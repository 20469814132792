import { useTransitionStateManager } from "@mui/base/useTransition"
import React, { PropsWithChildren, ReactElement, cloneElement } from "react"

export const TransitionAdapter: React.FC<PropsWithChildren> = ({
  children
}: PropsWithChildren) => {
  const { requestedEnter, onExited } = useTransitionStateManager()

  return (
    children &&
    cloneElement(children as ReactElement, {
      in: requestedEnter,
      onExited
    })
  )
}
