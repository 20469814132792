import { tags as t } from "@lezer/highlight"
import createTheme from "@uiw/codemirror-themes"

const sharedSettings = {
  background: "var(--palette-background-surface)",
  foreground: "var(--palette-text-secondary)",
  caret: "var(--palette-text-primary)",
  lineHighlight: "transparent",
  gutterBackground: "var(--palette-background-surface)",
  gutterForeground: "var(--palette-text-tertiary)"
}

export const codeEditorDark = createTheme({
  theme: "dark",
  settings: {
    ...sharedSettings
  },
  styles: [
    { tag: [t.comment, t.quote], color: "var(--palette-text-tertiary)" },
    {
      tag: [t.regexp, t.deleted, t.tagName, t.variableName],
      color: "#ffa07a"
    },
    {
      tag: [
        t.number,
        t.literal,
        t.typeName,
        t.typeOperator,
        t.meta,
        t.link,
        t.constant(t.variableName)
      ],
      color: "#f5ab35"
    },
    { tag: [t.attributeName, t.attributeValue], color: "#ffd700" },
    { tag: [t.inserted, t.string], color: "#abe338" },
    { tag: [t.heading], color: "#00e0e0" },
    { tag: [t.keyword, t.bool], color: "#dcc6e0" },
    { tag: t.emphasis, fontStyle: "italic" },
    { tag: t.strong, fontWeight: "bold" }
  ]
})

export const codeEditorLight = createTheme({
  theme: "dark",
  settings: {
    ...sharedSettings
  },
  styles: [
    { tag: [t.comment, t.quote], color: "var(--palette-text-tertiary)" },
    {
      tag: [t.regexp, t.deleted, t.tagName, t.variableName],
      color: "#d91e18"
    },
    {
      tag: [
        t.number,
        t.literal,
        t.typeName,
        t.typeOperator,
        t.meta,
        t.link,
        t.constant(t.variableName)
      ],
      color: "#aa5d00"
    },
    { tag: [t.attributeName, t.attributeValue], color: "#aa5d00" },
    { tag: [t.inserted, t.string], color: "#008000" },
    { tag: [t.heading], color: "#007faa" },
    { tag: [t.keyword, t.bool], color: "#7928a1" },
    { tag: t.emphasis, fontStyle: "italic" },
    { tag: t.strong, fontWeight: "bold" }
  ]
})
