import { UniservicesRequest } from "@liveops-portal/lib"
import { api } from "@/api"

export const uniservicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    request: builder.mutation<unknown, UniservicesRequest>({
      query: (body) => ({
        url: "uniservices",
        method: "POST",
        body
      }),
      extraOptions: {
        notify: { success: false }
      }
    })
  })
})

export const { useRequestMutation } = uniservicesApi
