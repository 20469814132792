import { Game, Space } from "@liveops-portal/lib"
import {
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Skeleton,
  listItemButtonClasses,
  listItemClasses
} from "@mui/joy"

import { Collapse, Fade } from "@mui/material"
import { SxProps } from "@mui/system"
import React from "react"
import { generatePath, Link } from "react-router-dom"
import { useGetAllGamesQuery } from "@/api/game"
import { useFindAllSpacesQuery } from "@/api/space"
import { EntityChip } from "@/components/entity-chip/entity-chip"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import {
  selectEnv,
  selectGame,
  selectSpace,
  setGameId,
  setSpaceId
} from "@/store/slices/context"

export const OverviewTree: React.FC = () => {
  const dispatch = useAppDispatch()
  const env = useAppSelector(selectEnv)
  const activeGameId = useAppSelector(selectGame)
  const activeSpaceId = useAppSelector(selectSpace)
  const { data: spaces, isFetching: isFetchingSpaces } = useFindAllSpacesQuery(
    { env },
    { skip: !env }
  )
  const { data: games, isFetching: isFetchingGames } = useGetAllGamesQuery(
    { env },
    { skip: !env }
  )
  const isFetching = isFetchingGames || isFetchingSpaces

  const spacesListSx: SxProps = {
    "--ListItemDecorator-size": "28px",
    "--ListItem-minHeight": "30px",
    position: "relative",
    ml: "10px"
  }

  const spaceItemSx: SxProps = {
    p: 0,
    position: "relative",
    [`&.${listItemClasses.root}::before`]: {
      content: '""',
      borderLeft: 1,
      borderLeftColor: "divider",
      position: "absolute",
      inset: 0,
      right: "auto"
    },
    [`&.${listItemClasses.root}[data-last-child]::before`]: {
      bottom: "14px"
    }
  }

  const gameItemSx: SxProps = {
    py: 0,
    [`&.${listItemButtonClasses.root}:hover`]: {
      bgcolor: "transparent",
      color: "text.primary"
    },
    [`&.${listItemButtonClasses.selected}`]: {
      bgcolor: "transparent"
    }
  }

  const spaceItemButtonSx: SxProps = {
    ...gameItemSx,
    [`&.${listItemButtonClasses.root}::before`]: {
      content: '""',
      borderTop: 1,
      borderTopColor: "divider",
      width: "20px",
      mr: "-3px"
    }
  }

  const onClickSpaceHandler = (gameId: string, spaceId: string) => {
    dispatch(setGameId(gameId))
    dispatch(setSpaceId(spaceId))
  }

  const onClickGameHandler = (gameId: string) => {
    dispatch(setGameId(gameId))
    dispatch(setSpaceId(null))
  }

  const renderSpaceItem = (space?: Space) => {
    const { spaceId, gameId, spaceName } = space || {}

    const to =
      gameId && spaceId
        ? generatePath("/:env/overview/:gameId/:spaceId?", {
            env,
            gameId,
            spaceId
          })
        : undefined

    return (
      <ListItem key={spaceId} sx={spaceItemSx}>
        <ListItemButton
          data-testid="tree-item-space"
          component={space ? Link : "a"}
          selected={spaceId === activeSpaceId}
          to={to}
          onClick={() =>
            gameId && spaceId && onClickSpaceHandler(gameId, spaceId)
          }
          sx={spaceItemButtonSx}
        >
          <ListItemDecorator>
            <EntityChip type="space" initial />
          </ListItemDecorator>
          <ListItemContent>
            {spaceName}
            <Skeleton
              loading={isFetching}
              variant="text"
              level="body-sm"
              sx={{ maxWidth: "calc(100% - 8px)" }}
            />
          </ListItemContent>
        </ListItemButton>
      </ListItem>
    )
  }

  const renderGameItem = (game?: Game) => {
    const { gameId, name } = game || {}
    const to = gameId
      ? generatePath("/:env/overview/:gameId/:spaceId?", {
          env,
          gameId,
          spaceId: null
        })
      : undefined

    return (
      <ListItem
        nested
        key={game ? gameId : "overview-tree-game-placeholder"}
        sx={{ p: 0 }}
      >
        <ListItemButton
          data-testid="tree-item-game"
          component={game ? Link : "a"}
          selected={gameId === activeGameId && activeSpaceId === null}
          to={to}
          onClick={() => gameId && onClickGameHandler(gameId)}
          sx={gameItemSx}
        >
          <ListItemDecorator>
            <EntityChip type="game" initial />
          </ListItemDecorator>
          <ListItemContent>
            {name}
            <Skeleton loading={isFetching} variant="text" level="body-sm" />
          </ListItemContent>
        </ListItemButton>
        <List sx={spacesListSx}>
          {game
            ? spaces
                ?.filter((s) => s.gameId === game.gameId)
                ?.map((space) => renderSpaceItem(space))
            : renderSpaceItem()}
        </List>
      </ListItem>
    )
  }

  return (
    <Card size="sm" sx={{ px: 2, minHeight: 90 }}>
      <List sx={spacesListSx}>
        <Fade
          in={isFetching}
          style={{ position: "absolute", inset: 0, marginTop: 4 }}
        >
          {renderGameItem()}
        </Fade>
        <Collapse in={!isFetching}>
          {games?.map((game) => renderGameItem(game))}
        </Collapse>
      </List>
    </Card>
  )
}
