import { ApiError, Environment } from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const environmentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEnvironments: builder.query<Environment[], void>({
      query: () => ({
        url: "environments"
      }),
      onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled.catch(({ error }) => {
          notify.error({
            message: i18next.t("api.getEnvironments.error"),
            error: error as ApiError
          })
        })
      }
    })
  }),
  overrideExisting: false
})

export const { useGetEnvironmentsQuery } = environmentApi
