import { GameServerBuild, DataState } from "@liveops-portal/lib"
import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import { CardItem } from "@/components/card-item/card-item"
import { matchDataStateToColor } from "@/helpers/match"

interface Props extends GameServerBuild {}

export const GameServerBuildCard: React.FC<Props> = ({
  buildId,
  buildType,
  codeChangeList,
  dataChangeList,
  deploymentType,
  state,
  lastUpdate,
  buildName,
  preserved
}) => {
  const { t } = useTranslation()

  return (
    <CardItem
      title={{ content: buildName }}
      chips={[
        { content: buildType },
        { content: deploymentType },
        {
          content: t(DataState[state]),
          color: matchDataStateToColor(state),
          props: { "data-testid": "game-server-build-state" }
        }
      ]}
      subtitles={[
        { content: buildId, copiable: true },

        {
          label: t("label.time.lastModified"),
          content: dayjs(lastUpdate).format("YYYY-MM-DD HH:mm:ss z")
        }
      ]}
      sections={[
        {
          title: t("label.gameServerBuild.codeChangelist"),
          content: codeChangeList || t("common.none")
        },
        {
          title: t("label.gameServerBuild.dataChangelist"),
          content: dataChangeList || t("common.none")
        },
        {
          title: t("state.preserved"),
          content: preserved ? t("common.yes") : t("common.no")
        }
      ]}
    />
  )
}
