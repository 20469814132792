import { Stack, Typography } from "@mui/joy"
import React from "react"
import { useTranslation } from "react-i18next"
import { SeasonCard } from "@/components/season-card/season-card"
import { SeasonCreate } from "@/components/season-create/season-create"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { usePageActions } from "@/hooks/usePageActions"
import { useGetSeasonsQuery } from "@/store/api/season"
import { selectEnv, selectGame } from "@/store/slices/context"

/**
 * Component representing the Season Designer Overview page.
 */
export const SeasonDesignerOverviewPage: React.FC = () => {
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const { t } = useTranslation()

  const { data: seasons, isFetching } = useGetSeasonsQuery(
    { gameId, env },
    { skip: !gameId || !env }
  )

  usePageActions([<SeasonCreate key="season-create" />])

  return (
    <>
      <Spinner loading={isFetching} />

      {seasons?.length ? (
        <Stack sx={{ gap: 2 }}>
          {seasons.map(({ seasonId }) => (
            <SeasonCard key={seasonId} seasonId={seasonId} />
          ))}
        </Stack>
      ) : (
        <Typography>
          {t("message.noItems.parent", {
            item: "item.seasons",
            parent: "item.game"
          })}
        </Typography>
      )}
    </>
  )
}
