import { CircularProgress, CircularProgressProps, Stack } from "@mui/joy"
import { Fade } from "@mui/material"

interface Props {
  loading: boolean
  size?: CircularProgressProps["size"]
  sx?: CircularProgressProps["sx"]
}

export const Spinner = ({ loading, size, sx }: Props) => {
  return (
    <Fade enter={false} in={loading} timeout={200} unmountOnExit>
      <Stack
        sx={{
          position: "absolute",
          inset: 0,
          bgcolor: "background.body",
          zIndex: 999,
          alignItems: "center",
          justifyContent: "center",
          ...sx
        }}
      >
        <CircularProgress data-testid="loading" size={size || "md"} />
      </Stack>
    </Fade>
  )
}
