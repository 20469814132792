import { ToolboxRequest } from "@liveops-portal/lib"
import { Button, List, Stack, Typography } from "@mui/joy"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ToolboxHistoryItem } from "@/components/toolbox-history-item/toolbox-history-item"

interface Props {
  onClick: (index: number) => void
}

/**
 * Lists all requests done via the Toolbox.
 * @param onClick - Returns the index of the clicked history item.
 */
export const ToolboxHistory: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation()
  const [history, setHistory] = useState<ToolboxRequest[]>([])
  const localStorageToolboxHistory = localStorage.getItem("toolboxHistory")

  useEffect(
    () => setHistory(JSON.parse(localStorageToolboxHistory || "[]")),
    [localStorageToolboxHistory]
  )

  const onToolboxHistoryClearClick = () => {
    localStorage.removeItem("toolboxHistory")
    setHistory([])
  }

  return (
    <Stack sx={{ gap: 2 }}>
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <Typography level="title-lg">{t("label.history")}</Typography>
        {history.length ? (
          <Button
            data-testid="clearHistory"
            size="sm"
            variant="outlined"
            onClick={onToolboxHistoryClearClick}
          >
            {t("action.clear")}
          </Button>
        ) : null}
      </Stack>
      {history.length ? (
        <List
          sx={{
            maxHeight: "60vh",
            overflow: "scroll",
            "--ListItem-paddingX": 0
          }}
        >
          {history.map(({ method, endpoint, serviceName }, index) => (
            <ToolboxHistoryItem
              key={index}
              toolboxRequest={{ method, serviceName, endpoint }}
              onClick={() => onClick(index)}
            />
          ))}
        </List>
      ) : (
        <Typography marginTop={2} level="body-xs">
          {t("message.noRequest")}
        </Typography>
      )}
    </Stack>
  )
}
