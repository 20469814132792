import { useRef, useEffect } from "react"

export const useEventListener = (
  eventName: string,
  handler: EventListener,
  element: HTMLElement | Window = window
) => {
  const savedHandler = useRef<EventListener | undefined>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) /* c8 ignore next */ return

    const eventListener = (event: Event) => savedHandler.current?.(event)

    element.addEventListener(eventName, eventListener)

    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}
