import { Buffer } from "buffer"
import { api } from "@/api"
import { GRAPH_API_URL } from "@/constants/auth"

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPhoto: builder.query<string | undefined, { id?: string; size?: number }>(
      {
        query: ({ id }) => ({
          url: `${GRAPH_API_URL}/v1.0/users/${id}/photo/$value`,
          responseHandler: (response: Response) =>
            response.status === 200 ? response.blob() : undefined
        }),
        extraOptions: { notify: { error: false } },
        transformResponse: async (response: Blob | undefined) => {
          if (response?.constructor.name === "Blob") {
            const buffer = Buffer.from(await response.arrayBuffer())
            return (
              "data:" + response.type + ";base64," + buffer.toString("base64")
            )
          }
        }
      }
    )
  })
})

export const { useGetPhotoQuery } = userApi
