import { Healthcheck } from "@liveops-portal/lib"
import { Stack, Typography } from "@mui/joy"
import React from "react"
import { useTranslation } from "react-i18next"
import { HealthcheckCard } from "@/components/healthcheck-card/healthcheck-card"
import { HealthcheckCreate } from "@/components/healthcheck-create/healthcheck-create"
import { HealthcheckTrigger } from "@/components/healthcheck-trigger/healthcheck-trigger"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { usePageActions } from "@/hooks/usePageActions"
import { useGetHealthchecksQuery } from "@/store/api/healthcheck"
import { selectEnv } from "@/store/slices/context"

/**
 * Component representing the Healthcheck page.
 */
export const HealthchecksPage: React.FC = () => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)

  const { data: healthchecks, isFetching } = useGetHealthchecksQuery(
    { env },
    { skip: !env }
  )

  usePageActions([
    <HealthcheckTrigger key="healthcheck-trigger" />,
    <HealthcheckCreate key="healthcheck-create" />
  ])

  return (
    <>
      <Spinner loading={isFetching} />

      {healthchecks?.length ? (
        <Stack sx={{ gap: 2 }}>
          {healthchecks.map((healthcheck: Healthcheck) => (
            <HealthcheckCard key={healthcheck.serviceName} {...healthcheck} />
          ))}
        </Stack>
      ) : (
        <Typography>
          {t("message.noItems.parent", {
            item: "item.healthchecks",
            parent: "item.environment"
          })}
        </Typography>
      )}
    </>
  )
}
