import { IconButton, InputProps, Stack, StackProps } from "@mui/joy"
import { Minus, Plus } from "iconoir-react"
import {
  FieldArray,
  FieldValues,
  Path,
  RegisterOptions,
  useFieldArray
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormInput } from "@/components/form-input/form-input"

interface Props<T extends FieldValues> extends StackProps {
  name: Path<T>
  fields: Record<
    string,
    InputProps & {
      type: "text" | "number"
      label?: string
      rules?: RegisterOptions
    }
  >
}

export const FormRepeater = <T extends FieldValues>({
  name,
  fields,
  ...props
}: Props<T>) => {
  const { t } = useTranslation()
  const {
    fields: items,
    append,
    remove
  } = useFieldArray({
    name
  })

  return (
    <Stack gap={1} sx={props.sx}>
      {items.map(({ id }, i) => (
        <Stack direction="row" gap={1} flexGrow={1} key={id}>
          {Object.keys(fields).map((fieldName) => (
            <FormInput
              key={`${name}.${i}.${fieldName}`}
              label={fields[fieldName].label}
              type={fields[fieldName].type}
              name={`${name}.${i}.${fieldName}`}
              rules={fields[fieldName].rules}
              sx={fields[fieldName].sx}
              aria-labelledby={props["aria-labelledby"]}
            />
          ))}
          <IconButton
            size="sm"
            variant="outlined"
            title={t("action.add")}
            aria-label={t("action.add")}
            onClick={() => {
              append(
                Object.keys(fields).map((fieldName) => ({
                  [fieldName]: ""
                })) as FieldArray<FieldValues, Path<T>>
              )
            }}
            sx={{
              alignSelf: "flex-end",
              bgcolor: "background.surface",
              ...(i === 0 && { mr: 5 })
            }}
          >
            <Plus />
          </IconButton>
          {i > 0 && (
            <IconButton
              size="sm"
              variant="outlined"
              title={t("action.remove")}
              aria-label={t("action.remove")}
              onClick={() => {
                remove(i)
              }}
              sx={{ alignSelf: "flex-end", bgcolor: "background.surface" }}
            >
              <Minus />
            </IconButton>
          )}
        </Stack>
      ))}
    </Stack>
  )
}
