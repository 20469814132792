export enum ServiceName {
  UNIMATRIX_XYQ_SERVICE = "Unimatrix (XYQ Service)",
  UNIMATRIX_OTHER = "Unimatrix (Other)",
  UNIPUBLISH = "Unipublish"
}

export enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE"
}

export interface ToolboxRequest {
  method: Method
  serviceName: ServiceName
  endpoint: string
  spaceId: string
  queryParams: Record<string, unknown>
  body: Record<string, unknown>
}
