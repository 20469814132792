import * as Sentry from "@sentry/react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { AuthGuard } from "@/components/auth-guard/auth-guard"
import { ROUTES } from "@/constants/routes"
import { ErrorPage } from "@/pages/error-page/error-page"
import { NotFoundPage } from "@/pages/not-found-page/not-found-page"

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    id: "root",
    path: "/",
    element: <AuthGuard />,
    errorElement: <ErrorPage />,
    children: [
      ...ROUTES,
      {
        id: "lost",
        path: ":env?/*",
        element: <NotFoundPage />,
        handle: {
          name: "app.notFound"
        }
      }
    ]
  }
])

export const Router = () => {
  return <RouterProvider router={router} />
}
