import {
  ApiError,
  GameServerBuildStatus,
  GameServerBuildState as Status
} from "@liveops-portal/lib"
import {
  Button,
  ButtonGroup,
  Card,
  Chip,
  Stack,
  Link,
  Typography,
  LinearProgress,
  ColorPaletteProp
} from "@mui/joy"
import { skipToken } from "@reduxjs/toolkit/query"
import dayjs from "dayjs"
import { EditPencil, PauseSolid, PlaySolid } from "iconoir-react"
import { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link as RouterLink } from "react-router-dom"
import {
  useGetGameServerBuildQuery,
  useGetGameServerBuildStatusQuery,
  useStopGameServerMutation
} from "@/api/game-server-build"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"
import { GameServerBuildDeploy } from "@/components/game-server-build-deploy/game-server-build-deploy"
import { GameServerBuildWarm } from "@/components/game-server-build-warm/game-server-build-warm"
import { Spinner } from "@/components/spinner/spinner"
import notify from "@/helpers/notify"
import { useAppSelector } from "@/hooks/store"
import { selectEnv, selectGame, selectSpace } from "@/store/slices/context"

const getStatusColor = (status: keyof typeof Status): ColorPaletteProp => {
  switch (Status[status]) {
    case Status.Shutdown:
      return "danger"
    case Status.NotUploaded:
    case Status.Deploying:
      return "warning"
    case Status.Ready:
      return "success"
    case Status.Unknown:
    default:
      return "neutral"
  }
}

const getGameServerActionIcon = (status: keyof typeof Status | undefined) => {
  if (!status) return <PlaySolid />

  switch (Status[status]) {
    case Status.Unknown:
    case Status.NotUploaded:
    case Status.Shutdown:
      return <PlaySolid />
    case Status.Deploying:
      return null
    case Status.Ready:
      return <PauseSolid />
  }
}

export const OverviewGameServerBuild = () => {
  const { t, i18n } = useTranslation()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)

  const {
    currentData: gameServer = {},
    error: e,
    isError,
    isFetching: isFetchingStatus,
    isLoading: isLoadingStatus,
    refetch
  } = useGetGameServerBuildStatusQuery(
    gameId && spaceId ? { gameId, spaceId } : skipToken
  )

  const error = e as ApiError
  const { status, expire, startedAt, buildId } =
    gameServer as GameServerBuildStatus

  const { currentData: build, isFetching: isFetchingBuild } =
    useGetGameServerBuildQuery(
      gameId && buildId ? { gameId, buildId } : skipToken
    )
  const { buildName } = build || {}

  const isFetching = isFetchingStatus || isFetchingBuild

  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false)
  const [openWarmModal, setOpenWarmModal] = useState<boolean>(false)
  const [timeLeft, setTimeLeft] = useState<number | null>(null)
  const [progress, setProgress] = useState<number>(0)

  const prevStatus = useRef<Status | null>(null)
  const expiresAt = useRef(dayjs(expire))
  const duration = useRef<number>(
    dayjs(expire).diff(dayjs(startedAt), "second")
  )
  const timer = useRef<number | null>(null)

  const [stopGameServer, { isLoading: isStopping, isSuccess: isStopped }] =
    useStopGameServerMutation()

  const onActionButtonClick = useCallback(() => {
    if (status === "Ready") {
      stopGameServer({
        gameId,
        spaceId,
        qdOperationReason: "Game server shutdown from LiveOps Portal"
      })
    } else {
      setOpenWarmModal(true)
    }
  }, [gameId, spaceId, status, stopGameServer])

  useEffect(() => {
    if (Status[status] === Status.Deploying) {
      prevStatus.current = Status.Deploying

      const interval = setInterval(() => {
        refetch()
      }, 5000)

      return () => clearInterval(interval)
    }
  }, [status, refetch])

  useEffect(() => {
    if (
      prevStatus.current === Status.Deploying &&
      Status[status] === Status.Ready
    ) {
      prevStatus.current = null
      notify.success(t("api.warmupGameServer.success", { spaceId }))
    }
  }, [status, spaceId, t])

  useEffect(() => {
    if (!expire?.startsWith("9999")) {
      expiresAt.current = dayjs(expire)
      duration.current = dayjs(expire).diff(dayjs(startedAt), "second")

      const calculateTimeLeft = () => {
        const now = dayjs()
        const diff = expiresAt.current.diff(now, "second")
        return diff > 0 ? diff : 0
      }

      const calculateProgress = (r: number) => {
        const p = (r * 100) / duration.current
        return !isNaN(p) && p > 0 ? Math.trunc(p * 100) / 100 : 0
      }

      const initialRemaining = calculateTimeLeft()
      const initialProgress = calculateProgress(initialRemaining)
      setTimeLeft(initialRemaining)
      setProgress(initialProgress)

      const countdown = window.setInterval(() => {
        const remaining = calculateTimeLeft()
        setTimeLeft(remaining)
        setProgress(calculateProgress(remaining))

        if (remaining <= 0 && timer.current) {
          clearInterval(timer.current)
        }
      }, 1000)

      timer.current = countdown

      return () => {
        if (timer.current) {
          clearInterval(timer.current)
          timer.current = null
        }
      }
    }
  }, [expire, startedAt])

  return (
    <>
      <GameServerBuildWarm open={openWarmModal} setOpen={setOpenWarmModal} />
      <GameServerBuildDeploy
        open={openUpdateModal}
        setOpen={setOpenUpdateModal}
        spaceId={spaceId}
        buildId={buildId}
        updating={!!build || error?.errCode === 12 ? "build" : undefined}
      />

      <Stack sx={{ gap: 2 }}>
        <Typography level="h4">{t("item.gameServer")}</Typography>
        <Card sx={{ overflow: "hidden", p: 0, gap: 0, minHeight: 87 }}>
          {!spaceId ? (
            <Typography
              color="warning"
              level="body-sm"
              fontStyle="italic"
              sx={{ p: 2, flexGrow: 1 }}
            >
              {t("error.gameServerBuild.noSpace")}
            </Typography>
          ) : (
            <Stack sx={{ flexDirection: "row", flexGrow: 1 }}>
              <Spinner
                loading={
                  (isFetchingStatus && Status[status] !== Status.Deploying) ||
                  isLoadingStatus ||
                  isFetchingBuild
                }
                sx={{ bgcolor: "background.surface", zIndex: 998 }}
              />

              <Stack sx={{ p: 2, flexGrow: 1, maxWidth: "calc(100% - 120px)" }}>
                {isError && (
                  <Typography
                    color="warning"
                    level="body-sm"
                    fontStyle="italic"
                  >
                    {error.errCode
                      ? i18n.exists(`error.gameServerBuild.${error.errCode}`)
                        ? t(`error.gameServerBuild.${error.errCode}`)
                        : error.message
                      : t("error.gameServerBuild.generic")}
                  </Typography>
                )}

                {!!buildName && !!status && !!buildId && (
                  <Stack sx={{ gap: 1 }}>
                    <Typography
                      level="body-md"
                      component="h2"
                      sx={{
                        fontWeight: "normal",
                        color: "text.primary",
                        wordBreak: "break-all"
                      }}
                    >
                      <Link
                        color="neutral"
                        component={RouterLink}
                        to={`/${env}/game-server-builds/${gameId}?${new URLSearchParams({ search: buildId }).toString()}`}
                        sx={{
                          color: ({ palette }) => palette.text.primary,
                          display: "inline",
                          mr: 1
                        }}
                      >
                        {buildName}
                      </Link>
                      <Chip color={getStatusColor(status)} data-testid="status">
                        {t(Status[status])}
                      </Chip>
                    </Typography>
                    <CopyToClipboard level="body-sm">{buildId}</CopyToClipboard>
                    {!!startedAt && !expire?.startsWith("9999") && (
                      <Typography level="body-xs">
                        <Typography fontWeight="xl">
                          {t("label.gameServerBuild.started")}:{" "}
                        </Typography>
                        {dayjs(startedAt).format("YYYY-MM-DD HH:mm:ss z")}
                      </Typography>
                    )}
                    {expire?.startsWith("9999") && (
                      <Typography
                        level="body-sm"
                        color="warning"
                        fontStyle="italic"
                      >
                        {t("message.gameServerBuild.alwaysWarm")}
                      </Typography>
                    )}
                  </Stack>
                )}
              </Stack>
              <ButtonGroup
                orientation="vertical"
                variant="plain"
                sx={{
                  flexGrow: 0,
                  borderLeft: 1,
                  borderLeftColor: "divider",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  minWidth: "120px"
                }}
              >
                <Button
                  disabled={error?.errCode === 11}
                  startDecorator={<EditPencil />}
                  onClick={() => setOpenUpdateModal(true)}
                  sx={{ flexGrow: 1 }}
                >
                  {t("action.update")}
                </Button>
                <Button
                  data-testid="action-button"
                  startDecorator={getGameServerActionIcon(status)}
                  loading={Status[status] === Status.Deploying || isStopping}
                  loadingPosition="start"
                  disabled={
                    isError ||
                    isFetching ||
                    Status[status] === Status.NotUploaded
                  }
                  onClick={onActionButtonClick}
                  sx={{ flexGrow: 1 }}
                >
                  {(Status[status] === Status.Deploying &&
                    t("state.deploying")) ||
                    (isStopping && t("state.stopping")) ||
                    (Status[status] === Status.Ready &&
                      !isStopped &&
                      t("action.stop")) ||
                    t("action.warm")}
                </Button>
              </ButtonGroup>
            </Stack>
          )}

          {Status[status] === Status.Ready &&
            !!timeLeft &&
            !isStopping &&
            !isFetchingStatus && (
              <LinearProgress
                determinate
                value={progress}
                thickness={22}
                variant="solid"
                data-testid="warmup-progress"
                color={
                  timeLeft < 900
                    ? "danger"
                    : timeLeft < 1800
                      ? "warning"
                      : "success"
                }
                sx={{
                  "--LinearProgress-progressRadius": 0,
                  "--LinearProgress-radius": 0,
                  borderTop: "1px solid",
                  borderTopColor: "divider"
                }}
              >
                <Typography
                  level="body-xs"
                  textColor="common.white"
                  sx={{
                    fontWeight: "xl",
                    mixBlendMode: "lighten",
                    gap: 0.5,
                    display: "inline-flex"
                  }}
                >
                  <Typography sx={{ textTransform: "uppercase" }}>
                    {t("label.gameServerBuild.remaining")}:
                  </Typography>
                  <Typography>
                    {dayjs.duration(timeLeft, "seconds").format("HH:mm:ss")}
                  </Typography>
                </Typography>
              </LinearProgress>
            )}
        </Card>
      </Stack>
    </>
  )
}
