import {
  FeatureSwitchSetRequest,
  FeatureSwitchResetRequest,
  BySpaceIdQuery,
  FeatureSwitch
} from "@liveops-portal/lib"
import { api } from "@/api"

export const featureSwitchApi = api
  .enhanceEndpoints({ addTagTypes: ["FeatureSwitch"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      findAllFeatureSwitches: builder.query<FeatureSwitch[], BySpaceIdQuery>({
        query: ({ spaceId }) => ({
          url: "/feature-switches",
          params: { spaceId }
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ switchName }) => ({
                  type: "FeatureSwitch" as const,
                  id: switchName
                })),

                { type: "FeatureSwitch", id: "ALL" }
              ]
            : [{ type: "FeatureSwitch", id: "ALL" }]
      }),
      setSwitch: builder.mutation<void, FeatureSwitchSetRequest>({
        query: ({ spaceId, switchName, switchValue, qdOperationReason }) => ({
          url: `/feature-switches/${switchName}/override`,
          params: { spaceId },
          body: { switchValue },
          method: "PATCH",
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { switchName }) => [
          { type: "FeatureSwitch", id: switchName }
        ]
      }),
      resetSwitch: builder.mutation<void, FeatureSwitchResetRequest>({
        query: ({ spaceId, switchName, qdOperationReason }) => ({
          url: `/feature-switches/${switchName}/override`,
          params: { spaceId },
          method: "DELETE",
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { switchName }) => [
          { type: "FeatureSwitch", id: switchName }
        ]
      })
    }),
    overrideExisting: false
  })

export const {
  useFindAllFeatureSwitchesQuery,
  useSetSwitchMutation,
  useResetSwitchMutation
} = featureSwitchApi
