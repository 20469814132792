import { ByEnvQuery, ByGameIdQuery, Game, OperationReason } from "."

// SEASONS

export interface SeasonRevisionDetails {
  clientDataVersion: string
  duration: string
}

interface _SeasonComponentItem {
  displayName: string
  itemId: string
  componentType: SeasonComponent["componentName"]
  lastModified?: string
}

export type SeasonComponentItem<T = object> = _SeasonComponentItem & T

export interface SeasonComponent {
  componentName: string
  items: SeasonComponentItem[]
}

export interface SeasonRevision {
  gameId: Game["gameId"]
  seasonId: string
  seasonName: string
  creationDate: string
  isDraft: boolean
  isLatestPublished: boolean
  revision: number
  seasonComponents?: SeasonComponent[]
  seasonRevisionDetails?: SeasonRevisionDetails
}

export interface Season
  extends Omit<
    SeasonRevision,
    | "isDraft"
    | "isLatestPublished"
    | "revision"
    | "seasonComponents"
    | "seasonRevisionDetails"
  > {
  duration?: string
  clientDataVersion: string
  latestDraftRevision?: SeasonRevision["revision"]
  latestPublishedRevision: SeasonRevision["revision"]
}

export interface ChallengeLevel {
  threshold: number
  reward: string
}

// CHALLENGES
export interface Challenge {
  displayName: string
  scope: ChallengeScope
  filter: string
  progressType: ChallengeProgressionType
  progressInMenu: boolean
  levels: ChallengeLevel[]
}

export const ChallengeScopes: Record<string, string> = {
  SEASON: "season",
  BATTLEPASS: "battlepass",
  EVENT: "event"
} as const

export type ChallengeScope =
  (typeof ChallengeScopes)[keyof typeof ChallengeScopes]

export const ChallengeProgressionTypes: Record<string, string> = {
  HIGHEST: "highest",
  ACCUMULATION: "accumulation"
} as const

export type ChallengeProgressionType =
  (typeof ChallengeProgressionTypes)[keyof typeof ChallengeProgressionTypes]

// BATTLEPASSES

export const BattlepassDurationTypes: Record<string, string> = {
  FULL: "fullSeason",
  SPECIFIC: "specificLength"
} as const

export type BattlepassDurationType =
  (typeof BattlepassDurationTypes)[keyof typeof BattlepassDurationTypes]

export const BattlepassXpRequiredTypes: Record<string, string> = {
  LINEAR: "linear",
  CUSTOM: "custom"
} as const

export type BattlepassXpRequiredType =
  (typeof BattlepassXpRequiredTypes)[keyof typeof BattlepassXpRequiredTypes]

export interface BattlepassTrack {
  displayName: string
  unlockItem?: string
  levels: {
    index: number
    rewards: { rewardId: string }[]
    xpRequired?: number
  }[]
}
export interface Battlepass {
  displayName: string
  duration: {
    type: BattlepassDurationType
    delay: number | null
    length: number
  }
  xpRequired: {
    type: BattlepassXpRequiredType
    linearValue?: number
  }

  tracks: BattlepassTrack[]
}

// REQUEST

export interface BySeasonIdQuery {
  seasonId: Season["seasonId"] | null
}

export type AllSeasonsQuery = ByGameIdQuery & ByEnvQuery

export type SeasonDraftRevisionQuery = BySeasonIdQuery &
  ByGameIdQuery &
  ByEnvQuery

export type SeasonComponentItemsByTypeQuery = {
  componentType: SeasonComponent["componentName"]
} & BySeasonIdQuery &
  ByGameIdQuery &
  ByEnvQuery

export type SeasonCreateRequest = {
  seasonId: Season["seasonId"]
  seasonName: Season["seasonName"]
  gameId: Season["gameId"]
  seasonRevisionDetails: SeasonRevisionDetails
} & OperationReason

export type SeasonUpdateRequest = {
  seasonId: Season["seasonId"]
  gameId: Season["gameId"]
  seasonRevisionDetails: SeasonRevisionDetails
} & OperationReason

export type SeasonPublishRequest = {
  seasonId: Season["seasonId"]
  gameId: Season["gameId"]
  revision: SeasonRevision["revision"]
} & OperationReason

export type SeasonDeleteRequest = {
  seasonId: Season["seasonId"]
  gameId: Season["gameId"]
} & OperationReason

export type SeasonComponentItemCreateRequest<T = object> = {
  gameId: Season["gameId"]
  seasonId: Season["seasonName"]
} & SeasonComponentItem<T> &
  OperationReason

export type SeasonComponentItemUpdateRequest = {
  gameId: Season["gameId"]
  seasonId: Season["seasonName"]
} & SeasonComponentItem &
  OperationReason

export type SeasonComponentItemDeleteRequest = {
  gameId: Season["gameId"]
  seasonId: Season["seasonId"]
  componentType: SeasonComponentItem["componentType"]
  itemId: SeasonComponentItem["itemId"]
} & OperationReason

// RESPONSE

export type SeasonUpsert = SeasonRevision & {
  seasonRevisionDetails: SeasonRevisionDetails
}

export type SeasonCreateResponse = SeasonUpsert

export type SeasonUpdateResponse = SeasonUpsert

export type SeasonComponentItemCreateResponse = SeasonComponentItem

export type SeasonComponentItemUpdateResponse = SeasonComponentItem
