import { Environment, Game, PlayerId, Season, Space } from "@liveops-portal/lib"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { DEFAULT_ENV, DEFAULT_GAMEID, DEFAULT_STAGE } from "@/constants/context"
import { GlobalState } from "@/typings/redux"

export type ContextState = {
  type: string[]
  stage: Environment
  env: Environment
  spaceId: Space["spaceId"] | null
  gameId: Game["gameId"] | null
  seasonId: Season["seasonId"] | null
  playerId: PlayerId
}

export const contextSlice = createSlice({
  name: "context",
  initialState: {
    type: [],
    stage: DEFAULT_STAGE,
    env: DEFAULT_ENV,
    gameId: DEFAULT_GAMEID,
    spaceId: null,
    seasonId: null,
    playerId: {} as PlayerId
  } as ContextState,
  reducers: {
    setEnv: (
      state: ContextState,
      { payload: env }: PayloadAction<ContextState["env"]>
    ) => {
      state.env = env
    },
    setGameId: (
      state: ContextState,
      { payload: gameId }: PayloadAction<ContextState["gameId"]>
    ) => {
      state.gameId = gameId
    },
    setSpaceId: (
      state: ContextState,
      { payload: spaceId }: PayloadAction<ContextState["spaceId"]>
    ) => {
      state.spaceId = spaceId
    },
    setSeasonId: (
      state: ContextState,
      { payload: seasonId }: PayloadAction<ContextState["seasonId"]>
    ) => {
      state.seasonId = seasonId
    },
    setPlayerId: (
      state: ContextState,
      { payload: playerId }: PayloadAction<ContextState["playerId"]>
    ) => {
      state.playerId = playerId
    }
  }
})

/* Actions */
export const { setEnv, setGameId, setSpaceId, setSeasonId, setPlayerId } =
  contextSlice.actions

/* Selectors */
export const selectStage = ({ context }: GlobalState) => context.stage
export const selectEnv = ({ context }: GlobalState) => context.env
export const selectGame = ({ context }: GlobalState) => context.gameId
export const selectSpace = ({ context }: GlobalState) => context.spaceId
export const selectSeason = ({ context }: GlobalState) => context.seasonId
export const selectPlayer = ({ context }: GlobalState) => context.playerId

export default contextSlice.reducer
