/**
 * Pushes a value to an array set to a certain localStorage key.
 * Can receive a limit, set to 100 by default.
 * @param key
 * @param item
 * @param limit
 */
export const pushToLocalStorageArray = (
  key: string,
  item: unknown,
  limit: number = 100
) => {
  const array = JSON.parse(localStorage.getItem(key) || "[]")

  if (array.length === limit) {
    array.pop()
  }
  // Add to front of array
  array.unshift(item)

  // Overwrite key with new array
  localStorage.setItem(key, JSON.stringify(array))
}

/**
 * Retrieves an item from an array of items saved via localStorage.
 * Returns null if array is empty or has nothing on index.
 * @param key
 * @param index
 */
export const getFromLocalStorageArray = (
  key: string,
  index: number
): unknown => {
  const itemsArray = JSON.parse(localStorage.getItem(key) || "[]")

  if (!itemsArray.length) {
    return null
  }

  return itemsArray[index] ? itemsArray[index] : null
}
