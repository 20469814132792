import { Card, CardContent, Stack, Typography } from "@mui/joy"
import React from "react"
import { useTranslation } from "react-i18next"
import { useGetApplicationsQuery } from "@/api/application"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"
import { useAppSelector } from "@/hooks/store"
import { selectEnv, selectGame } from "@/store/slices/context"

export const OverviewApplications: React.FC = () => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const { data: apps, isFetching } = useGetApplicationsQuery(
    { env, gameId },
    { skip: !env || !gameId }
  )

  return (
    <Stack sx={{ gap: 2 }}>
      <Typography level="h4">{t("item.applications")}</Typography>

      {!apps?.length && !isFetching && (
        <Typography>
          {t("message.noItems.root", { item: "item.application" })}
        </Typography>
      )}
      {apps?.map(({ appId, name }) => (
        <Card key={appId}>
          <CardContent
            sx={{
              flexGrow: 1,
              gap: 1
            }}
          >
            <Typography
              level="body-md"
              component="h2"
              sx={{ fontWeight: "normal", color: "text.primary" }}
            >
              {name}
            </Typography>
            <Typography level="body-sm">
              <CopyToClipboard>{appId}</CopyToClipboard>
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Stack>
  )
}
