import { Grid, Typography } from "@mui/joy"
import React from "react"
import { useTranslation } from "react-i18next"
import { FeatureSwitchCard } from "@/components/feature-switch-card/feature-switch-card"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { useGetAllFeatureSwitchesQuery } from "@/store/api/feature-switch"
import { selectEnv, selectSpace } from "@/store/slices/context"

export const FeatureSwitchesPage: React.FC = () => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)
  const spaceId = useAppSelector(selectSpace)

  const {
    data: switches,
    isFetching,
    isUninitialized
  } = useGetAllFeatureSwitchesQuery({ spaceId }, { skip: !env || !spaceId })

  return isUninitialized ? (
    <Typography>
      {t("message.uninitialized.parent", {
        parent: "item.space",
        item: "item.featureSwitches"
      })}
    </Typography>
  ) : (
    <>
      <Spinner loading={isFetching} />

      {switches?.length ? (
        <Grid container spacing={2} columns={12}>
          {switches.map((featureSwitch, index) => (
            <FeatureSwitchCard key={index} {...featureSwitch} />
          ))}
        </Grid>
      ) : (
        <Typography>
          {t("message.noItems.parent", {
            item: "item.featureSwitches",
            parent: "item.space"
          })}
        </Typography>
      )}
    </>
  )
}
