import {
  Dropdown,
  IconButton,
  Link,
  ListDivider,
  ListItemContent,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuItemProps
} from "@mui/joy"
import React, { Fragment, ReactNode, useState } from "react"
import { To, useNavigate } from "react-router-dom"

interface Props {
  label?: string
  icon: ReactNode
  disabled?: boolean
  variant?: MenuButtonProps["variant"]
  color?: MenuButtonProps["color"]
  items: {
    content: ReactNode
    icon?: ReactNode
    to?: To
    disabled?: boolean
    onClick?: MenuItemProps["onClick"]
  }[]
}

export const HeaderDropdown: React.FC<Props> = ({
  label,
  variant,
  color,
  disabled,
  icon,
  items
}) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  return (
    <Dropdown
      open={open}
      onOpenChange={(_, isOpen) => {
        setOpen(isOpen)
      }}
    >
      <MenuButton
        size="sm"
        sx={{
          ":hover": { bgcolor: "transparent" }
        }}
        aria-label={label}
        slots={{ root: IconButton }}
        slotProps={{
          root: { variant: variant || "plain", color: color || "neutral" }
        }}
        disabled={disabled}
      >
        {icon}
      </MenuButton>
      <Menu
        size="sm"
        placement="bottom-end"
        sx={{
          "--ListDivider-gap": 0,
          "--ListItemDecorator-size": "28px",
          mt: "20px",
          minWidth: "200px"
        }}
      >
        {items.map(
          (
            { content, icon: itemIcon, to, disabled: itemDisabled, onClick },
            i
          ) => {
            const isEmailLink =
              typeof to === "string" && to.startsWith("mailto:")
            return (
              <Fragment key={Math.random()}>
                <MenuItem
                  disabled={itemDisabled}
                  onClick={(e) => {
                    /* c8 ignore start */
                    e.preventDefault()

                    if (to) {
                      if (isEmailLink) {
                        window.location.href = to
                      } else {
                        navigate(to)
                      }
                    }

                    onClick?.(e)
                    setOpen(false)
                    /* c8 ignore end */
                  }}
                  component={Link}
                  href={to ? (to as string) : undefined}
                  underline="none"
                  sx={({ palette }) => ({
                    fontSize: "inherit",
                    color: "inherit",
                    display: "flex",
                    gap: 1,
                    svg: {
                      color: itemDisabled
                        ? palette.neutral.plainDisabledColor
                        : palette.text.tertiary
                    }
                  })}
                >
                  {!!itemIcon && (
                    <ListItemDecorator>{itemIcon}</ListItemDecorator>
                  )}
                  <ListItemContent>{content}</ListItemContent>
                </MenuItem>
                {i !== items.length && <ListDivider />}
              </Fragment>
            )
          }
        )}
      </Menu>
    </Dropdown>
  )
}
