import {
  HealthcheckCreateRequest,
  HealthcheckDeleteRequest,
  Healthcheck,
  HealthcheckToggleRequest,
  HealthcheckToggleResponse,
  ByEnvQuery,
  ManualHealthcheck,
  HealthcheckTriggerRequest,
  ApiError
} from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const healthcheckApi = api
  .enhanceEndpoints({ addTagTypes: ["Healthcheck"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getHealthchecks: builder.query<Healthcheck[], ByEnvQuery>({
        query: () => ({
          url: "healthchecks"
        }),
        providesTags: ["Healthcheck"],
        onQueryStarted(_, { queryFulfilled }) {
          queryFulfilled.catch(({ error }) => {
            notify.error({
              message: i18next.t("api.getHealthchecks.error"),
              error: error as ApiError
            })
          })
        }
      }),
      toggleHealthcheck: builder.mutation<
        HealthcheckToggleResponse,
        HealthcheckToggleRequest
      >({
        query: ({ serviceName, enabled }) => ({
          url: `healthchecks/${serviceName}`,
          body: { enabled: !enabled },
          method: "PATCH"
        }),
        onQueryStarted({ serviceName, env }, { dispatch, queryFulfilled }) {
          queryFulfilled
            .then(({ data: toggledHealthcheck }) => {
              dispatch(
                healthcheckApi.util.updateQueryData(
                  "getHealthchecks",
                  { env },
                  (draft) => {
                    const healthcheckIndex = draft.findIndex(
                      (healthcheck) => healthcheck.serviceName === serviceName
                    )

                    if (healthcheckIndex !== -1) {
                      draft[healthcheckIndex] = {
                        ...draft[healthcheckIndex],
                        ...toggledHealthcheck
                      }
                    }

                    notify.success(
                      i18next.t(
                        /* c8 ignore next */ toggledHealthcheck.enabled
                          ? "api.toggleHealthcheck.success.enabled"
                          : "api.toggleHealthcheck.success.disabled"
                      )
                    )
                  }
                )
              )
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.toggleHealthcheck.error"),
                error: error as ApiError
              })
            })
        }
      }),
      createHealthcheck: builder.mutation<
        Healthcheck,
        HealthcheckCreateRequest
      >({
        query: ({
          serviceName,
          enabled,
          subscriptionKey,
          qdOperationReason,
          url,
          msTeamsNotifierWebhookId
        }) => ({
          url: "healthchecks",
          method: "POST",
          body: {
            serviceName,
            enabled,
            url,
            subscriptionKey,
            msTeamsNotifierWebhookId
          },
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: ["Healthcheck"],
        onQueryStarted({ serviceName }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                i18next.t("api.createHealthcheck.success", { serviceName })
              )
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.createHealthcheck.error"),
                error: error as ApiError
              })
            })
        }
      }),
      deleteHealthcheck: builder.mutation<void, HealthcheckDeleteRequest>({
        query: ({ serviceName }) => ({
          url: `healthchecks/${serviceName}`,
          method: "DELETE"
        }),
        invalidatesTags: ["Healthcheck"],
        onQueryStarted({ serviceName }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                i18next.t("api.deleteHealthcheck.success", { serviceName })
              )
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.deleteHealthcheck.error"),
                error: error as ApiError
              })
            })
        }
      }),
      triggerHealthchecks: builder.mutation<
        ManualHealthcheck[],
        HealthcheckTriggerRequest
      >({
        query: (body) => ({
          url: "healthchecks/trigger",
          method: "POST",
          body
        }),
        invalidatesTags: ["Healthcheck"],
        onQueryStarted(_, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(i18next.t("api.triggerHealthchecks.success"))
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.triggerHealthchecks.error"),
                error: error as ApiError
              })
            })
        }
      })
    }),
    overrideExisting: false
  })

export const {
  useGetHealthchecksQuery,
  useToggleHealthcheckMutation,
  useCreateHealthcheckMutation,
  useDeleteHealthcheckMutation,
  useTriggerHealthchecksMutation
} = healthcheckApi
