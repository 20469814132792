import { useAccount } from "@azure/msal-react"
import { Avatar, Stack, Typography } from "@mui/joy"
import { Settings } from "iconoir-react"
import React from "react"
import { useTranslation } from "react-i18next"
import { useGetPhotoQuery } from "@/api/user"
import { HeaderDropdown } from "@/components/header-dropdown/header-dropdown"

export const UserDropdown: React.FC = () => {
  const account = useAccount()

  const { data: photo } = useGetPhotoQuery(
    { id: account?.username },
    { skip: !account }
  )

  const { t, i18n } = useTranslation()

  const props = {
    icon: (
      <Avatar
        role="img"
        variant="outlined"
        src={photo}
        sx={{ maxWidth: "32px", maxHeight: "32px" }}
      />
    ),
    disabled: !account,
    items: [
      {
        content: (
          <Stack>
            <Typography level="title-sm" textColor="text.primary">
              {account?.name}
            </Typography>
            <Typography level="body-xs" textColor="text.tertiary">
              {account?.username}
            </Typography>
          </Stack>
        )
      },
      {
        content: t("app.settings"),
        icon: <Settings />,
        disabled: true
      },
      {
        content: t("action.language"),
        icon: t("common.languageFlag"),
        onClick: () => {
          const updatedLanguage =
            i18n.language === "en" ? /* c8 ignore next */ "zh" : "en"
          i18n.changeLanguage(updatedLanguage)
          localStorage.setItem("localization", updatedLanguage)
        }
      }
    ]
  }

  return <HeaderDropdown {...props} />
}
