import { Action, Actions } from "@liveops-portal/lib"
import { Typography } from "@mui/joy"
import { FieldValues, SubmitHandler, UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { FormMultiSelect } from "@/components/form-multi-select/form-multi-select"
import { Modal } from "@/components/modal/modal"
import { useBundles } from "@/hooks/useBundles"

interface Props<T extends FieldValues> {
  open: boolean
  loading: boolean
  action: Action
  methods: UseFormReturn<T>
  onSubmit: SubmitHandler<T>
  onClose: () => void
}

export const BundleModal = <T extends FieldValues>({
  open,
  loading,
  action,
  methods,
  onClose,
  onSubmit
}: Props<T>) => {
  const { t } = useTranslation()

  const { allPublishedSeasons } = useBundles()

  const item = "item.bundle"

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("action.item", {
        action: `action.${action}`,
        item
      })}
    >
      {allPublishedSeasons?.length ? (
        <Form
          auditable={action === Actions.update}
          methods={methods}
          loading={loading}
          submitLabel={t(`action.${action}`)}
          onSubmit={onSubmit}
          gap={2}
          direction="column"
        >
          <FormInput
            type="text"
            name="bundleName"
            disabled={action === Actions.update}
            label={t("modifier.name", { item })}
            rules={{
              required: true
            }}
          />
          <FormMultiSelect
            name="seasonIds"
            label={t("item.seasons")}
            options={allPublishedSeasons.map(({ seasonName, seasonId }) => ({
              name: seasonId,
              label: seasonName
            }))}
            rules={{
              validate: (value) =>
                value.length > 0 || t("error.season.bundleSelectionLength")
            }}
          />
        </Form>
      ) : (
        <Typography color="warning">
          {t("error.season.emptySeasons")}
        </Typography>
      )}
    </Modal>
  )
}
