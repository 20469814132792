import {
  ApiError,
  BySpaceIdQuery,
  ConfigForSpaceId,
  ConfigCategoryUpdateRequest,
  ConfigCategoryDeleteRequest
} from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const configApi = api
  .enhanceEndpoints({ addTagTypes: ["Config"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      findAllConfigs: builder.query<ConfigForSpaceId, BySpaceIdQuery>({
        query: ({ spaceId }) => ({
          url: `space/${spaceId}/configs`
        }),
        providesTags: ["Config"],
        onQueryStarted({ spaceId }, { queryFulfilled }) {
          queryFulfilled.catch(({ error }) => {
            notify.error({
              message: i18next.t("api.findAllConfigs.error", { spaceId }),
              error: error as ApiError
            })
          })
        }
      }),
      updateConfigCategory: builder.mutation<void, ConfigCategoryUpdateRequest>(
        {
          query: ({ spaceId, configName, jsonData }) => ({
            url: `space/${spaceId}/config/${configName}`,
            method: "POST",
            body: jsonData
          }),
          invalidatesTags: ["Config"],
          onQueryStarted({ configName }, { queryFulfilled }) {
            queryFulfilled
              .then(() => {
                notify.success(
                  i18next.t("api.updateConfigCategory.success", { configName })
                )
              })
              .catch(({ error }) => {
                notify.error({
                  message: i18next.t("api.updateConfigCategory.error", {
                    configName
                  }),
                  error: error as ApiError
                })
              })
          }
        }
      ),
      deleteConfigCategory: builder.mutation<void, ConfigCategoryDeleteRequest>(
        {
          query: ({ spaceId, configName }) => ({
            url: `space/${spaceId}/config/${configName}`,
            method: "DELETE"
          }),
          invalidatesTags: ["Config"],
          onQueryStarted({ configName }, { queryFulfilled }) {
            queryFulfilled
              .then(() => {
                notify.success(
                  i18next.t("api.deleteConfigCategory.success", { configName })
                )
              })
              .catch(({ error }) => {
                notify.error({
                  message: i18next.t("api.deleteConfigCategory.error", {
                    configName
                  }),
                  error: error as ApiError
                })
              })
          }
        }
      )
    }),
    overrideExisting: false
  })

export const {
  useFindAllConfigsQuery,
  useUpdateConfigCategoryMutation,
  useDeleteConfigCategoryMutation
} = configApi
