import {
  isFulfilled,
  isRejected,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  UnknownAction
} from "@reduxjs/toolkit"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { RTKQueryAction } from "@/typings/redux"

interface EndpointConfig {
  success?: boolean
  error?: boolean
  successMsg?: string
  errorMsg?: string
}

/**
 * Middleware that automatically handles notifications for RTK Query
 * To disable success or error notifications on specific endpoints, provide:
 *
 * {
 *    extraOptions: {
 *      notify: EndpointConfig
 *    }
 * }
 *
 */
export const notificationsMiddleware: Middleware =
  (_: MiddlewareAPI) => (next) => (action: UnknownAction) => {
    const rtqAction = action as RTKQueryAction
    const endpointName = rtqAction.meta?.arg?.endpointName
    const args = rtqAction.meta?.arg?.originalArgs
    const method = rtqAction.meta?.baseQueryMeta?.request?.method
    const config: EndpointConfig = {
      success: method !== "GET",
      error: true,
      ...rtqAction.meta?.baseQueryMeta?.extraOptions?.notify
    }

    if (isFulfilled(action)) {
      if (endpointName) {
        if (config.success) {
          const msg = config.successMsg || `api.${endpointName}.success`

          notify.success({
            ...(i18next.exists(msg) && {
              message: i18next.t(msg, { ...args })
            })
          })
        }
      }
    }

    if (isRejected(action)) {
      const error = isRejectedWithValue(action)
        ? rtqAction.payload
        : rtqAction.error

      // Handle unexpected string errors
      if (typeof error === "string") {
        notify.error({ error })
        return next(action)
      } else {
        if (endpointName) {
          if (!config.error) return next(action)

          const msg = config.errorMsg || `api.${endpointName}.error`

          notify.error({
            ...(i18next.exists(msg) && {
              message: i18next.t(msg, { ...args })
            }),
            error
          })
        }
      }
    }

    return next(action)
  }
