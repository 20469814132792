import {
  Modal as JoyModal,
  ModalClose,
  ModalDialog,
  ModalProps,
  Stack,
  Typography
} from "@mui/joy"

interface Props extends ModalProps {
  title?: string
}

export const Modal: React.FC<Props> = ({
  title,
  children,
  ...props
}: Props) => {
  return (
    <JoyModal {...props}>
      <ModalDialog
        sx={{
          backfaceVisibility: "hidden"
        }}
      >
        <Stack>
          <ModalClose />
          {title && (
            <Typography level="h2" fontSize="lg" lineHeight={0.3} mb={1} pr={3}>
              {title}
            </Typography>
          )}
        </Stack>

        {children}
      </ModalDialog>
    </JoyModal>
  )
}
