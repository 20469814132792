import {
  formatJsonToString,
  sortStringsWithKeyword,
  isValidJson,
  ConfigCategory,
  Operation
} from "@liveops-portal/lib"
import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Select,
  Stack,
  Typography,
  Option
} from "@mui/joy"
import { Plus, TrashSolid, Xmark } from "iconoir-react"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { CodeEditor } from "@/components/code-editor/code-editor"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { Spinner } from "@/components/spinner/spinner"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import {
  useDeleteConfigCategoryMutation,
  useFindAllConfigsQuery,
  useUpdateConfigCategoryMutation
} from "@/store/api/config"
import {
  setActiveOperation,
  resetOperation,
  selectActiveOperation
} from "@/store/slices/audit"
import { selectSpace } from "@/store/slices/context"

/**
 * Component representing the config page.
 * If a space is selected, displays the configuration viewer.
 * If no space is selected, prompts the user to choose a space.
 */
export const ConfigPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const spaceId = useAppSelector(selectSpace)
  const activeOperation = useAppSelector(selectActiveOperation)
  const [categories, setCategories] = useState<string[]>([])
  const [isAdding, setIsAdding] = useState<boolean | undefined>()
  const [activeCategory, setActiveCategory] = useState<string | null>("general")
  const previousCategory = useRef<string>("general")
  const [initialConfig, setInitialConfig] = useState<ConfigCategory>()
  const [publicConfig, setPublicConfig] = useState<string>("{}")
  const [privateConfig, setPrivateConfig] = useState<string>("{}")
  const {
    data: config,
    isSuccess,
    isUninitialized,
    isFetching
  } = useFindAllConfigsQuery({ spaceId }, { skip: !spaceId })

  // UPDATE
  const [updateConfigCategory, updateMutationMeta] =
    useUpdateConfigCategoryMutation()
  const updateOperation: Operation = useMemo(() => ["config", "update"], [])
  const isUpdateOperationActive = isOperationActive(
    activeOperation,
    updateOperation
  )
  const isLoadingUpdate =
    isUpdateOperationActive || updateMutationMeta.isLoading

  // DELETE
  const [deleteConfigCategory, deleteMutationMeta] =
    useDeleteConfigCategoryMutation()
  const deleteOperation: Operation = ["config", "delete"]
  const isDeleteOperationActive = isOperationActive(
    activeOperation,
    deleteOperation
  )
  const isLoadingDelete =
    isDeleteOperationActive || deleteMutationMeta.isLoading

  const isConfigEdited = useMemo(
    () =>
      initialConfig &&
      config &&
      (formatJsonToString(initialConfig.public) !== publicConfig ||
        formatJsonToString(initialConfig.private) !== privateConfig),
    [config, initialConfig, privateConfig, publicConfig]
  )

  useEffect(() => {
    if (deleteMutationMeta.isSuccess) {
      dispatch(resetOperation())
      setActiveCategory("general")
      previousCategory.current = "general"
    }
  }, [dispatch, deleteMutationMeta.isSuccess])

  useEffect(() => {
    if (isConfigEdited && isSuccess) {
      dispatch(setActiveOperation(updateOperation))
    } else {
      setIsAdding(false)
      dispatch(resetOperation())
    }
  }, [dispatch, isConfigEdited, isSuccess, updateOperation])

  useEffect(() => {
    if (!config) return

    const _initialConfig =
      (activeCategory && config[activeCategory]) || ({} as ConfigCategory)

    setCategories(sortStringsWithKeyword(Object.keys(config), "general"))
    setInitialConfig(_initialConfig)
    setPublicConfig(formatJsonToString(_initialConfig.public))
    setPrivateConfig(formatJsonToString(_initialConfig.private))
  }, [activeCategory, config])

  const onSubmitHandler = () => {
    if (activeCategory) {
      if (isDeleteOperationActive) {
        deleteConfigCategory({
          spaceId,
          configName: activeCategory
        })
      }

      if (isUpdateOperationActive) {
        const updatedConfig = {
          private: JSON.parse(privateConfig),
          public: JSON.parse(publicConfig)
        }
        updateConfigCategory({
          spaceId,
          configName: activeCategory,
          jsonData: updatedConfig
        })
      }
    }
  }

  return isUninitialized ? (
    <Typography>
      {t("message.uninitialized.parent", {
        parent: "item.space",
        item: "item.configuration"
      })}
    </Typography>
  ) : (
    <>
      <Spinner loading={isFetching} />
      {config && Object.keys(config).length ? (
        <Stack component="form" sx={{ gap: 2, flexGrow: 1 }}>
          <OperationReason
            autofocus={isDeleteOperationActive}
            closeable={isDeleteOperationActive}
            open={!!isConfigEdited || isLoadingUpdate || isLoadingDelete}
            disabled={
              !!publicConfig &&
              !!privateConfig &&
              (!isValidJson(publicConfig) || !isValidJson(privateConfig))
            }
            onSubmit={onSubmitHandler}
          />

          <FormControl>
            <FormLabel>{t("label.configuration.configName")}</FormLabel>
            <Stack sx={{ flexDirection: "row", gap: 1, width: 300 }}>
              {isAdding ? (
                <Input
                  autoFocus
                  fullWidth
                  type="text"
                  name="configName"
                  placeholder={t("modifier.new", { item: "item.category" })}
                  onChange={(event) => {
                    const { value } = event.target
                    const formattedValue = value.replace(/\s/g, "-")
                    event.target.value = formattedValue
                    setActiveCategory(formattedValue)
                  }}
                />
              ) : (
                <Select
                  value={activeCategory}
                  name="configName"
                  disabled={isDeleteOperationActive}
                  onChange={(_, value) => {
                    if (value !== null) {
                      setActiveCategory(value)
                      previousCategory.current = value
                    }
                  }}
                  sx={{ flexGrow: 1 }}
                >
                  {categories?.map((option) => (
                    <Option key={option} label={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              )}
              {activeCategory !== "general" && !isAdding && (
                <IconButton
                  size="sm"
                  color="danger"
                  variant="solid"
                  aria-label={t("action.item", {
                    action: "action.delete",
                    item: "item.category"
                  })}
                  loading={isDeleteOperationActive}
                  onClick={() => {
                    dispatch(setActiveOperation(deleteOperation))
                  }}
                >
                  <TrashSolid />
                </IconButton>
              )}
              <IconButton
                size="sm"
                variant="outlined"
                title={
                  isAdding
                    ? t("action.cancel")
                    : t("action.item", {
                        action: "action.add",
                        item: "item.category"
                      })
                }
                disabled={isDeleteOperationActive}
                onClick={() => {
                  isAdding
                    ? setActiveCategory(previousCategory.current)
                    : setActiveCategory(null)
                  setIsAdding(!isAdding)
                }}
              >
                {isAdding ? <Xmark /> : <Plus />}
              </IconButton>
            </Stack>
            {activeCategory === "general" && (
              <FormHelperText>
                {t("message.configuration.generalReadOnly")}
              </FormHelperText>
            )}
          </FormControl>

          <Stack
            sx={{
              flexGrow: 1,
              gap: 1,
              flexDirection: "row",
              position: "relative"
            }}
          >
            <CodeEditor
              readOnly={activeCategory === "general"}
              name="_public"
              value={publicConfig}
              label={t("label.configuration.publicConfig")}
              onChange={setPublicConfig}
              error={!isValidJson(publicConfig)}
              sx={{ flexBasis: "50%" }}
            />
            <CodeEditor
              readOnly={activeCategory === "general"}
              name="_private"
              value={privateConfig}
              label={t("label.configuration.privateConfig")}
              onChange={setPrivateConfig}
              error={!isValidJson(privateConfig)}
              sx={{ flexBasis: "50%" }}
            />
          </Stack>
        </Stack>
      ) : (
        <Typography>
          {t("message.noItems.parent", {
            item: "item.configuration",
            parent: "item.space"
          })}
        </Typography>
      )}
    </>
  )
}
