import {
  DeployGameServerBuildMutation,
  GameServerBuild,
  GameServerBuildStatus,
  GameServerBuildStatusQuery,
  GameServerRegions,
  GameServerRegionsQuery,
  GetGameServerBuildQuery,
  GetGameServerBuildsQuery,
  PreserveGameServerBuildMutation,
  sortByKey,
  StopGameServerMutation,
  UnpreserveGameServerBuildMutation,
  WarmupGameServerMutation
} from "@liveops-portal/lib"
import { api } from "@/api"

export const gameServerBuildApi = api
  .enhanceEndpoints({
    addTagTypes: ["GameServerBuild", "GameServerBuildStatus"]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getGameServerBuilds: builder.query<
        GameServerBuild[],
        GetGameServerBuildsQuery
      >({
        query: ({ gameId }) => ({ url: `/game/${gameId}/game-server/builds` }),
        providesTags: (result) =>
          result
            ? result.map(({ buildId: id }) => ({ type: "GameServerBuild", id }))
            : ["GameServerBuild"],
        transformResponse: (builds: GameServerBuild[]) =>
          sortByKey(builds, "lastUpdate", "desc")
      }),
      getGameServerBuild: builder.query<
        GameServerBuild,
        GetGameServerBuildQuery
      >({
        query: ({ gameId, buildId }) => ({
          url: `/game/${gameId}/game-server/build/${buildId}`
        }),
        providesTags: (result, error, { buildId: id }) =>
          result ? [{ type: "GameServerBuild", id }] : []
      }),
      deployBuild: builder.mutation<void, DeployGameServerBuildMutation>({
        query: ({
          gameId,
          buildId,
          spaceId,
          envName,
          cpuType,
          numberOfCores,
          qdOperationReason
        }) => ({
          url: `/game/${gameId}/game-server/build/${buildId}/deploy`,
          method: "POST",
          body: {
            gameId,
            buildId,
            spaceId,
            envName,
            cpuType,
            numberOfCores
          },
          headers: { "QD-Operation-Reason": qdOperationReason }
        }),
        invalidatesTags: (result, error, { spaceId, buildId }) =>
          !error
            ? [
                { type: "GameServerBuild", id: buildId },
                { type: "GameServerBuildStatus", id: spaceId! }
              ]
            : []
      }),
      preserveBuild: builder.mutation<void, PreserveGameServerBuildMutation>({
        query: ({ gameId, buildId, qdOperationReason }) => ({
          url: `/game/${gameId}/game-server/build/${buildId}/preserve`,
          method: "POST",
          headers: { "QD-Operation-Reason": qdOperationReason }
        }),
        invalidatesTags: (result, error, { buildId: id }) =>
          !error ? [{ type: "GameServerBuild", id }] : []
      }),
      unpreserveBuild: builder.mutation<
        void,
        UnpreserveGameServerBuildMutation
      >({
        query: ({ gameId, buildId, qdOperationReason }) => ({
          url: `/game/${gameId}/game-server/build/${buildId}/unpreserve`,
          method: "POST",
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { buildId: id }) =>
          !error ? [{ type: "GameServerBuild", id }] : []
      }),
      getGameServerRegions: builder.query<
        GameServerRegions,
        GameServerRegionsQuery
      >({
        query: ({ gameId, spaceId }) => ({
          url: `/game/${gameId}/game-server/space/${spaceId}/regions`
        })
      }),
      getGameServerBuildStatus: builder.query<
        GameServerBuildStatus,
        GameServerBuildStatusQuery
      >({
        query: ({ gameId, spaceId }) => ({
          url: `/game/${gameId}/game-server/space/${spaceId}/status`
        }),
        providesTags: (result, error, { spaceId }) =>
          result ? [{ type: "GameServerBuildStatus", id: spaceId! }] : [],
        extraOptions: {
          notify: { error: false }
        }
      }),
      warmupGameServer: builder.mutation<void, WarmupGameServerMutation>({
        query: ({
          gameId,
          spaceId,
          envName,
          gameServerPerMachine,
          regions,
          hoursWarmup,
          qdOperationReason
        }) => ({
          url: `/game/${gameId}/game-server/space/${spaceId}/warmup`,
          method: "POST",
          body: {
            spaceId,
            envName,
            gameServerPerMachine,
            regions,
            hoursWarmup
          },
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        extraOptions: {
          notify: { success: false }
        },
        invalidatesTags: (result, error, { spaceId }) =>
          !error ? [{ type: "GameServerBuildStatus", id: spaceId! }] : []
      }),
      stopGameServer: builder.mutation<void, StopGameServerMutation>({
        query: ({ gameId, spaceId, qdOperationReason }) => ({
          url: `/game/${gameId}/game-server/space/${spaceId}/stop`,
          method: "POST",
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { spaceId }) =>
          !error ? [{ type: "GameServerBuildStatus", id: spaceId! }] : []
      })
    }),
    overrideExisting: false
  })

export const {
  useGetGameServerBuildQuery,
  useGetGameServerBuildsQuery,
  useGetGameServerBuildStatusQuery,
  useGetGameServerRegionsQuery,
  useDeployBuildMutation,
  usePreserveBuildMutation,
  useStopGameServerMutation,
  useUnpreserveBuildMutation,
  useWarmupGameServerMutation
} = gameServerBuildApi
