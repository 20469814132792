import { ApiError } from "."

export interface Notification {
  message: string
  error?: ApiError
  severity?: Severity
  actions?: NotificationAction[]
  exclude?: boolean
}

export interface NotificationAction {
  label: string
  handler: (...args: unknown[]) => void
}

export enum Severity {
  Info = "info",
  Warning = "warning",
  Success = "success",
  Error = "error"
}
