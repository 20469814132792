import { FunctionInterpolation } from "@emotion/react"
import { Theme } from "@mui/joy"
import { css } from "@mui/system"

/**
 * Styles defined here will affect THE ENTIRE APPLICATION.
 * Only add things here after careful considerations about side effects.
 */
const globalStyles: FunctionInterpolation<Theme> = () => css`
  * {
    transition-timing-function: ease;
    scrollbar-width: thin;
  }

  @-moz-document url-prefix() {
    * {
      scrollbar-width: thin;
    }
  }

  body {
    font-variant-numeric: tabular-nums;
    overflow: hidden;
  }

  svg[class*="iconoir"] {
    color: var(--Icon-color);
    margin: var(--Icon-margin);
    font-size: var(--Icon-fontSize, 20px);
    width: 1em;
    height: 1em;
  }

  @keyframes borderMove {
    100% {
      background-position:
        10px 0,
        -10px 100%,
        0 -10px,
        100% 10px;
    }
  }

  *:-webkit-autofill,
  *:autofill {
    transition:
      background-color 0s 2147483647s,
      color 0s 2147483647s !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`

export default globalStyles
