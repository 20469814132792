import { FunctionInterpolation } from "@emotion/react"
import { Theme } from "@mui/joy"
import { css } from "@mui/system"
import "react-toastify/dist/ReactToastify.css"

const toastifyStyles: FunctionInterpolation<Theme> = (theme) => css`
  /* stylelint-disable selector-class-pattern */
  :root {
    --toastify-color-light: ${theme.palette.neutral.softBg};
    --toastify-color-dark: ${theme.palette.neutral.softBg};
    --toastify-color-info: ${theme.palette.text.icon};
    --toastify-color-success: ${theme.palette.success};
    --toastify-color-warning: ${theme.palette.warning};
    --toastify-color-error: ${theme.palette.danger};

    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 0;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: ${theme.fontFamily.body};
    --toastify-z-index: 9999;

    --toastify-text-color-light: ${theme.palette.text.primary};
    --toastify-text-color-dark: ${theme.palette.text.primary};
  }

  .Toastify__toast-container {
    margin-top: var(--Header-height);
  }

  .Toastify__toast {
    padding: 0;
  }

  .Toastify__toast-body {
    padding: 0;
    margin: 0;
  }
`

export default toastifyStyles
