/**
 * Wraps an object in an array
 * @typeParam T - Type of object the array contains
 * @param condition
 * @param obj
 */
export const toArray = <T = object>(obj: T): T[] => {
  if (obj == null) {
    return []
  } else if (Array.isArray(obj)) {
    return obj
  } else {
    return [obj]
  }
}

/**
 * Allows to spread in conditionally an object in an array
 * @typeParam T - Type of object the array contains
 * @param condition
 * @param obj
 */
export const insertIf = <T = object>(condition: boolean, obj: T): T[] => {
  return condition ? toArray(obj) : []
}
