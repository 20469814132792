import { useMemo } from "react"
import { useLocation, matchRoutes } from "react-router-dom"
import { ROUTES } from "@/constants/routes"

export const useRoutePath = () => {
  const location = useLocation()

  return useMemo(
    () =>
      matchRoutes(ROUTES, location)
        ?.map(({ route: { path } }) => path)
        .filter(Boolean)
        .join("/")
        .replaceAll(/\/\*?\//g, "/"),
    [location]
  )
}
