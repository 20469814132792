import { Card, CardContent, Stack, Typography } from "@mui/joy"
import { skipToken } from "@reduxjs/toolkit/query"
import React from "react"
import { useTranslation } from "react-i18next"
import { useGetPlayerQuery } from "@/api/player"
import { useAppSelector } from "@/hooks/store"
import { selectSpace, selectPlayer } from "@/store/slices/context"

export const PlayerCustomizations: React.FC = () => {
  const { t } = useTranslation()
  const spaceId = useAppSelector(selectSpace)
  const { type, accountId } = useAppSelector(selectPlayer)
  const { currentData: playerProfile } = useGetPlayerQuery(
    spaceId && !!accountId.length && type
      ? { spaceId, accountId, type }
      : skipToken
  )

  return (
    !!playerProfile && (
      <Stack sx={{ gap: 2 }}>
        <Typography level="h3">{t("item.customizations")}</Typography>
        <Card
          variant="outlined"
          sx={{ minHeight: 87, overflow: "hidden", flexGrow: 1 }}
        >
          <CardContent sx={{ gap: 2 }}>
            <Typography color="warning" fontStyle="italic">
              {t("message.notImplemented.generic")}
            </Typography>
          </CardContent>
        </Card>
      </Stack>
    )
  )
}
