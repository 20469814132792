import {
  FormHelperText,
  FormControl,
  FormControlProps,
  RadioGroup,
  Radio,
  RadioGroupProps,
  RadioProps
} from "@mui/joy"
import { Key, useMemo } from "react"
import {
  Controller,
  ControllerProps,
  FieldValues,
  Path,
  PathValue,
  useFormContext
} from "react-hook-form"
import { FormLabel } from "@/components/form-label/form-label"

interface Props<T extends FieldValues>
  extends RadioGroupProps,
    Pick<FormControlProps, "orientation"> {
  name: Path<T>
  options: RadioProps[]
  label?: string
  rules?: ControllerProps<T>["rules"]
  hint?: string
  helper?: string
}

/**
 * Renders a Joy UI RadioGroup bound to React Hook Form.
 * @param name
 * @param label
 * @param rules
 * @param defaultValue
 * @param sx
 * @param hint
 * @param helper
 */
export const FormRadioGroup = <T extends FieldValues>({
  name,
  options,
  label,
  rules,
  hint,
  helper,
  defaultValue,
  orientation = "horizontal",
  sx,
  ...props
}: Props<T>) => {
  const {
    control,
    setValue,
    formState: { defaultValues }
  } = useFormContext<T>()

  const _defaultValue = useMemo(
    () => (defaultValues && eval(`defaultValues.${name}`)) || defaultValue,
    [defaultValues, name, defaultValue]
  )

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={_defaultValue}
      render={() => {
        return (
          <FormControl sx={sx}>
            {label && (
              <FormLabel required={!!rules?.required} hint={hint}>
                {label}
              </FormLabel>
            )}
            <RadioGroup
              {...props}
              orientation={orientation}
              name={name}
              size="sm"
              defaultValue={_defaultValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setValue(name, event.target.value as PathValue<T, Path<T>>)
              }
              sx={{
                minHeight: 32,
                padding: "4px 4px 4px",
                bgcolor: "neutral.softBg",
                display: "inline-flex",
                justifyContent: "center",
                m: 0,
                "--RadioGroup-gap": "4px",
                "--Radio-actionRadius": "4px"
              }}
            >
              {options.map(({ value, label: _label, disabled }) => (
                <Radio
                  disableIcon
                  key={value as Key}
                  value={value}
                  label={_label}
                  color="neutral"
                  variant="plain"
                  disabled={disabled}
                  sx={{
                    px: 2,
                    alignItems: "center",
                    textAlign: "center",
                    minWidth: "max-content",
                    flexGrow: 1
                  }}
                  slotProps={{
                    action: ({ checked }) => ({
                      sx: {
                        ...(checked && {
                          bgcolor: "background.surface",
                          boxShadow: "sm",
                          "&:hover": {
                            bgcolor: "background.surface"
                          }
                        })
                      }
                    })
                  }}
                />
              ))}
            </RadioGroup>
            {helper && (
              <FormHelperText sx={{ fontSize: "xs" }}>{helper}</FormHelperText>
            )}
          </FormControl>
        )
      }}
    />
  )
}
