import {
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType
} from "@azure/msal-browser"
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication
} from "@azure/msal-react"
import { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { Layout } from "@/components/layout/layout"
import { Spinner } from "@/components/spinner/spinner"
import { MSAL_LOGIN_REQUEST } from "@/constants/auth"
import { LoginPage } from "@/pages/login-page/login-page"

export const AuthGuard = () => {
  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    MSAL_LOGIN_REQUEST
  )
  const isAuthenticated = useIsAuthenticated()
  const { inProgress } = useMsal()
  const ssoInProgress = inProgress !== InteractionStatus.None

  useEffect(() => {
    /* c8 ignore start */ // MsalReactTester doesn't offer the possibility to test specific errors
    if (!error) return
    if (error instanceof InteractionRequiredAuthError) {
      ;(async () => await login(InteractionType.Redirect, MSAL_LOGIN_REQUEST))()
    }
    /* c8 ignore end */
  }, [error, login])

  return (
    <>
      <Spinner loading={ssoInProgress} />

      {!ssoInProgress &&
        (isAuthenticated ? (
          <Layout>
            <Outlet />
          </Layout>
        ) : (
          <LoginPage />
        ))}
    </>
  )
}
