import useUrlState from "@ahooksjs/use-url-state"
import { ActiveFilters, Filter } from "@liveops-portal/lib"
import { Button, Input, Stack } from "@mui/joy"
import { Search } from "iconoir-react"
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { FiltersSelect } from "@/components/filters-select/filters-select"

interface Props<T> {
  filters: Filter<T>[]
  searchable?: string[]
  onSearchChange?: (value: string) => void
  onFilterChange?: (value: string, accessor: string) => void
  onReset?: () => void
}

export const Filters = <T,>({
  filters,
  searchable,
  onSearchChange,
  onFilterChange,
  onReset
}: Props<T>) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const mounted = useRef(false)
  const filterState = useMemo(
    () =>
      filters.reduce(
        (acc, { accessor }) => ({ ...acc, [accessor]: [] }),
        {} as ActiveFilters<T>
      ),
    [filters]
  )
  const [urlState, setUrl] = useUrlState<ActiveFilters<T>>(filterState, {
    parseOptions: {
      arrayFormat: "comma"
    },
    stringifyOptions: {
      arrayFormat: "comma"
    }
  })
  const isFiltered = useMemo(
    () => urlState && Object.values(urlState).some((filter) => !!filter.length),
    [urlState]
  )

  const onInputChangeHandler: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { value } = event.target
    setUrl((curr) => ({
      ...curr,
      search: value.length ? value : undefined
    }))
    onSearchChange?.(value)
  }

  const onSelectChangeHandler: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { value, name: accessor } = event.target
    const key = accessor as keyof T
    setUrl((curr) => {
      const currentFilterValue: string[] =
        typeof curr[key] === "string" ? [curr[key]] : curr[key]
      const valueFiltered = currentFilterValue?.some((v) => v === value)
      const removedValue = currentFilterValue?.filter(
        (v: string) => v !== value
      )
      const updatedValue = currentFilterValue?.length
        ? [...currentFilterValue, value]
        : [value]
      return {
        ...curr,
        [accessor]: valueFiltered ? removedValue : updatedValue
      }
    })
    onFilterChange?.(accessor, value)
  }

  const initFilters = useCallback(() => {
    if (!mounted.current) {
      const searchParam = searchParams.get("search")

      if (searchable && searchParam != null) {
        onSearchChange?.(searchParam)
      }

      searchParams?.forEach((_, key) => {
        const filterValue = searchParams.get(key)
        if (key !== "search" && filterValue?.length) {
          onFilterChange?.(key, filterValue)
        }
      })

      mounted.current = true
    }
  }, [onFilterChange, onSearchChange, searchParams, searchable])

  useEffect(() => {
    initFilters()
  }, [initFilters])

  return (
    <Stack sx={{ gap: 2, flexDirection: "row" }}>
      {!!searchable && (
        <Input
          type="text"
          endDecorator={<Search />}
          value={urlState.search || ""}
          onChange={onInputChangeHandler}
        />
      )}

      {filters.map(({ label, options, accessor }) => (
        <FiltersSelect
          key={label}
          label={label}
          accessor={accessor}
          options={options}
          selected={urlState[accessor]}
          onChange={onSelectChangeHandler}
        />
      ))}

      {isFiltered && (
        <Button
          variant="outlined"
          onClick={() => {
            setUrl({ ...filterState, search: undefined })
            onReset?.()
          }}
        >
          {t("label.resetFilters")}
        </Button>
      )}
    </Stack>
  )
}
