export const formatJsonToString = (value: unknown) =>
  JSON.stringify(value || {}, null, 2)

export const formatWeeksToTimeSpan = (value: string | number) =>
  `${typeof value === "string" ? parseInt(value) * 7 : value * 7}.00:00:00`

export const formatTimeSpanToWeeks = (value: string | null | undefined) => {
  if (!value) {
    return 0
  }

  const regex: RegExp = /(\d+)\.(?=\d+)|(\d{2}):\d{2}:\d{2}/
  const match = value.match(regex)

  let days = 0
  if (match) {
    if (match[1]) {
      days = parseInt(match[1], 10)
    }
  }

  return days / 7
}
