import { InteractionRequiredAuthError } from "@azure/msal-browser"
import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError
} from "@reduxjs/toolkit/query/react"
import { captureException } from "@sentry/react"
import { MSAL_INSTANCE, MSAL_LOGIN_REQUEST } from "@/constants/auth"
import { resetOperation, selectOperationReason } from "@/store/slices/audit"
import { selectEnv } from "@/store/slices/context"

const baseQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.__CLIENT_URL}/api/`,
  prepareHeaders: async (headers, { getState }) => {
    /* c8 ignore start */
    // Auth
    const activeAccount = MSAL_INSTANCE.getActiveAccount()
    const accounts = MSAL_INSTANCE.getAllAccounts()

    const env = selectEnv(getState())
    const qdOperationReason = selectOperationReason(getState())
    const currentLocale = localStorage.getItem("localization")

    if (!headers.get("env") && env) headers.set("env", env)
    if (!headers.get("QD-Operation-Reason") && qdOperationReason)
      headers.set("QD-Operation-Reason", qdOperationReason)
    if (currentLocale) headers.set("Content-Language", currentLocale)

    if (activeAccount || accounts.length > 0) {
      const account = accounts[0]

      if (account) {
        try {
          const auth =
            await MSAL_INSTANCE.acquireTokenSilent(MSAL_LOGIN_REQUEST)

          if (auth.accessToken)
            headers.set("Authorization", `Bearer ${auth.accessToken}`)
          if (account.username) headers.set("QD-Username", account.username)
        } catch (e) {
          if (e instanceof InteractionRequiredAuthError) {
            await MSAL_INSTANCE.acquireTokenRedirect({
              ...MSAL_LOGIN_REQUEST,
              account
            })
          }
        }
      }
    }

    return headers
    /* c8 ignore end */
  }
})

/* Initial configuration of the RTK Query. Endpoints should be configured in respective features */
export const api = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    try {
      const result = await baseQuery(args, api, extraOptions)

      if (result.error) {
        return {
          ...result,
          meta: {
            ...result.meta,
            ...(extraOptions && { extraOptions })
          },
          error: {
            status: result.error.status,
            ...(!!result.error.data &&
              (typeof result.error.data === "string"
                ? { message: result.error.data }
                : { ...result.error.data }))
          }
        }
      }

      // Reset operationReason on successful call
      if (result.meta?.request.headers.has("Qd-Operation-Reason")) {
        api.dispatch(resetOperation())
      }

      return {
        ...result,
        meta: { ...result.meta, extraOptions },
        data: result.data
      }
    } catch (error) {
      const { status } = error as FetchBaseQueryError

      if (
        (typeof status === "number" && status >= 499) ||
        typeof status === "string"
      ) {
        captureException(error)
      }

      return { error }
    }
  },
  endpoints: () => ({})
})
