export interface HttpError {
  message: string
  path: string
  statusCode: number
  timestamp: string
  errors?: Record<string, string>
}

export type ApiError =
  | {
      message?: string
      error?: string
      statusCode: number
    }
  | string

// CUSTOM ERRORS

export enum ErrorKey {
  EmptySpaceConfiguration = "EmptySpaceConfiguration",
  MissingConfigurationCategory = "MissingConfigurationCategory",
  MissingConfigurationKeys = "MissingConfigurationKeys"
}

export type CustomErrors = Record<string, Partial<Record<ErrorKey, string>>>
