import {
  HealthcheckCreateRequest,
  HealthcheckDeleteRequest,
  Healthcheck,
  HealthcheckToggleRequest,
  HealthcheckToggleResponse,
  ByEnvQuery,
  ManualHealthcheck,
  HealthcheckTriggerRequest
} from "@liveops-portal/lib"
import { api } from "@/api"

export const healthcheckApi = api
  .enhanceEndpoints({ addTagTypes: ["Healthcheck"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getHealthchecks: builder.query<Healthcheck[], ByEnvQuery>({
        query: () => ({
          url: "healthchecks"
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ serviceName: id }) => ({
                  type: "Healthcheck" as const,
                  id
                })),
                "Healthcheck"
              ]
            : ["Healthcheck"]
      }),
      toggleHealthcheck: builder.mutation<
        HealthcheckToggleResponse,
        HealthcheckToggleRequest
      >({
        query: ({ serviceName, enabled }) => ({
          url: `healthchecks/${serviceName}`,
          body: { enabled: !enabled },
          method: "PATCH"
        }),
        invalidatesTags: (result, error, { serviceName: id }) =>
          !error ? [{ type: "Healthcheck", id }] : []
      }),
      createHealthcheck: builder.mutation<
        Healthcheck,
        HealthcheckCreateRequest
      >({
        query: ({
          serviceName,
          enabled,
          subscriptionKey,
          qdOperationReason,
          url,
          msTeamsNotifierWebhookId
        }) => ({
          url: "healthchecks",
          method: "POST",
          body: {
            serviceName,
            enabled,
            url,
            subscriptionKey,
            msTeamsNotifierWebhookId
          },
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: ["Healthcheck"]
      }),
      deleteHealthcheck: builder.mutation<void, HealthcheckDeleteRequest>({
        query: ({ serviceName }) => ({
          url: `healthchecks/${serviceName}`,
          method: "DELETE"
        }),
        invalidatesTags: (result, error, { serviceName: id }) =>
          !error ? [{ type: "Healthcheck", id }] : []
      }),
      triggerHealthchecks: builder.mutation<
        ManualHealthcheck[],
        HealthcheckTriggerRequest
      >({
        query: (body) => ({
          url: "healthchecks/trigger",
          method: "POST",
          body
        }),
        invalidatesTags: ["Healthcheck"]
      })
    }),
    overrideExisting: false
  })

export const {
  useGetHealthchecksQuery,
  useToggleHealthcheckMutation,
  useCreateHealthcheckMutation,
  useDeleteHealthcheckMutation,
  useTriggerHealthchecksMutation
} = healthcheckApi
