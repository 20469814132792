import { CircularProgress, Switch, SwitchProps, Typography } from "@mui/joy"
import React from "react"
import { useTranslation } from "react-i18next"

interface Props extends SwitchProps {
  checked: boolean
  loading?: boolean
  onClick?: (...args: unknown[]) => void
  disabled?: boolean
}

export const SwitchOnOff: React.FC<Props> = ({
  checked,
  loading,
  onClick,
  disabled,
  sx,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Switch
      disabled={disabled}
      checked={checked}
      color={checked ? "primary" : "neutral"}
      sx={{
        "--Switch-trackWidth": "55px",
        ...sx
      }}
      slotProps={{
        track: {
          children: loading ? (
            <CircularProgress
              variant="plain"
              data-testid="switch-pending"
              sx={{
                "--CircularProgress-size": "20px",
                "--CircularProgress-progressThickness": "3px",
                "--CircularProgress-progressColor": "white",
                /* c8 ignore next */ ml: checked ? "5px" : "30px",
                transition: "margin 0.2s"
              }}
            />
          ) : (
            <Typography
              fontSize="xs"
              textColor="common.white"
              p={1}
              ml={checked ? "1px" : "20px"}
            >
              {checked ? t("common.on") : t("common.off")}
            </Typography>
          )
        }
      }}
      onClick={onClick}
      {...props}
    />
  )
}
