import { Operation } from "@liveops-portal/lib"
import { Divider, Stack } from "@mui/joy"
import dayjs from "dayjs"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useGetBundlesQuery, useDeleteBundleMutation } from "@/api/bundle"
import { BundleUpdate } from "@/components/bundle-update/bundle-update"
import { CardItem } from "@/components/card-item/card-item"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { SeasonCard } from "@/components/season-card/season-card"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { selectActiveOperation, setActiveOperation } from "@/store/slices/audit"
import { selectEnv, selectGame } from "@/store/slices/context"

interface Props {
  bundleId: string
}

export const BundleCard: React.FC<Props> = ({ bundleId }) => {
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const skip = { skip: !gameId || !env }

  // UPDATE
  const [openUpdate, setOpenUpdate] = useState<boolean>(false)

  const activeOperation = useAppSelector(selectActiveOperation)

  const { bundle } = useGetBundlesQuery(
    { gameId },
    {
      ...skip,
      selectFromResult: ({ data }) => ({
        bundle: data?.find((bundle) => bundle.bundleId === bundleId)
      })
    }
  )

  // DELETE
  const [deleteBundle, deleteBundleMeta] = useDeleteBundleMutation()
  const deleteOperation: Operation = [bundleId, "delete"]
  const isDeleteOperationActive = isOperationActive(
    activeOperation,
    deleteOperation
  )
  const isLoadingDelete = isDeleteOperationActive || deleteBundleMeta.isLoading

  const onSubmitHandler = () => {
    if (isDeleteOperationActive) deleteBundle({ bundleId, gameId })
  }

  return (
    !!bundle && (
      <>
        <OperationReason
          open={isDeleteOperationActive}
          onSubmit={onSubmitHandler}
          autofocus
          closeable
        />

        <BundleUpdate
          open={openUpdate}
          setOpen={setOpenUpdate}
          {...bundle}
          seasonIds={bundle.bundleContent.cookedSeasonIds.map(
            ({ seasonId }) => seasonId
          )}
        />
        <CardItem
          key={bundle.bundleId}
          active={isLoadingDelete}
          chips={[{ content: "BUNDLE", color: "success" }]}
          title={{ content: bundle.bundleName }}
          subtitles={[
            { content: bundle.bundleId, copiable: true },
            {
              label: t("label.bundle.creationDate"),
              content: dayjs(bundle.creationDate).format(
                "YYYY-MM-DD HH:mm:ss z"
              )
            },
            {
              label: t("label.bundle.bundleVersion"),
              content: bundle.bundleVersion
            },
            { content: <Divider sx={{ mt: 1, mb: 1 }} /> },
            {
              content: `${t("label.bundle.seasonsInBundle")}:`
            },
            {
              content: (
                <Stack sx={{ gap: 1 }}>
                  {bundle.bundleContent.cookedSeasonIds.map(({ seasonId }) => (
                    <SeasonCard key={seasonId} seasonId={seasonId} />
                  ))}
                </Stack>
              )
            }
          ]}
          actions={{
            update: {
              handler: () => {
                setOpenUpdate(true)
              }
            },
            delete: {
              handler: () => {
                dispatch(setActiveOperation(deleteOperation))
              }
            }
          }}
        />
      </>
    )
  )
}
