import {
  Operation,
  Season,
  formatTimeSpanToWeeks,
  insertIf
} from "@liveops-portal/lib"
import { Divider, IconButton, Stack, Typography } from "@mui/joy"
import { Eye } from "iconoir-react"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useGetDataVersionsQuery } from "@/api/game-data"
import { useDeleteSeasonMutation, useGetSeasonsQuery } from "@/api/season"
import { CardItem } from "@/components/card-item/card-item"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { SeasonPublish } from "@/components/season-publish/season-publish"
import { SeasonUpdate } from "@/components/season-update/season-update"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { selectActiveOperation, setActiveOperation } from "@/store/slices/audit"
import { selectEnv, selectGame } from "@/store/slices/context"

interface Props {
  seasonId: Season["seasonId"]
}

export const SeasonCard: React.FC<Props> = ({ seasonId }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const skip = { skip: !gameId || !env }

  const { data: dataVersions, isFetching: isFetchingDataVersions } =
    useGetDataVersionsQuery({ gameId, env }, skip)
  const { season } = useGetSeasonsQuery(
    { gameId, env },
    {
      ...skip,
      selectFromResult: ({ data }) => ({
        season: data?.find((season) => season.seasonId === seasonId)
      })
    }
  )

  const activeOperation = useAppSelector(selectActiveOperation)

  const getSeasonDetails = (season: Season, getDraft: boolean = false) => {
    // Has published revision
    if (season.latestPublishedSeasonRevision && !getDraft) {
      return season.latestPublishedSeasonRevision.seasonRevisionDetails
    }

    return season.latestDraftSeasonRevision.seasonRevisionDetails
  }

  const getWeeks = useCallback(
    (duration: string) => formatTimeSpanToWeeks(duration),
    []
  )

  // UPDATE
  const [openUpdate, setOpenUpdate] = useState<boolean>(false)

  // PUBLISH
  const [openPublish, setOpenPublish] = useState<boolean>(false)

  // DELETE
  const [deleteSeason, deleteSeasonMeta] = useDeleteSeasonMutation()
  const deleteOperation: Operation = [seasonId, "delete"]
  const isDeleteOperationActive = isOperationActive(
    activeOperation,
    deleteOperation
  )
  const isLoadingDelete = isDeleteOperationActive || deleteSeasonMeta.isLoading

  const onSubmitHandler = () => {
    if (isDeleteOperationActive) deleteSeason({ seasonId, gameId })
  }

  const { seasonName, latestPublishedRevision } = season || {}

  const getSectionsProps = (season: Season) => {
    const { duration, clientDataVersion } = getSeasonDetails(season)
    const {
      duration: draftDuration,
      clientDataVersion: draftClientDataVersion
    } = getSeasonDetails(season, true)

    const renderSectionDetails = (
      data: string | number,
      draftData: string | number,
      title: string
    ) => ({
      title,
      content: (
        <Stack>
          <Typography level="body-sm" sx={{ color: "inherit" }}>
            {data}
          </Typography>
          <Divider inset="context" sx={{ mb: 1, mt: 1 }} />
          <Typography sx={{ fontStyle: "italic" }} level="body-xs">
            {t("state.draft")} {title}:
          </Typography>
          <Typography
            level="body-sm"
            sx={{ fontStyle: "italic", color: "inherit" }}
          >
            {draftData}
          </Typography>
        </Stack>
      ),
      props: {
        minWidth: "72px"
      }
    })

    return [
      renderSectionDetails(
        t("time.duration.week", {
          count: getWeeks(duration)
        }),
        t("time.duration.week", {
          count: getWeeks(draftDuration)
        }),
        t("label.time.duration")
      ),
      renderSectionDetails(
        clientDataVersion,
        draftClientDataVersion,
        t("item.dataVersion")
      ),
      {
        title: t("label.season.viewData"),
        content: (
          <Stack sx={{ alignItems: "center" }}>
            <IconButton
              size="sm"
              color="primary"
              variant="solid"
              aria-label={t("action.view")}
              component={Link}
              to={`data-viewer/${seasonId}`}
              sx={{ mt: 1, width: "40px" }}
            >
              <Eye />
            </IconButton>
          </Stack>
        )
      }
    ]
  }

  return (
    !!season && (
      <>
        <OperationReason
          open={isDeleteOperationActive}
          onSubmit={onSubmitHandler}
          autofocus
          closeable
        />

        <SeasonUpdate
          open={openUpdate}
          setOpen={setOpenUpdate}
          {...season}
          {...getSeasonDetails(season)}
        />
        <SeasonPublish
          open={openPublish}
          setOpen={setOpenPublish}
          seasonId={season.seasonId}
          revision={season.latestDraftSeasonRevision.revision}
        />

        <CardItem
          title={{ content: seasonName }}
          active={isLoadingDelete}
          chips={[
            { content: t("item.season"), color: "primary" },
            ...insertIf(latestPublishedRevision === -1, {
              content: t("state.draft"),
              color: "warning" as const
            })
          ]}
          subtitles={[
            { content: seasonId, copiable: true },
            {
              label: t("label.season.latestPublishedRevision"),
              content: latestPublishedRevision
            }
          ]}
          sections={getSectionsProps(season)}
          actions={{
            update: {
              handler: () => {
                setOpenUpdate(true)
              },
              disabled: isFetchingDataVersions || !dataVersions?.length
            },
            publish: {
              handler: () => {
                setOpenPublish(true)
              }
            },
            delete: {
              handler: () => {
                dispatch(setActiveOperation(deleteOperation))
              }
            }
          }}
        />
      </>
    )
  )
}
