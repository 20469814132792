import { InteractionRequiredAuthError } from "@azure/msal-browser"
import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError
} from "@reduxjs/toolkit/query/react"
import { addBreadcrumb, captureException } from "@sentry/react"
import { MSAL_INSTANCE, MSAL_LOGIN_REQUEST } from "@/constants/auth"
import { selectOperationReason } from "@/store/slices/audit"
import { selectEnv } from "@/store/slices/context"

const baseQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.__CLIENT_URL}/api/`,
  prepareHeaders: async (headers, { getState }) => {
    /* c8 ignore start */
    // Auth
    const activeAccount = MSAL_INSTANCE.getActiveAccount()
    const accounts = MSAL_INSTANCE.getAllAccounts()

    if (activeAccount || accounts.length > 0) {
      const account = accounts[0]

      if (account) {
        const qdOperationReason = selectOperationReason(getState())
        const currentLocale = localStorage.getItem("localization")

        try {
          const auth =
            await MSAL_INSTANCE.acquireTokenSilent(MSAL_LOGIN_REQUEST)

          if (!headers.get("env")) headers.set("env", selectEnv(getState()))
          qdOperationReason &&
            headers.set("QD-Operation-Reason", qdOperationReason)
          currentLocale && headers.set("Content-Language", currentLocale)
          auth.accessToken &&
            headers.set("Authorization", `Bearer ${auth.accessToken}`)
          account.username && headers.set("QD-Username", account.username)
        } catch (e) {
          if (e instanceof InteractionRequiredAuthError) {
            await MSAL_INSTANCE.acquireTokenRedirect({
              ...MSAL_LOGIN_REQUEST,
              account
            })
          }
        }
      }
    }

    return headers
    /* c8 ignore end */
  }
})

/* Initial configuration of the RTK Query. Endpoints should be configured in respective features */
export const api = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    try {
      const { data, error, meta } = await baseQuery(args, api, extraOptions)

      if (error) {
        addBreadcrumb({
          type: "error",
          category: "api",
          message:
            typeof error === "string"
              ? error
              : error.data &&
                  typeof error.data === "object" &&
                  "message" in error.data
                ? (error.data.message as string)
                : JSON.stringify(error.data),
          level: "error",
          data: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            endpoint: (meta as any)?.endpoint
          }
        })
        throw error
      }

      return { data }
    } catch (error) {
      const { status, data } = error as FetchBaseQueryError
      if (
        (typeof status === "number" && status >= 499) ||
        typeof status === "string"
      )
        captureException(error)
      return { error: data }
    }
  },
  endpoints: () => ({})
})
