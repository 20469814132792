import { useAccount } from "@azure/msal-react"
import { useMemo } from "react"

export const useIsAdmin = () => {
  const account = useAccount()

  return useMemo(
    () =>
      Boolean(
        account &&
          account.idTokenClaims &&
          account.idTokenClaims.roles?.some((role) => role === "App.Admin")
      ),
    [account]
  )
}
