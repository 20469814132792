import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup"
import {
  Card,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemDecorator,
  Typography,
  listItemButtonClasses
} from "@mui/joy"
import { ColorPaletteProp } from "@mui/joy"
import { Fade, Collapse } from "@mui/material"
import { NavArrowDown } from "iconoir-react"
import React, {
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react"
import { useTranslation } from "react-i18next"
import {
  generatePath,
  matchPath,
  NavLink,
  RouteObject,
  useLocation
} from "react-router-dom"
import { useAppSelector } from "@/hooks/store"
import { selectIsSidebarExpanded } from "@/store/slices/app"
import {
  selectEnv,
  selectGame,
  selectSeason,
  selectSpace
} from "@/store/slices/context"

export const SidebarItem: React.FC<RouteObject> = ({
  id,
  path,
  handle: { name, icon: Icon, hideSidebar },
  children
}: RouteObject) => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)
  const seasonId = useAppSelector(selectSeason)
  const expanded = useAppSelector(selectIsSidebarExpanded)
  const { pathname } = useLocation()
  const to = useMemo(
    () => path && generatePath(path, { env, gameId, spaceId, seasonId }),
    [env, gameId, path, seasonId, spaceId]
  )
  const itemSelected = to && !!matchPath(to, pathname)
  const subItemSelected = !!matchPath(`${to}/*`, pathname) && id !== "overview"
  const [open, setOpen] = useState<boolean>(itemSelected || subItemSelected)
  const [menuId, setMenuId] = React.useState<null | string>(null)
  const [isOnButton, setIsOnButton] = React.useState<boolean>(false)
  const [isOnMenu, setIsOnMenu] = React.useState<boolean>(false)
  const anchor = useRef<HTMLAnchorElement | null>(null)
  const color = env as ColorPaletteProp

  const { root: rootClass, selected: selectedClass } = listItemButtonClasses

  const onClickHandler = () => {
    if (expanded) {
      setOpen(!open)
    }
  }

  const onMouseEnterHandler: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (!expanded) {
      setMenuId(id!)
      setIsOnButton(true)
      anchor.current = event.currentTarget
    }
  }

  const onMouseLeaveHandler = () => !expanded && setIsOnButton(false)

  useEffect(() => {
    if (expanded) {
      setOpen(itemSelected || subItemSelected)
    } else {
      setOpen(false)
    }
  }, [expanded, itemSelected, subItemSelected])

  useEffect(() => {
    if (!isOnButton && !isOnMenu) {
      setMenuId((latestId: null | string) =>
        latestId === id ? null : latestId
      )
    }
  }, [id, isOnButton, isOnMenu])

  const renderSubItems = () =>
    children?.map(
      ({
        id: subItemId,
        path: _path,
        element: subItemElement,
        Component: subItemComponent,
        index,
        handle: { name: subItemName }
      }) => {
        const subItemPath =
          index && path && !_path ? path : path ? `${path}/${_path}` : _path

        const to =
          subItemPath &&
          generatePath(subItemPath, {
            env,
            gameId,
            spaceId,
            seasonId
          })

        const selected =
          to && index ? matchPath(to, pathname) : matchPath(`${to}/*`, pathname)

        return (
          <ListItem
            key={subItemId}
            sx={{
              ...(!expanded && { "--ListItem-paddingRight": 0 })
            }}
          >
            <ListItemButton
              component={subItemComponent ? NavLink : "a"}
              selected={!!selected}
              to={to}
              disabled={!subItemComponent && !subItemElement}
              sx={(theme) => ({
                fontSize: "sm",
                borderWidth: 0,
                borderLeftWidth: 2,
                ...(!expanded && { px: theme.spacing(2) }),
                [`&.${rootClass}.${selectedClass}`]: {
                  borderLeftColor: theme.palette[color].solidBg,
                  bgcolor: "transparent"
                },
                [`&.${rootClass}:hover`]: {
                  bgcolor: "neutral.softHoverBg"
                }
              })}
            >
              {t(subItemName)}
            </ListItemButton>
          </ListItem>
        )
      }
    )

  const renderSubMenu = () =>
    children ? (
      <List
        aria-expanded={expanded ? open : menuId === id}
        variant={expanded ? "soft" : "outlined"}
        sx={({ spacing }) => ({
          "--ListItem-minHeight": spacing(4),
          ...(!expanded && {
            borderRadius: "sm",
            overflow: "hidden",
            minWidth: 175,
            bgcolor: "background.body",
            marginLeft: spacing(1)
          })
        })}
      >
        {expanded ? (
          <Collapse in={open} timeout={200}>
            {renderSubItems()}
          </Collapse>
        ) : (
          renderSubItems()
        )}
      </List>
    ) : (
      !expanded && (
        <Card size="sm" sx={({ spacing }) => ({ p: 1, ml: spacing(1) })}>
          {t(name)}
        </Card>
      )
    )

  return (
    !hideSidebar && (
      <ListItem
        nested
        slotProps={{
          endAction: {
            style: {
              opacity: Number(expanded && !!children),
              pointerEvents: expanded && !!children ? "all" : "none"
            }
          }
        }}
        endAction={
          <Fade in={expanded} timeout={200}>
            <IconButton
              size="sm"
              sx={{
                transform: open ? "none" : "rotate(-90deg)",
                transitionProperty: "all",
                ":hover": { bgcolor: "transparent" }
              }}
              onClick={onClickHandler}
              aria-label="toggle sidebar"
            >
              <NavArrowDown />
            </IconButton>
          </Fade>
        }
      >
        <ListItem component="div">
          <ListItemButton
            component={to ? NavLink : "a"}
            to={to}
            onClick={onClickHandler}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            selected={itemSelected || subItemSelected}
            sx={({ vars: { palette } }) => ({
              borderWidth: 0,
              borderLeftWidth: 2,
              [`&.${rootClass}.${selectedClass}`]: {
                "--Icon-color": palette[color].softColor,
                borderLeftColor: palette[color].solidBg,
                bgcolor: palette[color].softBg
              }
            })}
          >
            {!!Icon && (
              <ListItemDecorator>
                <Icon />
              </ListItemDecorator>
            )}

            <Fade in={expanded} timeout={200}>
              <Typography noWrap fontSize="sm" textColor="text.secondary">
                {t(name)}
              </Typography>
            </Fade>
          </ListItemButton>
        </ListItem>
        {!expanded ? (
          <BasePopup
            role={children ? "menu" : "tooltip"}
            open={menuId === id}
            offset={-8}
            anchor={anchor.current}
            placement="right-start"
            /* Testing library cannot mimic cursor transition */
            /* c8 ignore start */
            onMouseEnter={() => setIsOnMenu(true)}
            onMouseLeave={() => setIsOnMenu(false)}
            /* c8 ignore end */
            style={{ zIndex: "popup" }}
          >
            {renderSubMenu()}
          </BasePopup>
        ) : (
          renderSubMenu()
        )}
      </ListItem>
    )
  )
}
