import { Actions, SeasonCreateRequest } from "@liveops-portal/lib"
import { Button } from "@mui/joy"
import { Plus } from "iconoir-react"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { SeasonModal } from "@/components/season-modal/season-modal"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useFindAllGameDataVersionsQuery } from "@/store/api/game-data"
import { useCreateSeasonMutation } from "@/store/api/season"
import { selectEnv, selectGame } from "@/store/slices/context"

interface FormData extends SeasonCreateRequest {}

export const SeasonCreate: React.FC = () => {
  const dispatch = useAppDispatch()
  const methods = useForm<FormData>({ mode: "all" })
  const { reset: resetForm } = methods
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const [createSeason, mutation] = useCreateSeasonMutation()
  const { isSuccess, isLoading: isLoadingCreate } = mutation

  const { isFetching, isError } = useFindAllGameDataVersionsQuery(
    { gameId, env },
    { skip: !gameId || !env }
  )

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm])

  const onSubmitHandler = (data: FormData) => createSeason({ ...data, gameId })

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [dispatch, isSuccess, onCloseHandler])

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        startDecorator={<Plus />}
        disabled={isFetching || isError}
      >
        {t("action.create")}
      </Button>

      <SeasonModal
        open={open}
        loading={isLoadingCreate}
        action={Actions.create}
        methods={methods}
        onSubmit={onSubmitHandler}
        onClose={onCloseHandler}
      />
    </>
  )
}
