import {
  Box,
  FormControl,
  FormLabel,
  Select,
  Typography,
  IconButton,
  Option
} from "@mui/joy"
import { Updater } from "@tanstack/react-table"
import { ArrowLeft, ArrowRight } from "iconoir-react"
import { useTranslation } from "react-i18next"

interface Props {
  count: number
  page: number
  pageSize: number
  pageSizeOptions: ReadonlyArray<number>
  onPageChange: (updater: Updater<number>) => void
  onPageSizeChange: (updater: Updater<number>) => void
}

export const TablePagination: React.FC<Props> = ({
  count,
  page,
  onPageChange,
  pageSize,
  pageSizeOptions,
  onPageSizeChange
}: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <FormControl orientation="horizontal" size="sm">
        <FormLabel>{t("label.rowsPerPage")}:</FormLabel>
        <Select
          onChange={(_, pageNumber) =>
            pageNumber && onPageSizeChange(pageNumber)
          }
          value={pageSize}
        >
          {pageSizeOptions.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      </FormControl>
      <Typography sx={{ textAlign: "center", minWidth: 80 }}>
        {t("label.pagination", {
          from: count === 0 ? 0 : page * pageSize + 1,
          to: Math.min(count, (page + 1) * pageSize),
          count
        })}
      </Typography>
      <Box sx={{ display: "flex", gap: 1 }}>
        <IconButton
          aria-label={t("label.previousPage")}
          size="sm"
          color="neutral"
          variant="outlined"
          disabled={page === 0}
          onClick={() => onPageChange(page - 1)}
          sx={{ bgcolor: "background.surface" }}
        >
          <ArrowLeft />
        </IconButton>
        <IconButton
          aria-label={t("label.nextPage")}
          size="sm"
          color="neutral"
          variant="outlined"
          disabled={page >= Math.ceil(count / pageSize) - 1}
          onClick={() => onPageChange(page + 1)}
          sx={{ bgcolor: "background.surface" }}
        >
          <ArrowRight />
        </IconButton>
      </Box>
    </>
  )
}
