import { UniservicesRequest } from "@liveops-portal/lib"
import {
  Button,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemDecorator,
  Stack,
  Typography
} from "@mui/joy"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  onClick: (index: number) => void
}

/**
 * Lists all requests done via the Toolbox.
 * @param onClick - Returns the index of the clicked history item.
 */
export const ToolboxHistory: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation()
  const [history, setHistory] = useState<UniservicesRequest[]>([])
  const localStorageToolboxHistory = localStorage.getItem("toolboxHistory")

  useEffect(
    () => setHistory(JSON.parse(localStorageToolboxHistory || "[]")),
    [localStorageToolboxHistory]
  )

  const onToolboxHistoryClearClick = () => {
    localStorage.removeItem("toolboxHistory")
    setHistory([])
  }

  return (
    <>
      <Stack sx={{ justifyContent: "space-between", flexDirection: "row" }}>
        <Typography level="title-lg">{t("label.history")}</Typography>
        {history.length ? (
          <Button
            size="sm"
            variant="outlined"
            onClick={onToolboxHistoryClearClick}
          >
            {t("action.clear")}
          </Button>
        ) : null}
      </Stack>
      {history.length ? (
        <List
          sx={{
            maxHeight: {
              xs: "20vh",
              lg: "calc(100vh - var(--Header-height) - 131px)"
            },
            overflow: "scroll",
            "--ListItem-paddingX": 0,
            "--ListItem-paddingY": ({ spacing }) => spacing(1),
            "--List-gap": 0
          }}
        >
          {history.map(({ method, endpoint, serviceName }, index) => (
            <ListItem key={`toolbox-history-item-${index}`}>
              <ListItemButton
                data-testid="historyItem"
                onClick={() => onClick(index)}
                sx={{ alignItems: "flex-start" }}
              >
                <ListItemDecorator
                  sx={{ width: "60px", justifyContent: "center", mt: "2px" }}
                >
                  <Chip>{method}</Chip>
                </ListItemDecorator>
                <Typography
                  sx={{
                    display: { xs: "flex", xl: "block" },
                    flexDirection: "column"
                  }}
                >
                  <Typography
                    level="body-sm"
                    fontWeight="xl"
                    textColor="text.secondary"
                    sx={{ mr: 1 }}
                  >
                    {serviceName}
                  </Typography>
                  <Typography level="body-xs">{endpoint}</Typography>
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography marginTop={2} level="body-xs">
          {t("message.noRequest")}
        </Typography>
      )}
    </>
  )
}
