const isObject = (value: unknown): value is { [key: string]: unknown } =>
  typeof value === "object" && value !== null

export const findValue = (
  obj: { [key: string]: unknown } | object | Record<string, unknown>,
  path: string
): unknown => {
  const keys = path.toLowerCase().split(".")
  let current: unknown = obj

  for (const key of keys) {
    if (!isObject(current)) return null

    const foundKey = Object.keys(current).find((k) => k.toLowerCase() === key)
    if (!foundKey) return null

    current = current[foundKey]
  }

  return current
}
