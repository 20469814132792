import { GlobalStyles, IconButton, Stack } from "@mui/joy"
import { Bell, SidebarExpand } from "iconoir-react"
import React from "react"
import { useTranslation } from "react-i18next"
import { useGetEnvironmentsQuery } from "@/api/environment"
import { EnvSelector } from "@/components/env-selector/env-selector"
import { GameSelector } from "@/components/game-selector/game-selector"
import { HelpDropdown } from "@/components/help-dropdown/help-dropdown"
import { ModeSwitcher } from "@/components/mode-switcher/mode-switcher"
import { PlayerSelector } from "@/components/player-selector/player-selector"
import { SeasonSelector } from "@/components/season-selector/season-selector"
import { SpaceSelector } from "@/components/space-selector/space-selector"
import { UserDropdown } from "@/components/user-dropdown/user-dropdown"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import {
  selectIsNotificationCenterOpen,
  selectIsSidebarExpanded,
  toggleNotificationCenter,
  toggleSidebar
} from "@/store/slices/app"

export const Header: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isSidebarExpanded = useAppSelector(selectIsSidebarExpanded)
  const isNotificationCenterOpen = useAppSelector(
    selectIsNotificationCenterOpen
  )
  const { data: envs } = useGetEnvironmentsQuery()

  const onClickExpandSidebarHandler = () => {
    dispatch(toggleSidebar())
  }

  const onClickToggleNotificationCenter = () => {
    dispatch(toggleNotificationCenter())
  }

  return (
    <>
      <GlobalStyles
        styles={{
          ":root": {
            "--Header-height": "52px"
          }
        }}
      />
      <Stack
        component="header"
        direction="row"
        alignItems="center"
        borderBottom={1}
        borderColor="divider"
        height="var(--Header-height)"
        p={1}
        position="sticky"
        top={0}
      >
        {!isSidebarExpanded && (
          <Stack mr={1}>
            <IconButton
              size="sm"
              title={t("action.item", {
                action: "action.expand",
                item: "item.sidebar"
              })}
              onClick={onClickExpandSidebarHandler}
              data-testid="app-sidebar-expand"
              aria-controls="sidebar"
              aria-expanded={isSidebarExpanded}
            >
              <SidebarExpand />
            </IconButton>
          </Stack>
        )}
        <Stack sx={{ flexDirection: "row", gap: 1, flexGrow: 1 }}>
          {envs?.length && (
            <>
              <EnvSelector />
              <GameSelector />
              <SpaceSelector />
              <SeasonSelector />
              <PlayerSelector />
            </>
          )}
        </Stack>
        <Stack sx={{ flexDirection: "row", mr: "5px", gap: "5px" }}>
          <HelpDropdown />

          <ModeSwitcher />

          <IconButton
            size="sm"
            title={
              isNotificationCenterOpen
                ? t("hint.closeNotificationCenter")
                : t("hint.openNotificationCenter")
            }
            onClick={onClickToggleNotificationCenter}
            data-testid="notification-center-toggle-button"
            sx={{ ":hover": { bgcolor: "transparent" } }}
          >
            <Bell />
          </IconButton>
          <UserDropdown />
        </Stack>
      </Stack>
    </>
  )
}
