import { Card, CardContent, List, ListItem, Stack, Typography } from "@mui/joy"
import { skipToken } from "@reduxjs/toolkit/query"
import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import { useGetPlayerQuery } from "@/api/player"
import { PlayerReset } from "@/components/player-reset/player-reset"
import { useAppSelector } from "@/hooks/store"
import { selectSpace, selectPlayer } from "@/store/slices/context"

export const PlayerInfo: React.FC = () => {
  const { t } = useTranslation()
  const spaceId = useAppSelector(selectSpace)
  const { type, accountId } = useAppSelector(selectPlayer)
  const { currentData: playerProfile } = useGetPlayerQuery(
    spaceId && !!accountId.length && type
      ? { spaceId, accountId, type }
      : skipToken
  )

  return (
    !!playerProfile && (
      <Stack sx={{ gap: 2 }}>
        <Typography level="h3">{t("label.player.info")}</Typography>
        <Card variant="outlined" sx={{ flexGrow: 1 }}>
          <CardContent sx={{ gap: 2 }}>
            <List
              sx={{
                p: 0,
                "--ListItem-minHeight": 0,
                "--ListItem-paddingX": 0
              }}
            >
              {!!playerProfile.playtime && (
                <ListItem>
                  <Typography level="title-sm">
                    {t("label.player.playtime")}:
                  </Typography>
                  <Typography level="body-sm">
                    {dayjs
                      .duration(playerProfile.playtime, "ms")
                      .format("D[d] H[h] m[m]")}
                  </Typography>
                </ListItem>
              )}
              {!!playerProfile.lastOnline && (
                <ListItem>
                  <Typography level="title-sm">
                    {t("state.lastOnline")}:
                  </Typography>
                  <Typography level="body-sm">
                    {dayjs(playerProfile.lastOnline).format(
                      "YYYY-MM-DD HH:mm:ss z"
                    )}
                  </Typography>
                </ListItem>
              )}
              <ListItem>
                <Typography level="title-sm">
                  {t("label.player.ranking")}:
                </Typography>
                <Typography level="body-sm">{playerProfile.ranking}</Typography>
              </ListItem>
            </List>

            <PlayerReset accountId={playerProfile.accountId} />
          </CardContent>
        </Card>
      </Stack>
    )
  )
}
