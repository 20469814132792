import {
  AllApplicationsByGameQuery,
  ApiError,
  Application,
  ByEnvQuery
} from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const applicationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllApplicationsByGameId: builder.query<
      Application[],
      AllApplicationsByGameQuery & ByEnvQuery
    >({
      query: ({ gameId, includeMarkedForDeletion }) => ({
        url: `applications`,
        params: { gameId, includeMarkedForDeletion }
      }),
      onQueryStarted({ gameId }, { queryFulfilled }) {
        queryFulfilled.catch(({ error }) => {
          notify.error({
            message: i18next.t("api.getAllApplicationsByGameId.error", {
              gameId
            }),
            error: error as ApiError
          })
        })
      }
    })
  }),
  overrideExisting: false
})

export const { useGetAllApplicationsByGameIdQuery } = applicationApi
