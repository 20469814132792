import { CreateApplicationMutation } from "@liveops-portal/lib"
import { Button } from "@mui/joy"
import { Plus } from "iconoir-react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useCreateApplicationMutation } from "@/api/application"
import { useGetAllGamesQuery } from "@/api/game"
import { useFindAllSpacesQuery } from "@/api/space"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { FormSelect } from "@/components/form-select/form-select"
import { Modal } from "@/components/modal/modal"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useIsAdmin } from "@/hooks/useIsAdmin"
import { selectEnv, selectGame, selectSpace } from "@/store/slices/context"

type FormData = CreateApplicationMutation

export const ApplicationCreate: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)
  const isAdmin = useIsAdmin()

  const [open, setOpen] = useState<boolean>(false)

  const { data: games } = useGetAllGamesQuery({ env }, { skip: !env })
  const { data: _spaces } = useFindAllSpacesQuery({ env }, { skip: !env })
  const [createApp, mutation] = useCreateApplicationMutation()
  const { isSuccess, isLoading } = mutation

  const defaultValues = useMemo(
    () => ({
      gameId,
      defaultSpaceId: spaceId,
      qdOperationReason: "Application created from LiveOps Portal"
    }),
    [gameId, spaceId]
  )

  const methods = useForm<FormData>({
    mode: "all",
    defaultValues
  })
  const { reset: resetForm, watch } = methods
  const selectedGame = watch("gameId")
  const spaces = useMemo(
    () => _spaces?.filter((space) => space.gameId === selectedGame),
    [_spaces, selectedGame]
  )

  const onCloseHandler = useCallback(() => {
    resetForm(defaultValues)
    setOpen(false)
  }, [defaultValues, resetForm])

  const onSubmitHandler = (data: FormData) => createApp(data)

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [dispatch, isSuccess, onCloseHandler])

  useEffect(() => {
    if (spaces) {
      resetForm({
        ...defaultValues,
        gameId: selectedGame,
        defaultSpaceId: spaces.map(({ spaceId }) => spaceId).includes(spaceId)
          ? spaceId
          : null
      })
    }
  }, [defaultValues, resetForm, spaces, selectedGame, spaceId])

  return (
    isAdmin && (
      <>
        <Button
          variant="outlined"
          onClick={() => setOpen(true)}
          startDecorator={<Plus />}
        >
          {t("action.item", {
            action: "action.create",
            item: "item.application"
          })}
        </Button>

        <Modal
          open={open}
          onClose={onCloseHandler}
          title={t("action.item", {
            action: "action.create",
            item: "item.application"
          })}
        >
          <Form
            methods={methods}
            loading={isLoading}
            submitLabel={t("action.create")}
            onSubmit={onSubmitHandler}
            sx={{ gap: 2 }}
            fieldsToTrim={["name"]}
          >
            <FormSelect
              name="gameId"
              label={t("item.game")}
              options={
                games?.map(({ name, gameId }) => ({
                  label: name,
                  value: gameId
                })) || []
              }
              rules={{ required: true }}
            />
            <FormSelect
              name="defaultSpaceId"
              label={t("label.defaultSpaceId")}
              options={
                spaces?.map(({ spaceName, spaceId }) => ({
                  label: spaceName,
                  value: spaceId
                })) || []
              }
              rules={{ required: true }}
            />
            <FormInput
              type="text"
              name="name"
              label={t("modifier.name", { item: "item.application" })}
              rules={{
                required: true
              }}
            />
          </Form>
        </Modal>
      </>
    )
  )
}
