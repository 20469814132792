import { CustomErrors } from "@liveops-portal/lib"
import { List, ListItem, Stack, Typography } from "@mui/joy"
import { MessageAlert } from "iconoir-react"
import React from "react"
import { useTranslation } from "react-i18next"

interface Props {
  errors: CustomErrors
}

export const CustomErrorsList: React.FC<Props> = ({ errors }) => {
  const { t } = useTranslation()

  return (
    <Stack>
      <Typography startDecorator={<MessageAlert />} color="warning">
        {t("message.configuration.fixNeeded")}
      </Typography>
      <List marker="disc">
        {Object.entries(errors).map(([key, value]) => (
          <ListItem key={key}>
            <Typography level="body-sm">{Object.values(value)[0]}</Typography>
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}
