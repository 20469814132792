import { Environment } from "@liveops-portal/lib"
import { api } from "@/api"

export const environmentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEnvironments: builder.query<Environment[], void>({
      query: () => ({
        url: "environments"
      })
    })
  }),
  overrideExisting: false
})

export const { useGetEnvironmentsQuery } = environmentApi
