import { OperationReason } from "./audit.typings"
import { Space } from "./space.typings"

export enum FeatureSwitchValueTypes {
  OVERRIDDEN = "state.overridden"
}

export interface FeatureSwitch<T = boolean> {
  switchName: string
  switchDescription: string
  switchType: string
  switchValue: T
  defaultValue: T
  overrideValue: T | null
}

export interface FeatureSwitchSetRequest
  extends Pick<FeatureSwitch, "switchName" | "switchValue"> {
  spaceId: Space["spaceId"]
  qdOperationReason?: OperationReason["qdOperationReason"]
}

export interface FeatureSwitchDeleteRequest
  extends Pick<FeatureSwitch, "switchName"> {
  spaceId: Space["spaceId"]
  qdOperationReason?: OperationReason["qdOperationReason"]
}
