import useUrlState from "@ahooksjs/use-url-state"
import { PlayerId, PlayerIdType } from "@liveops-portal/lib"
import { Divider, IconButton, Input, Option, Select } from "@mui/joy"
import { skipToken } from "@reduxjs/toolkit/query"
import { Search } from "iconoir-react"
import React, { useEffect, useMemo, useRef } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useGetPlayerQuery } from "@/api/player"
import { EntityChip } from "@/components/entity-chip/entity-chip"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useRoutePath } from "@/hooks/useRoutePath"
import { selectPlayer, selectSpace, setPlayerId } from "@/store/slices/context"

export const PlayerSelector: React.FC = () => {
  const { t } = useTranslation()
  const mounted = useRef(false)
  const currentPath = useRoutePath()
  const dispatch = useAppDispatch()
  const spaceId = useAppSelector(selectSpace)
  const { type, accountId } = useAppSelector(selectPlayer)
  const [urlState, setUrl] = useUrlState<{
    [key in PlayerIdType]: string
  }>()
  const presetId: PlayerId = useMemo(
    () =>
      Object.keys(urlState).reduce((acc, k) => {
        const key = k as PlayerIdType
        acc = { type: key, accountId: urlState[key] }
        return acc
      }, {} as PlayerId),
    [urlState]
  )
  const {
    handleSubmit,
    getValues,
    control,
    setValue,
    formState: { isValid }
  } = useForm<PlayerId>({
    mode: "all",
    defaultValues: {
      accountId: presetId.accountId || "",
      type: presetId.type || PlayerIdType.sdkuid
    }
  })
  const { isFetching, refetch } = useGetPlayerQuery(
    spaceId && type && !!accountId.length
      ? {
          spaceId,
          type,
          accountId
        }
      : skipToken
  )
  const onSubmit: SubmitHandler<PlayerId> = (data) => {
    if (data.type === type && data.accountId === accountId) {
      refetch()
      return
    }

    if (data.type) {
      setUrl({ [data.type]: data.accountId })
    }
  }

  const disabled = isFetching || !spaceId

  useEffect(() => {
    if (currentPath?.includes("players") && spaceId) {
      if (!mounted.current) {
        mounted.current = true
      }

      if (presetId.accountId && presetId.type) {
        dispatch(setPlayerId(presetId))
        setValue("accountId", presetId.accountId)
        setValue("type", presetId.type)
      }
    } else {
      if (mounted.current) {
        dispatch(setPlayerId({ accountId: "", type: getValues("type") }))
        setValue("accountId", "")
      }
    }
  }, [spaceId, dispatch, getValues, presetId, setValue, currentPath])

  return (
    currentPath?.includes("players") && (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="accountId"
          control={control}
          render={({ field: inputField }) => {
            const type = getValues("type")
            return (
              <Input
                {...inputField}
                value={inputField.value || ""}
                aria-label={t("label.playerSelector")}
                disabled={disabled}
                placeholder={
                  type === PlayerIdType.sdkuid
                    ? t(`placeholder.player.sdkuid`)
                    : t(`placeholder.player.other`, { type })
                }
                startDecorator={<EntityChip type="player" sx={{ mr: 1 }} />}
                endDecorator={
                  <>
                    <Divider orientation="vertical" />
                    <Controller
                      name="type"
                      control={control}
                      render={({ field: selectField }) => (
                        <Select
                          data-testid="player-selector-type"
                          {...selectField}
                          variant="plain"
                          disabled={disabled}
                          slotProps={{
                            listbox: {
                              variant: "outlined"
                            }
                          }}
                          onChange={(_, newValue) => {
                            selectField.onChange(newValue)
                            setUrl({ [presetId.type as string]: undefined })
                            setValue("accountId", "")
                          }}
                          sx={{ "&:hover": { bgcolor: "transparent" } }}
                        >
                          {Object.keys(PlayerIdType).map((key) => (
                            <Option
                              key={key}
                              value={key}
                              disabled={
                                key === PlayerIdType.xbox ||
                                key === PlayerIdType.psn
                              }
                            >
                              {key === PlayerIdType.sdkuid
                                ? t(`label.service.uni`)
                                : t(`label.service.${key}`)}
                            </Option>
                          ))}
                        </Select>
                      )}
                    />
                    <Divider orientation="vertical" />
                    <IconButton
                      type="submit"
                      variant="solid"
                      color="primary"
                      disabled={disabled || isFetching || !isValid}
                      aria-label={t(`action.search`)}
                      title={t(`action.search`)}
                      sx={{
                        ml: 0.5
                      }}
                      slotProps={{
                        loadingIndicator: { "data-testid": "loading" }
                      }}
                    >
                      <Search />
                    </IconButton>
                  </>
                }
                sx={{ "--Input-gap": "0px", width: 500 }}
              />
            )
          }}
        />
      </form>
    )
  )
}
