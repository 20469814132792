import {
  GameServerBuild,
  GameServerBuildDeploymentType,
  DataState,
  GameServerBuildType
} from "@liveops-portal/lib"
import { Stack, Typography } from "@mui/joy"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Filters } from "@/components/filters/filters"
import { GameServerBuildCard } from "@/components/game-server-build-card/game-server-build-card"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { useGetGameServerBuildsQuery } from "@/store/api/game-server-build"
import { selectEnv, selectGame } from "@/store/slices/context"

/**
 * Component representing the build page.
 */
export const GameServerBuildsPage: React.FC = () => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)

  const { data: gameServerBuilds, isFetching } = useGetGameServerBuildsQuery(
    { gameId, env },
    { skip: !gameId || !env }
  )
  const [filteredGameServerBuilds, setFilteredGameServerBuilds] = useState<
    GameServerBuild[] | undefined
  >()

  const searchable = useMemo(
    () => ["buildName", "buildId", "codeChangeList", "dataChangeList"],
    []
  )

  const filters = [
    {
      accessor: "buildType" as const,
      label: t("label.gameServerBuild.buildType"),
      options: Object.keys(GameServerBuildType).map((key) => ({
        label: t(
          `${GameServerBuildType[key as keyof typeof GameServerBuildType]}`
        ),
        value: key
      }))
    },
    {
      accessor: "deploymentType" as const,
      label: t("label.gameServerBuild.deploymentType"),
      options: Object.keys(GameServerBuildDeploymentType).map((key) => ({
        label: t(
          `${GameServerBuildDeploymentType[key as keyof typeof GameServerBuildDeploymentType]}`
        ),
        value: key
      }))
    },
    {
      accessor: "state" as const,
      label: t("label.gameServerBuild.state"),
      options: Object.keys(DataState).map((key) => ({
        label: t(`${DataState[key as keyof typeof DataState]}`),
        value: key
      }))
    }
  ]

  const onSearchChangeHandler = (searchString: string) => {
    setFilteredGameServerBuilds((filteredItems) =>
      filteredItems?.filter((item) =>
        searchable.some((key) => {
          const value = item[key as keyof GameServerBuild]?.toString()
          return value?.includes(searchString)
        })
      )
    )
  }

  const onFilterChangeHandler = (accessor: string, filterValue: string) => {
    setFilteredGameServerBuilds((filteredItems) =>
      filteredItems?.filter((item) =>
        filterValue?.includes(item[accessor as keyof GameServerBuild] as string)
      )
    )
  }

  const onResetHandler = () => setFilteredGameServerBuilds(gameServerBuilds)

  useEffect(() => {
    setFilteredGameServerBuilds(gameServerBuilds)
  }, [gameServerBuilds])

  return (
    <>
      <Spinner loading={isFetching || !filteredGameServerBuilds} />

      {filteredGameServerBuilds ? (
        <Stack sx={{ gap: 2 }}>
          <Filters
            searchable={searchable}
            filters={filters}
            onSearchChange={onSearchChangeHandler}
            onFilterChange={onFilterChangeHandler}
            onReset={onResetHandler}
          />
          {filteredGameServerBuilds.length ? (
            <Stack sx={{ gap: 2 }}>
              {filteredGameServerBuilds.map((build: GameServerBuild) => (
                <GameServerBuildCard key={build.buildId} {...build} />
              ))}
            </Stack>
          ) : (
            <Typography>
              {t("message.noItems.selectedCriteria", {
                item: "item.builds"
              })}
            </Typography>
          )}
        </Stack>
      ) : (
        <Typography>
          {t("message.noItems.parent", {
            item: "item.builds",
            parent: "item.game"
          })}
        </Typography>
      )}
    </>
  )
}
