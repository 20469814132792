import { Deck, DataOption } from "@liveops-portal/lib"
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Chip,
  FormControl,
  Select,
  Option,
  CardOverflow
} from "@mui/joy"
import { skipToken } from "@reduxjs/toolkit/query"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useGetPlayerQuery } from "@/api/player"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"
import { FormLabel } from "@/components/form-label/form-label"
import { useAppSelector } from "@/hooks/store"
import { useDataType } from "@/hooks/useDataType"
import { selectSpace, selectPlayer } from "@/store/slices/context"

export const PlayerDecks: React.FC = () => {
  const { t } = useTranslation()
  const mounted = useRef(false)
  const spaceId = useAppSelector(selectSpace)
  const { type, accountId } = useAppSelector(selectPlayer)
  const { currentData: playerProfile } = useGetPlayerQuery(
    spaceId && !!accountId.length && type
      ? { spaceId, accountId, type }
      : skipToken
  )
  const { lastSpellcasterPlayed: _lastSpellcasterPlayed, decks: _decks } =
    playerProfile || {}

  const { data: spellcasters } = useDataType("spellcasters")
  const { data: catalysts } = useDataType("catalysts")
  const { data: spells } = useDataType("spells")

  const [activeSpellcaster, setActiveSpellcaster] = useState<DataOption>()
  const [activeCatalyst, setActiveCatalyst] = useState<DataOption>()
  const [activeDeck, setActiveDeck] = useState<Deck | null>(null)

  const lastSpellcasterPlayed = useMemo(
    () =>
      (!!_lastSpellcasterPlayed &&
        spellcasters?.find(({ id: i }) =>
          i.includes(_lastSpellcasterPlayed)
        )) ||
      null,
    [spellcasters, _lastSpellcasterPlayed]
  )

  const getSpellcasterCatalysts = useCallback(
    (spellcasterLabel: string) =>
      catalysts?.filter(({ label: l }) => l.includes(spellcasterLabel)),
    [catalysts]
  )

  const getCatalystDecks = useCallback(
    (catalystId: string) =>
      _decks?.filter(({ catalystId: id }) => id === catalystId),
    [_decks]
  )

  const init = useCallback(() => {
    const initialSpellcaster = lastSpellcasterPlayed || spellcasters[0]
    const initialCatalyst = getSpellcasterCatalysts(
      initialSpellcaster?.label
    )[0]
    const initialDeck = getCatalystDecks(initialCatalyst?.id)?.[0] || null

    setActiveSpellcaster(initialSpellcaster)
    setActiveCatalyst(initialCatalyst)
    setActiveDeck(initialDeck)
  }, [
    getCatalystDecks,
    getSpellcasterCatalysts,
    lastSpellcasterPlayed,
    spellcasters
  ])

  useEffect(() => {
    if (
      !!playerProfile &&
      !!spellcasters.length &&
      !!catalysts.length &&
      !mounted.current
    ) {
      init()

      mounted.current = true
    }
  }, [playerProfile, spellcasters, catalysts, init])

  return (
    !!playerProfile && (
      <Stack sx={{ gap: 2 }}>
        <Typography level="h3">{t("item.decks")}</Typography>
        <Card
          variant="outlined"
          sx={{ minHeight: 87, overflow: "hidden", flexGrow: 1 }}
        >
          <CardContent sx={{ gap: 2 }}>
            {playerProfile.decks?.length ? (
              <Stack sx={{ flexDirection: "row", gap: 1 }}>
                {!!spellcasters && (
                  <FormControl
                    sx={{
                      width: ({ spacing }) => `calc(50% - ${spacing(0.5)})`
                    }}
                  >
                    <FormLabel>{t("item.spellcaster")}</FormLabel>

                    <Select
                      value={
                        activeSpellcaster ? activeSpellcaster.id : "placeholder"
                      }
                      onChange={(_, value) => {
                        const newSpellcaster = spellcasters.find(
                          ({ id }) => id === value
                        )!
                        const newCatalyst = getSpellcasterCatalysts(
                          newSpellcaster.label
                        )[0]

                        setActiveSpellcaster(newSpellcaster)
                        setActiveCatalyst(newCatalyst)
                        setActiveDeck(
                          getCatalystDecks(newCatalyst.id)?.[0] || null
                        )
                      }}
                      endDecorator={
                        lastSpellcasterPlayed?.id === activeSpellcaster?.id && (
                          <Chip color="success" sx={{ pointerEvents: "none" }}>
                            {t("state.lastPlayed")}
                          </Chip>
                        )
                      }
                    >
                      <Option disabled value="placeholder">
                        {t("placeholder.option")}
                      </Option>
                      {spellcasters?.map(({ id, label }) => (
                        <Option key={id} label={label} value={id}>
                          <Stack
                            sx={{
                              flexGrow: 1,
                              flexDirection: "row",
                              justifyContent: "space-between"
                            }}
                          >
                            <Typography>{label}</Typography>
                            {lastSpellcasterPlayed?.id === id && (
                              <Chip
                                color="success"
                                sx={{ pointerEvents: "none" }}
                              >
                                {t("state.lastPlayed")}
                              </Chip>
                            )}
                          </Stack>
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {!!catalysts && (
                  <FormControl
                    sx={{
                      width: ({ spacing }) => `calc(50% - ${spacing(0.5)})`
                    }}
                  >
                    <FormLabel
                      hint={t("message.notImplemented.lastCatalystUsed")}
                    >
                      {t("item.catalyst")}
                    </FormLabel>

                    <Select
                      value={activeCatalyst ? activeCatalyst.id : "placeholder"}
                      disabled={!activeSpellcaster}
                      onChange={(_, value) => {
                        const newCatalyst = catalysts.find(
                          ({ id }) => id === value
                        )!

                        if (newCatalyst) {
                          setActiveCatalyst(newCatalyst)
                          setActiveDeck(
                            getCatalystDecks(newCatalyst.id)?.[0] || null
                          )
                        }
                      }}
                    >
                      <Option disabled value="placeholder">
                        {t("placeholder.option")}
                      </Option>
                      {!!activeSpellcaster &&
                        catalysts
                          ?.filter(({ label }) =>
                            label.includes(activeSpellcaster.label)
                          )
                          .map(({ id, label }) => (
                            <Option key={id} label={label} value={id}>
                              {label}
                            </Option>
                          ))}
                    </Select>
                  </FormControl>
                )}
              </Stack>
            ) : (
              <Typography color="warning" fontStyle="italic">
                {t("error.player.noDecks")}
              </Typography>
            )}
          </CardContent>
          {!!playerProfile.decks?.length &&
            !!activeSpellcaster &&
            !!activeCatalyst && (
              <CardOverflow
                variant="soft"
                sx={{
                  pt: 1,
                  pb: 2,
                  borderTop: "1px solid",
                  borderColor: "divider"
                }}
              >
                <Stack sx={{ gap: 2 }}>
                  <>
                    <FormControl sx={{ width: "100%", mt: 1 }}>
                      <FormLabel>{t("item.deck")}</FormLabel>

                      {getCatalystDecks(activeCatalyst.id)?.length &&
                      !!activeDeck ? (
                        <Select
                          value={activeDeck.deckId}
                          disabled={
                            !activeCatalyst ||
                            !getCatalystDecks(activeCatalyst.id)?.length
                          }
                          onChange={(_, value) => {
                            const newDeck = getCatalystDecks(
                              activeCatalyst.id
                            )?.find(({ deckId }) => deckId === value)

                            if (newDeck) {
                              setActiveDeck(newDeck)
                            }
                          }}
                          endDecorator={
                            activeDeck.isCatalystLastDeckUsed && (
                              <Chip
                                color="success"
                                sx={{ pointerEvents: "none" }}
                              >
                                {t("state.lastPlayed")}
                              </Chip>
                            )
                          }
                        >
                          <Option disabled value="placeholder">
                            {t("placeholder.option")}
                          </Option>
                          {getCatalystDecks(activeCatalyst.id)?.map(
                            ({ deckName, deckId, isCatalystLastDeckUsed }) => (
                              <Option
                                key={deckId}
                                label={deckName}
                                value={deckId}
                              >
                                <Stack
                                  sx={{
                                    flexGrow: 1,
                                    flexDirection: "row",
                                    justifyContent: "space-between"
                                  }}
                                >
                                  <Typography>{deckName}</Typography>
                                  {isCatalystLastDeckUsed && (
                                    <Chip
                                      color="success"
                                      sx={{ pointerEvents: "none" }}
                                    >
                                      {t("state.lastPlayed")}
                                    </Chip>
                                  )}
                                </Stack>
                              </Option>
                            )
                          )}
                        </Select>
                      ) : (
                        <Typography>
                          {t("message.noItems.parent", {
                            item: "item.deck",
                            parent: "item.catalyst"
                          })}
                        </Typography>
                      )}
                    </FormControl>

                    {activeDeck && (
                      <>
                        <Stack
                          sx={{
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1
                          }}
                        >
                          <Typography level="title-sm">
                            {t("id.deck")}:
                          </Typography>
                          <Typography level="body-sm">
                            <CopyToClipboard>
                              {activeDeck.deckId}
                            </CopyToClipboard>
                          </Typography>
                        </Stack>

                        <Stack
                          sx={{
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: 1
                          }}
                        >
                          <Typography level="title-sm">
                            {t("item.spells")}:
                          </Typography>
                          {!!spells &&
                            activeDeck.slotItems.map((spellId) => {
                              const spell = spells.find(
                                ({ id }) => id === spellId.split(":")[0]
                              )

                              return (
                                !!spell && (
                                  <Chip key={spellId} color="primary">
                                    {spell.label}
                                  </Chip>
                                )
                              )
                            })}
                        </Stack>
                      </>
                    )}
                  </>
                </Stack>
              </CardOverflow>
            )}
        </Card>
      </Stack>
    )
  )
}
