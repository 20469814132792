import { ApiError, Space } from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const playerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findPlayerNamecardProfilesBySdkuid: builder.query<
      Record<string, string>[],
      { sdkuid: string; spaceId: Space["spaceId"] }
    >({
      query: ({ sdkuid, spaceId }) => ({
        url: `players/${sdkuid}/namecard/profile`,
        params: { spaceId }
      }),
      onQueryStarted({ sdkuid }, { queryFulfilled }) {
        queryFulfilled.catch(({ error }) => {
          notify.error({
            message: i18next.t("api.findPlayerNamecardProfilesBySdkuid.error", {
              sdkuid
            }),
            error: error as ApiError
          })
        })
      }
    })
  }),
  overrideExisting: false
})

export const { useLazyFindPlayerNamecardProfilesBySdkuidQuery } = playerApi
