import {
  FormLabel as JoyFormLabel,
  Tooltip,
  SvgIcon,
  FormLabelProps
} from "@mui/joy"
import { InfoCircle } from "iconoir-react"

interface Props extends FormLabelProps {
  hint?: string
}

/**
 * Renders a Joy UI FormLabel with an option hint.
 * @param hint
 */
export const FormLabel = ({ hint, children, ...props }: Props) => {
  return (
    <JoyFormLabel {...props}>
      {children}
      {!!hint && (
        <Tooltip title={hint} size="sm" arrow placement="right">
          <SvgIcon fontSize="md" sx={{ ml: 0.5 }}>
            <InfoCircle />
          </SvgIcon>
        </Tooltip>
      )}
    </JoyFormLabel>
  )
}
