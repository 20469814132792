import { ApiError, ResetProgressionRequest } from "@liveops-portal/lib"
import { THREADOFFATE_BATTLEPASS_ID } from "@/constants/battlepass"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const battlepassApi = api.injectEndpoints({
  endpoints: (builder) => ({
    resetThreadOfFateProgression: builder.mutation<
      void,
      Omit<ResetProgressionRequest, "battlepassId">
    >({
      query: ({ spaceId, accountId }) => ({
        url: `space/${spaceId}/player/${accountId}/battlepass/${THREADOFFATE_BATTLEPASS_ID}/progression/reset`,
        method: "POST"
      }),
      onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled
          .then(() => {
            notify.success(
              i18next.t("api.resetThreadOfFateProgression.success")
            )
          })
          .catch(({ error }) => {
            notify.error({
              message: i18next.t("api.resetThreadOfFateProgression.error"),
              error: error as ApiError
            })
          })
      }
    })
  }),
  overrideExisting: false
})

export const { useResetThreadOfFateProgressionMutation } = battlepassApi
