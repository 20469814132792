import { Operation } from "@liveops-portal/lib"
import { Button, Stack, Typography } from "@mui/joy"
import { skipToken } from "@reduxjs/toolkit/query"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useApplyPlayerPresetMutation, useGetPresetsQuery } from "@/api/player"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { selectActiveOperation, setActiveOperation } from "@/store/slices/audit"
import { selectSpace } from "@/store/slices/context"

type Props = {
  accountId: string
}

export const PlayerReset: React.FC<Props> = ({ accountId }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const activeOperation = useAppSelector(selectActiveOperation)
  const spaceId = useAppSelector(selectSpace)
  const { data: presets } = useGetPresetsQuery(
    spaceId ? { spaceId } : skipToken
  )
  const [appliedPreset, setAppliedPreset] = useState<string | null>(null)
  const [applyPreset] = useApplyPlayerPresetMutation()

  const operation: Operation = ["player", "applyPreset"]

  const open = isOperationActive(activeOperation, operation)

  const onSubmitHandler = () =>
    appliedPreset &&
    applyPreset({ spaceId, presetId: appliedPreset, accountId })

  const onCloseHandler = () => {
    setAppliedPreset(null)
  }

  const disabled = open || !!activeOperation

  return (
    <>
      <OperationReason
        open={open}
        onSubmit={onSubmitHandler}
        onClose={onCloseHandler}
        autofocus
        closeable
      />

      {!!presets?.length && (
        <Stack sx={{ gap: 1 }}>
          <Typography level="title-sm">{t("action.resetAs")}:</Typography>
          <Stack sx={{ flexDirection: "row", gap: 1 }}>
            {presets.map((preset) => (
              <Button
                variant="soft"
                key={preset.presetId}
                loading={appliedPreset === preset.presetId}
                disabled={disabled}
                onClick={() => {
                  dispatch(setActiveOperation(operation))
                  setAppliedPreset(preset.presetId)
                }}
                sx={{ flexGrow: 1, width: `calc(100% / ${presets.length})` }}
              >
                {i18n.exists(`label.player.presets.${preset.displayName}`)
                  ? t(`label.player.presets.${preset.displayName}`)
                  : preset.displayName}
              </Button>
            ))}
          </Stack>
        </Stack>
      )}
    </>
  )
}
