import { FieldError } from "react-hook-form"
import i18next from "@/i18n"

/**
 * Returns default translated error messages if one is not provided
 * @param error
 * @param _label
 */
export const getFormErrorMessage = (error: FieldError, _label?: string) => {
  const { t } = i18next
  if (error.message) return error.message

  const label = _label || "common.this"

  switch (error.type) {
    case "required":
      return t("error.required", { label })
    case "min":
      return t("error.min", { label })
    case "max":
      return t("error.max", { label })
    case "minLength":
      return t("error.minLength", { label })
    case "maxLength":
      return t("error.maxLength", { label })
  }
}
