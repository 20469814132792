import {
  Application,
  CreateApplicationMutation,
  GetApplicationsQuery
} from "@liveops-portal/lib"
import { api } from "@/api"

export const applicationApi = api
  .enhanceEndpoints({
    addTagTypes: ["Application"]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getApplications: builder.query<Application[], GetApplicationsQuery>({
        query: ({ gameId, includeMarkedForDeletion }) => ({
          url: `game/${gameId}/applications`,
          params: { includeMarkedForDeletion }
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ appId: id }) => ({
                  type: "Application" as const,
                  id
                })),
                "Application"
              ]
            : ["Application"]
      }),
      createApplication: builder.mutation<void, CreateApplicationMutation>({
        query: ({ gameId, defaultSpaceId, name, qdOperationReason }) => {
          return {
            url: `game/${gameId}/applications`,
            method: "POST",
            body: {
              defaultSpaceId,
              name
            },
            headers: {
              "QD-Operation-Reason": qdOperationReason
            }
          }
        },
        invalidatesTags: ["Application"]
      })
    }),
    overrideExisting: false
  })

export const { useGetApplicationsQuery, useCreateApplicationMutation } =
  applicationApi
