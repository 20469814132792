import { CircularProgress, Stack } from "@mui/joy"
import { Fade } from "@mui/material"

interface Props {
  loading: boolean
}

export const Spinner = ({ loading }: Props) => {
  return (
    <Fade in={loading} timeout={200} unmountOnExit>
      <Stack
        sx={{
          position: "absolute",
          inset: 0,
          bgcolor: "background.body",
          zIndex: 9999,
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <CircularProgress data-testid="loading" />
      </Stack>
    </Fade>
  )
}
