import {
  FeatureSwitchSetRequest,
  FeatureSwitchDeleteRequest,
  BySpaceIdQuery,
  FeatureSwitch,
  ApiError
} from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const featureSwitchApi = api
  .enhanceEndpoints({ addTagTypes: ["FeatureSwitches"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAllFeatureSwitches: builder.query<FeatureSwitch[], BySpaceIdQuery>({
        query: ({ spaceId }) => ({
          url: "/feature-switches",
          params: { spaceId }
        }),
        providesTags: ["FeatureSwitches"],
        onQueryStarted({ spaceId }, { queryFulfilled }) {
          queryFulfilled.catch(({ error }) => {
            notify.error({
              message: i18next.t("api.getAllFeatureSwitches.error", {
                spaceId
              }),
              error: error as ApiError
            })
          })
        }
      }),
      setOverrideValue: builder.mutation<void, FeatureSwitchSetRequest>({
        query: ({ spaceId, switchName, switchValue, qdOperationReason }) => ({
          url: `/feature-switches/${switchName}/override`,
          params: { spaceId },
          body: { switchValue },
          method: "PATCH",
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: ["FeatureSwitches"],
        onQueryStarted({ switchName }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                i18next.t("api.setOverrideValue.success", { switchName })
              )
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.setOverrideValue.error", {
                  switchName
                }),
                error: error as ApiError
              })
            })
        }
      }),
      deleteOverrideValue: builder.mutation<void, FeatureSwitchDeleteRequest>({
        query: ({ spaceId, switchName, qdOperationReason }) => ({
          url: `/feature-switches/${switchName}/override`,
          params: { spaceId },
          method: "DELETE",
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: ["FeatureSwitches"],
        onQueryStarted({ switchName }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                i18next.t("api.deleteOverrideValue.success", { switchName })
              )
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.deleteOverrideValue.error", {
                  switchName
                }),
                error: error as ApiError
              })
            })
        }
      })
    }),
    overrideExisting: false
  })

export const {
  useGetAllFeatureSwitchesQuery,
  useSetOverrideValueMutation,
  useDeleteOverrideValueMutation
} = featureSwitchApi
