import { ApiError } from "@liveops-portal/lib"
import { Stack, Typography } from "@mui/joy"
import { Grid2 as Grid } from "@mui/material"
import { skipToken } from "@reduxjs/toolkit/query"
import { MessageAlert } from "iconoir-react"
import React from "react"
import { useTranslation } from "react-i18next"
import { useGetPlayerQuery } from "@/api/player"
import { CustomErrorsList } from "@/components/custom-errors-list/custom-errors-list"
import { PlayerCustomizations } from "@/components/player-customizations/player-customizations"
import { PlayerDecks } from "@/components/player-decks/player-decks"
import { PlayerIdentities } from "@/components/player-identities/player-identities"
import { PlayerInfo } from "@/components/player-info/player-info"
import { PlayerPopulations } from "@/components/player-populations/player-populations"
import { PlayerWallet } from "@/components/player-wallet/player-wallet"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { useDataType } from "@/hooks/useDataType"
import { useUniservicesConfigStatus } from "@/hooks/useUniservicesConfigStatus"
import { selectPlayer, selectSpace } from "@/store/slices/context"

export const PlayerFinderPage: React.FC = () => {
  const { t, i18n } = useTranslation()
  const spaceId = useAppSelector(selectSpace)
  const { type, accountId } = useAppSelector(selectPlayer)
  const config = useUniservicesConfigStatus()
  const playerProfile = useGetPlayerQuery(
    spaceId && type && accountId ? { spaceId, accountId, type } : skipToken
  )
  const error = playerProfile.error as ApiError

  const spellcasters = useDataType("spellcasters")
  const catalysts = useDataType("catalysts")
  const spells = useDataType("spells")

  const isFetching =
    config.isFetching ||
    playerProfile.isFetching ||
    spellcasters.isFetching ||
    catalysts.isFetching ||
    spells.isFetching

  return !spaceId ? (
    <Typography>
      {t("message.uninitialized.root", {
        parent: "item.space"
      })}
    </Typography>
  ) : (
    <>
      <Spinner loading={isFetching} />

      {!!Object.keys(config.errors).length && !config.isFetching ? (
        <CustomErrorsList errors={config.errors} />
      ) : (
        <>
          {(!accountId || playerProfile.isError) && (
            <Typography>
              {t("message.uninitialized.root", {
                parent: "item.player"
              })}
            </Typography>
          )}

          {playerProfile.isError && (
            <Stack sx={{ gap: 2 }}>
              <Typography startDecorator={<MessageAlert />} color="warning">
                {error.errCode
                  ? i18n.exists(`error.player.${error.errCode}`, {
                      accountId,
                      spaceId
                    })
                    ? t(`error.player.${error.errCode}`, { accountId, spaceId })
                    : error.message
                  : t("error.player.generic")}
              </Typography>
            </Stack>
          )}

          {accountId && !isFetching && playerProfile.currentData && (
            <Stack sx={{ gap: 2, flexGrow: 1 }}>
              <Stack sx={{ flexGrow: 1, gap: 3, position: "relative" }}>
                <Grid container spacing={2} columns={3}>
                  <Grid
                    size={{ xs: 3, xl: 1 }}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2
                    }}
                  >
                    <PlayerInfo />
                    <PlayerIdentities />
                  </Grid>
                  <Grid
                    size={{ xs: 3, xl: 1 }}
                    sx={{ display: "flex", "> *": { flexGrow: 1 } }}
                  >
                    <PlayerPopulations />
                  </Grid>
                  <Grid
                    size={{ xs: 3, xl: 1 }}
                    sx={{ display: "flex", "> *": { flexGrow: 1 } }}
                  >
                    <PlayerWallet />
                  </Grid>
                </Grid>
                <Grid container spacing={2} columns={2}>
                  <Grid size={{ xs: 2, xl: 1 }}>
                    <PlayerDecks />
                  </Grid>
                  <Grid
                    size={{ xs: 2, xl: 1 }}
                    sx={{ display: "flex", "> *": { flexGrow: 1 } }}
                  >
                    <PlayerCustomizations />
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          )}
        </>
      )}
    </>
  )
}
