import { ToolboxRequest } from "@liveops-portal/lib"
import {
  Chip,
  ListItem,
  ListItemButton,
  ListItemDecorator,
  Stack,
  Typography
} from "@mui/joy"
import React from "react"

interface Props {
  toolboxRequest: Partial<ToolboxRequest>
  onClick: () => void
}

export const ToolboxHistoryItem: React.FC<Props> = ({
  toolboxRequest,
  onClick
}) => {
  const { method, serviceName, endpoint } = toolboxRequest

  return (
    <>
      <ListItem>
        <ListItemButton onClick={onClick} data-testid="historyItem">
          <ListItemDecorator sx={{ width: "60px", justifyContent: "center" }}>
            <Chip>{method}</Chip>
          </ListItemDecorator>
          <Stack>
            <Typography sx={{ fontWeight: "bold" }}>{serviceName}</Typography>
            <Typography>{endpoint}</Typography>
          </Stack>
        </ListItemButton>
      </ListItem>
    </>
  )
}
