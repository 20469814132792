import { FunctionInterpolation } from "@emotion/react"
import { Theme } from "@mui/joy"
import { css } from "@mui/system"

const toastifyStyles: FunctionInterpolation<Theme> = (theme) => css`
  :root {
    --toastify-color-light: inherit;
    --toastify-color-dark: inherit;
    --toastify-color-info: inherit;
    --toastify-color-success: inherit;
    --toastify-color-warning: inherit;
    --toastify-color-error: inherit;

    --toastify-toast-width: 320px;
    --toastify-toast-background: inherit;
    --toastify-toast-padding: 0;
    --toastify-toast-min-height: 0;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: ${theme.fontFamily.body};
    --toastify-z-index: ${theme.zIndex.snackbar};

    --toastify-text-color-light: ${theme.palette.text.primary};
    --toastify-text-color-dark: ${theme.palette.text.primary};
  }

  .Toastify__toast-container {
    margin-top: var(--Header-height);
  }
`

export default toastifyStyles
