import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  PublicClientApplication,
  RedirectRequest
} from "@azure/msal-browser"

/* Configuration constant containing the necessary infos to retrieve authentication information from Azure */
export const MSAL_INSTANCE_CONFIG: Configuration = {
  auth: {
    clientId: import.meta.env.__AZURE_CLIENT_ID_CLIENT,
    authority: `https://login.microsoftonline.com/${
      import.meta.env.__AZURE_TENANT_ID
    }`,
    redirectUri: import.meta.env.__CLIENT_URL
  },
  cache: {
    cacheLocation: "sessionStorage"
  }
}

export const MSAL_INSTANCE = new PublicClientApplication(MSAL_INSTANCE_CONFIG)

export const MSAL_LOGIN_REQUEST: RedirectRequest = {
  redirectUri: "/",
  scopes: ["User.Read"]
}

export const TEST_ACCESS_TOKEN =
  "eyJhbGciOiJIUzI1NiJ9.eyJzY3AiOiJVc2VyLlJlYWQiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9jb250b3NvIiwiYXBwX2Rpc3BsYXluYW1lIjoiYXBwbGljYXRpb24tbmFtZSIsInRpZCI6InRlbmFudC1pZCIsInRlbmFudF9yZWdpb25fc2NvcGUiOiJYWCIsImF1ZCI6IjAwMDAwMDAzLTAwMDAtMDAwMC1jMDAwLTAwMDAwMDAwMDAwMCIsInVuaXF1ZV9uYW1lIjoiam9obi5kb2VAY29udG9zby5jb20iLCJuYmYiOiIxNjU2NTk5NjI2IiwiYXBwaWQiOiJhcHAtaWQiLCJuYW1lIjoiSm9obiBEb2UiLCJleHAiOiIxNjU2NjA0NzY3IiwiaWF0IjoiMTY1NjU5OTYyNiIsImVtYWlsIjoiam9obi5kb2VAY29udG9zby5jb20ifQ.ftOvEUhqEFKWxIcxcYsgstgldO-31hIVwEhQ0hwwqWg"

export const defaultTestAccountInfo: AccountInfo = {
  homeAccountId: "home-account-id",
  localAccountId: "local-account-id",
  environment: "login.windows.net",
  tenantId: "tenant-id",
  username: "john.doe@contoso.com",
  name: "John Doe"
}
export const defaultTestAuthenticationResult: AuthenticationResult = {
  authority: "https://login.microsoftonline.com",
  uniqueId: "unique-id",
  tenantId: "tenant-id",
  scopes: ["openid", "profile"],
  idToken: "test-id-token",
  idTokenClaims: {},
  accessToken: TEST_ACCESS_TOKEN,
  fromCache: false,
  correlationId: "test-correlation-id",
  expiresOn: new Date(Date.now() + 3600000),
  account: defaultTestAccountInfo,
  tokenType: "Bearer"
}
export const GRAPH_API_URL = "https://graph.microsoft.com"
