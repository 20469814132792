import {
  Autocomplete,
  AutocompleteOption,
  createFilterOptions,
  ListItemContent,
  Typography
} from "@mui/joy"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { EntityChip } from "@/components/entity-chip/entity-chip"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useRoutePath } from "@/hooks/useRoutePath"
import { useGetAllGamesQuery } from "@/store/api/game"
import {
  selectEnv,
  selectGame,
  setGameId,
  setSeasonId,
  setSpaceId
} from "@/store/slices/context"

export const GameSelector: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const currentPath = useRoutePath()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const { gameId: gameIdParam } = useParams()

  const { data: games, isFetching: isFetchingGames } = useGetAllGamesQuery(
    { env },
    { skip: !env }
  )

  useEffect(() => {
    if (
      currentPath?.includes("gameId") &&
      gameIdParam &&
      gameIdParam !== gameId
    ) {
      dispatch(setGameId(gameIdParam))
    }
  }, [dispatch, currentPath, gameId, gameIdParam])

  return (
    currentPath?.includes("gameId") &&
    games && (
      <Autocomplete
        autoComplete
        disableClearable
        aria-label={t("label.gameSelector")}
        id="gameSelector"
        disabled={isFetchingGames}
        value={games.find((g) => g.gameId === gameId)}
        options={games}
        filterOptions={createFilterOptions({
          stringify: (option) => `${option.name} ${option.gameId}`
        })}
        onChange={(_, newGame) => {
          dispatch(setGameId(newGame.gameId))
          dispatch(setSpaceId(null))
          dispatch(setSeasonId(null))
          currentPath &&
            navigate(
              generatePath(currentPath, {
                env,
                gameId: newGame.gameId,
                spaceId: null,
                seasonId: null
              })
            )
        }}
        sx={{
          width: 260
        }}
        startDecorator={<EntityChip type="game" />}
        isOptionEqualToValue={(option, value) => option.gameId === value.gameId}
        getOptionLabel={(option) => option.name}
        slotProps={{
          clearIndicator: { sx: { display: "none" } }
        }}
        renderOption={(props, option) => (
          <AutocompleteOption {...props} key={option.gameId}>
            <ListItemContent>
              <Typography level="body-sm" textColor="text.primary">
                {option.name}
              </Typography>
              <Typography level="body-xs" fontWeight="400" noWrap>
                {option.gameId}
              </Typography>
            </ListItemContent>
          </AutocompleteOption>
        )}
      />
    )
  )
}
