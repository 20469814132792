import useUrlState from "@ahooksjs/use-url-state"
import {
  formatJsonToString,
  sortStringsWithKeyword,
  isValidJson,
  ConfigCategory,
  Operation
} from "@liveops-portal/lib"
import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Select,
  Stack,
  Typography,
  Option,
  Checkbox
} from "@mui/joy"
import { Plus, TrashSolid, Xmark } from "iconoir-react"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  useDeleteConfigCategoryMutation,
  useFindAllConfigsQuery,
  useUpdateConfigCategoryMutation
} from "@/api/config"
import { CodeEditor } from "@/components/code-editor/code-editor"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { Spinner } from "@/components/spinner/spinner"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import {
  setActiveOperation,
  resetOperation,
  selectActiveOperation
} from "@/store/slices/audit"
import { selectSpace } from "@/store/slices/context"

/**
 * Component representing the config page.
 * If a space is selected, displays the configuration viewer.
 * If no space is selected, prompts the user to choose a space.
 */
export const ConfigPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const spaceId = useAppSelector(selectSpace)
  const activeOperation = useAppSelector(selectActiveOperation)
  const [categories, setCategories] = useState<string[]>([])
  const [isAdding, setIsAdding] = useState<boolean | undefined>()
  const previousCategory = useRef<string>("general")
  const [initialConfig, setInitialConfig] = useState<ConfigCategory>()
  const [publicConfig, setPublicConfig] = useState<string>("{}")
  const [privateConfig, setPrivateConfig] = useState<string>("{}")
  const [ignoreDefaultConfig, setIgnoreDefaultConfig] = useState<boolean>(false)

  const {
    data: activeConfig,
    isSuccess,
    isUninitialized,
    isFetching
  } = useFindAllConfigsQuery(
    { spaceId, ignoreDefaultConfig },
    { skip: !spaceId }
  )

  const [urlState, setUrl] = useUrlState<{ activeCategory: string }>()
  const [activeCategory, setActiveCategory] = useState<string | null>(
    urlState["activeCategory"] || "general"
  )

  // UPDATE
  const [updateConfigCategory, updateMutationMeta] =
    useUpdateConfigCategoryMutation()
  const updateOperation: Operation = useMemo(() => ["config", "update"], [])
  const isUpdateOperationActive = isOperationActive(
    activeOperation,
    updateOperation
  )
  const isLoadingUpdate =
    isUpdateOperationActive || updateMutationMeta.isLoading

  // DELETE
  const [deleteConfigCategory, deleteMutationMeta] =
    useDeleteConfigCategoryMutation()
  const deleteOperation: Operation = ["config", "delete"]
  const isDeleteOperationActive = isOperationActive(
    activeOperation,
    deleteOperation
  )
  const isLoadingDelete =
    isDeleteOperationActive || deleteMutationMeta.isLoading

  const isConfigEdited = useMemo(
    () =>
      initialConfig &&
      activeConfig &&
      (formatJsonToString(initialConfig.public) !== publicConfig ||
        formatJsonToString(initialConfig.private) !== privateConfig),
    [activeConfig, initialConfig, privateConfig, publicConfig]
  )

  useEffect(() => {
    if (deleteMutationMeta.isSuccess) {
      setActiveCategory("general")
      previousCategory.current = "general"
    }
  }, [dispatch, deleteMutationMeta.isSuccess])

  useEffect(() => {
    if (isConfigEdited && isSuccess) {
      dispatch(setActiveOperation(updateOperation))
    } else {
      setIsAdding(false)
      dispatch(resetOperation())
    }
  }, [dispatch, isConfigEdited, isSuccess, updateOperation])

  useEffect(() => {
    if (!activeConfig) return

    if (!isAdding && !activeConfig[activeCategory || ""]) {
      setActiveCategory("general")
    }

    const _initialConfig =
      (activeCategory && activeConfig[activeCategory]) || ({} as ConfigCategory)

    setCategories(sortStringsWithKeyword(Object.keys(activeConfig), "general"))
    setInitialConfig(_initialConfig)
    setPublicConfig(formatJsonToString(_initialConfig.public))
    setPrivateConfig(formatJsonToString(_initialConfig.private))
  }, [activeCategory, activeConfig, isAdding])

  const onSubmitHandler = () => {
    if (activeCategory) {
      if (isDeleteOperationActive) {
        deleteConfigCategory({
          spaceId,
          configName: activeCategory
        })
        setUrl({ activeCategory: "general" })
      }

      if (isUpdateOperationActive) {
        const updatedConfig = {
          private: JSON.parse(privateConfig),
          public: JSON.parse(publicConfig)
        }
        updateConfigCategory({
          spaceId,
          configName: activeCategory,
          jsonData: updatedConfig
        })
        setUrl({ activeCategory })
      }
    }
  }

  const isReadOnly =
    !isAdding && (activeCategory === "general" || !ignoreDefaultConfig)

  return isUninitialized ? (
    <Typography>
      {t("message.uninitialized.parent", {
        parent: "item.space",
        item: "item.configuration"
      })}
    </Typography>
  ) : (
    <>
      <Spinner loading={isFetching} />
      {activeConfig && Object.keys(activeConfig).length ? (
        <Stack
          component="form"
          sx={{ gap: 2, flexGrow: 1 }}
          /* c8 ignore start */
          onSubmit={(e) => {
            e.preventDefault()
          }}
          /* c8 ignore end */
        >
          <OperationReason
            autofocus={isDeleteOperationActive}
            closeable={isDeleteOperationActive}
            open={!!isConfigEdited || isLoadingUpdate || isLoadingDelete}
            disabled={
              !!publicConfig &&
              !!privateConfig &&
              (!isValidJson(publicConfig) || !isValidJson(privateConfig))
            }
            onSubmit={onSubmitHandler}
          />

          <FormControl>
            <FormLabel>{t("label.configuration.configName")}</FormLabel>
            <Stack sx={{ flexDirection: "row", gap: 1, width: 300 }}>
              {isAdding ? (
                <Input
                  autoFocus
                  fullWidth
                  type="text"
                  name="configName"
                  placeholder={t("modifier.new", { item: "item.category" })}
                  onChange={(event) => {
                    const { value } = event.target
                    const formattedValue = value.replace(/\s/g, "-")
                    event.target.value = formattedValue
                    setActiveCategory(formattedValue)
                  }}
                />
              ) : (
                <Select
                  value={activeCategory}
                  name="configName"
                  disabled={isDeleteOperationActive}
                  onChange={(_, value) => {
                    if (value !== null) {
                      setActiveCategory(value)
                      setUrl({ activeCategory: value })
                      previousCategory.current = value
                    }
                  }}
                  sx={{ flexGrow: 1 }}
                >
                  {categories?.map((option) => (
                    <Option key={option} label={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              )}
              {activeCategory !== "general" && !isAdding && (
                <IconButton
                  size="sm"
                  color="danger"
                  variant="solid"
                  aria-label={t("action.item", {
                    action: "action.delete",
                    item: "item.category"
                  })}
                  loading={isDeleteOperationActive}
                  onClick={() => {
                    dispatch(setActiveOperation(deleteOperation))
                  }}
                >
                  <TrashSolid />
                </IconButton>
              )}
              <IconButton
                size="sm"
                variant="outlined"
                title={
                  isAdding
                    ? t("action.cancel")
                    : t("action.item", {
                        action: "action.add",
                        item: "item.category"
                      })
                }
                disabled={isDeleteOperationActive}
                onClick={() => {
                  if (isAdding) {
                    setActiveCategory(previousCategory.current)
                  } else {
                    setActiveCategory(null)
                  }

                  setIsAdding(!isAdding)
                }}
              >
                {isAdding ? <Xmark /> : <Plus />}
              </IconButton>
            </Stack>
            {activeCategory === "general" && (
              <FormHelperText>
                {t("message.configuration.generalReadOnly")}
              </FormHelperText>
            )}
            <FormHelperText>
              {!ignoreDefaultConfig
                ? t("message.configuration.currentlyShowingAllConfigValues")
                : t("message.configuration.currentlyShowingOverriddenValues")}
            </FormHelperText>
          </FormControl>

          <Checkbox
            sx={{ width: "fit-content" }}
            label={t("label.configuration.overrideValues")}
            onClick={() => setIgnoreDefaultConfig(!ignoreDefaultConfig)}
          />

          <Stack
            sx={{
              flexGrow: 1,
              gap: 1,
              flexDirection: "row",
              position: "relative"
            }}
          >
            <CodeEditor
              readOnly={isReadOnly}
              name="_public"
              value={publicConfig}
              label={t("label.configuration.publicConfig")}
              onChange={setPublicConfig}
              error={!isValidJson(publicConfig)}
              sx={{ flexBasis: "50%" }}
            />
            <CodeEditor
              readOnly={isReadOnly}
              name="_private"
              value={privateConfig}
              label={t("label.configuration.privateConfig")}
              onChange={setPrivateConfig}
              error={!isValidJson(privateConfig)}
              sx={{ flexBasis: "50%" }}
            />
          </Stack>
        </Stack>
      ) : (
        <Typography>
          {t("message.noItems.parent", {
            item: "item.configuration",
            parent: "item.space"
          })}
        </Typography>
      )}
    </>
  )
}
