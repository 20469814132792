import { IconButton, Typography } from "@mui/joy"
import { PasteClipboard } from "iconoir-react"
import React, {
  Children,
  ReactElement,
  isValidElement,
  PropsWithChildren
} from "react"
import { useTranslation } from "react-i18next"
import notify from "@/helpers/notify"

interface Props extends PropsWithChildren {}

// Component can be used to wrap strings or other components.

export const CopyToClipboard: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation()

  const arrayChildren = Children.toArray(children)

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    notify.success({ message: t("state.copied"), exclude: true })
  }

  return Children.map(arrayChildren, (child) => {
    const isChildString = typeof child === "string"
    const isChildNumber = typeof child === "number"
    const isChildValidReactElement =
      isValidElement(child) && typeof child.props.children === "string"
    const text = isChildString
      ? child
      : isChildNumber
        ? child.toString()
        : isChildValidReactElement
          ? (child as ReactElement).props.children
          : ""

    const shouldHaveButton =
      isChildString || isChildNumber || isChildValidReactElement

    const renderButton = () =>
      shouldHaveButton ? (
        <IconButton
          variant="soft"
          color="primary"
          title={t("hint.copy")}
          component="button"
          sx={{
            "--IconButton-size": "20px"
          }}
          onClick={() => {
            copyToClipboard(text)
          }}
        >
          <PasteClipboard />
        </IconButton>
      ) : null

    return <Typography endDecorator={renderButton()}>{child}</Typography>
  })
}
