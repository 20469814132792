export const zhCN = {
  translation: {
    action: {
      language: "切换到英语"
    },
    common: {
      languageCode: "EN"
    },
    state: {
      overridden: "被覆盖"
    }
  }
}
