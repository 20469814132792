import {
  FormHelperText,
  Input,
  InputProps,
  FormControl,
  FormControlProps
} from "@mui/joy"
import { useMemo } from "react"
import {
  Controller,
  ControllerProps,
  FieldValues,
  Path,
  PathValue,
  useFormContext
} from "react-hook-form"
import { FormLabel } from "@/components/form-label/form-label"
import { getFormErrorMessage } from "@/helpers/errors"
import { findNestedValue } from "@/helpers/match"

interface Props<T extends FieldValues>
  extends InputProps,
    Pick<FormControlProps, "orientation"> {
  name: Path<T>
  label?: string
  rules?: ControllerProps<T>["rules"]
  hint?: string
  helper?: string
}

/**
 * Renders a Joy UI Input bound to React Hook Form.
 * @param name
 * @param label
 * @param rules
 * @param defaultValue
 * @param sx
 * @param hint
 * @param helper
 */
export const FormInput = <T extends FieldValues>({
  name,
  label,
  rules,
  hint,
  helper,
  defaultValue,
  orientation,
  sx,
  ...props
}: Props<T>) => {
  const {
    control,
    formState: { defaultValues }
  } = useFormContext<T>()

  const _defaultValue = useMemo(
    () => (defaultValues ? findNestedValue(defaultValues, name) : defaultValue),
    [defaultValues, name, defaultValue]
  )

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={_defaultValue as PathValue<T, Path<T>>}
      render={({ field, fieldState: { error, invalid } }) => {
        return (
          <FormControl error={invalid} sx={sx} orientation={orientation}>
            {label && (
              <FormLabel required={!!rules?.required} hint={hint}>
                {label}
              </FormLabel>
            )}

            <Input
              {...field}
              {...props}
              size="sm"
              error={invalid}
              value={field.value || ""}
              aria-label={label || props["aria-label"]}
              onChange={(event) => {
                props.onChange?.(event)
                if (props.type === "number") {
                  field.onChange(+event.target.value)
                } else {
                  field.onChange(event)
                }
              }}
            />
            {helper && (
              <FormHelperText sx={{ fontSize: "xs" }}>{helper}</FormHelperText>
            )}
            {!!error && (
              <FormHelperText role="alert" sx={{ fontSize: "xs" }}>
                {getFormErrorMessage(error, label)}
              </FormHelperText>
            )}
          </FormControl>
        )
      }}
    />
  )
}
