import {
  Action,
  Actions,
  BattlepassDurationTypes,
  BattlepassTrack,
  BattlepassXpRequiredTypes,
  formatTimeSpanToWeeks
} from "@liveops-portal/lib"
import { Divider, IconButton, Sheet, Stack, Table, Typography } from "@mui/joy"
import { Minus, Plus } from "iconoir-react"
import { useCallback, useEffect, useRef } from "react"
import {
  ArrayPath,
  FieldArray,
  FieldValues,
  Path,
  PathValue,
  SubmitHandler,
  useFieldArray,
  UseFormReturn
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useGetSeasonsQuery } from "@/api/season"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { FormRadioGroup } from "@/components/form-radio-group/form-radio-group"
import { FormRepeater } from "@/components/form-repeater/form-repeater"
import { Modal } from "@/components/modal/modal"
import { findNestedValue } from "@/helpers/match"
import { useAppSelector } from "@/hooks/store"
import { selectEnv, selectGame, selectSeason } from "@/store/slices/context"

interface Props<T extends FieldValues> {
  open: boolean
  loading: boolean
  action: Action
  methods: UseFormReturn<T>
  onSubmit: SubmitHandler<T>
  onClose: () => void
}

export const BattlepassModal = <T extends FieldValues>({
  open,
  loading,
  action,
  methods,
  onClose,
  onSubmit
}: Props<T>) => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const seasonId = useAppSelector(selectSeason)
  const { season } = useGetSeasonsQuery(
    { gameId, env },
    {
      skip: !gameId || !env,
      selectFromResult: ({ data }) => ({
        season: data?.find((season) => season.seasonId === seasonId)
      })
    }
  )
  const { duration } =
    season?.latestPublishedSeasonRevision?.seasonRevisionDetails || {}
  const seasonLength = formatTimeSpanToWeeks(duration) * 7

  const {
    control,
    watch,
    setValue,
    formState: { defaultValues }
  } = methods

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tracks" as ArrayPath<T>
  })

  const isDurationSpecific =
    watch("duration.type" as Path<T>) === BattlepassDurationTypes.SPECIFIC
  const isXpRequiredLinear =
    watch(`xpRequired.type` as Path<T>) === BattlepassXpRequiredTypes.LINEAR
  const tracks: BattlepassTrack[] = watch("tracks" as Path<T>)
  const levelsAmount = watch("levelsAmount" as Path<T>)
  const prevLevelsAmount = useRef<number>(Number(levelsAmount))

  const generateLevels = useCallback((array: number[], firstIdx?: number) => {
    return array.map((index) => {
      if (firstIdx) firstIdx++
      return {
        index: firstIdx || index,
        rewards: [{ rewardId: "" }]
      } as PathValue<T, Path<T>>
    })
  }, [])

  useEffect(() => {
    if (!tracks) return
    const debounce = setTimeout(() => {
      const next = Number(levelsAmount)
      const prev = prevLevelsAmount.current
      const diff = next - prev
      prevLevelsAmount.current = next

      tracks.forEach((track, i) => {
        const path = `tracks.${i}.levels` as unknown as Path<T>

        if (track.levels?.length) {
          const { levels } = track
          const lastIdx = levels[levels.length - 1]

          if (diff < 0) {
            setValue(path, levels.slice(0, diff) as PathValue<T, Path<T>>)
          }

          if (diff > 0) {
            setValue(
              path,
              levels.concat(
                generateLevels([...Array(diff).keys()], levels.indexOf(lastIdx))
              ) as PathValue<T, Path<T>>
            )
          }
        } else {
          setValue(
            path,
            generateLevels([...Array(next).keys()]) as PathValue<T, Path<T>>
          )
        }
      })
    }, 200)
    return () => clearTimeout(debounce)
  }, [generateLevels, levelsAmount, setValue, tracks])

  useEffect(() => {
    const delay = "label.time.delay" as Path<T>
    const defaultDelay = defaultValues
      ? findNestedValue(defaultValues, delay)
      : 0
    const length = "duration.length" as Path<T>
    const defaultLength = defaultValues
      ? findNestedValue(defaultValues, length)
      : null

    setValue(delay, defaultDelay as PathValue<T, Path<T>>)
    setValue(
      length,
      (isDurationSpecific ? defaultLength : seasonLength) as PathValue<
        T,
        Path<T>
      >
    )
  }, [seasonLength, isDurationSpecific, setValue, defaultValues])

  const item = "item.battlepass"

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("action.item", { action: `action.${action}`, item })}
    >
      <Form
        auditable={action === Actions.update}
        methods={methods}
        loading={loading}
        submitLabel={t(`action.${action}`)}
        onSubmit={onSubmit}
        gap={2}
      >
        <Stack
          gap={2}
          direction="row"
          alignItems="flex-start"
          divider={<Divider orientation="vertical" />}
        >
          <Stack gap={2} flexGrow={1}>
            <FormInput
              type="text"
              name="displayName"
              label={t("modifier.name", {
                item: "item.battlepass"
              })}
              rules={{ required: true }}
              sx={{ flexGrow: 1 }}
            />
            <Stack direction="row" gap={1}>
              <FormRadioGroup
                label={t("label.time.duration")}
                helper={t("hint.seasonDuration", { seasonLength })}
                name="duration.type"
                options={Object.values(BattlepassDurationTypes).map(
                  (value) => ({
                    value,
                    label: t(`label.season.durationTypes.${value}`)
                  })
                )}
                rules={{ required: true }}
              />

              <FormInput
                type="number"
                name="duration.delay"
                label={t("label.time.delay")}
                hint={t("hint.maxBattlepassDelay")}
                disabled={!isDurationSpecific}
                rules={{
                  min: 0,
                  max: {
                    value: seasonLength,
                    message: t("error.season.maxBattlepassDelay")
                  }
                }}
                slotProps={{
                  input: { min: 0, max: seasonLength }
                }}
                endDecorator={<>{t("time.unit.day_other")}</>}
                sx={{ width: 95, flexGrow: 1 }}
              />
              <FormInput
                type="number"
                name="duration.length"
                label={t("label.time.length")}
                disabled={!isDurationSpecific}
                rules={{
                  required: isDurationSpecific,
                  min: 1,
                  max: {
                    value: seasonLength,
                    message: t("error.season.maxBattlepassLength")
                  }
                }}
                slotProps={{
                  input: {
                    min: 1,
                    max: seasonLength
                  }
                }}
                endDecorator={<>{t("time.unit.day_other")}</>}
                sx={{ width: 90, flexGrow: 1 }}
              />
            </Stack>
            <Stack direction="row" gap={1}>
              <FormRadioGroup
                label={t("label.season.xpRequired")}
                name="xpRequired.type"
                options={Object.values(BattlepassXpRequiredTypes).map(
                  (value) => ({
                    value,
                    label: t(`label.season.xpRequiredTypes.${value}`),
                    disabled: value === BattlepassXpRequiredTypes.CUSTOM
                  })
                )}
                rules={{ required: true }}
              />

              <FormInput
                type="number"
                name="xpRequired.linearValue"
                disabled={!isXpRequiredLinear}
                label={t("label.season.xpPerLevel")}
                rules={{
                  required: true,
                  min: 0
                }}
                sx={{ width: 105, flexGrow: 1 }}
              />
              <FormInput
                type="number"
                name="levelsAmount"
                label={t("item.levels")}
                rules={{
                  required: true,
                  min: 1
                }}
                sx={{ width: 105, flexGrow: 1 }}
              />
            </Stack>
          </Stack>
          <Stack minWidth={275} maxWidth="50%" flexGrow={1}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography level="title-sm">{t("item.tracks")}</Typography>
              <IconButton
                size="sm"
                variant="outlined"
                title={t("action.item", {
                  action: "action.add",
                  item: "item.track"
                })}
                aria-label={t("action.item", {
                  action: "action.add",
                  item: "item.track"
                })}
                onClick={() => {
                  append({
                    displayName: "",
                    levels: generateLevels([
                      ...Array(Number(levelsAmount)).keys()
                    ])
                  } as FieldArray<T, ArrayPath<T>>)
                }}
                sx={{ ml: 1 }}
              >
                <Plus />
              </IconButton>
            </Stack>
            <Sheet sx={{ maxHeight: 215, overflow: "auto" }}>
              <Table
                noWrap
                stickyHeader
                sx={{
                  "--TableCell-height": "auto",
                  "& thead th:first-of-type": {
                    width: 32,
                    textAlign: "right"
                  }
                }}
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      <Typography display="flex" gap={0.5} id="trackName">
                        {t("modifier.name", {
                          item: "item.track"
                        })}
                        <Typography textColor="danger.500" fontWeight="500">
                          *
                        </Typography>
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((field, trackId) => (
                    <tr
                      key={`modalTrackRow[${field.id}]`}
                      data-testid="modalTrackRow"
                    >
                      <th scope="row">
                        <Typography level="title-sm" textAlign="right">
                          {trackId + 1}
                        </Typography>
                      </th>
                      <td>
                        <Stack direction="row" gap={1}>
                          <FormInput
                            type="text"
                            name={`tracks.${trackId}.displayName`}
                            aria-labelledby="trackName"
                            rules={{ required: true }}
                            sx={{ flexGrow: 1, mr: trackId === 0 ? 5 : 0 }}
                          />
                          {trackId > 0 && (
                            <IconButton
                              size="sm"
                              variant="outlined"
                              title={t("action.item", {
                                action: "action.remove",
                                item: "item.track"
                              })}
                              aria-label={t("action.item", {
                                action: "action.remove",
                                item: "item.track"
                              })}
                              onClick={() => {
                                remove(trackId)
                              }}
                              sx={{ alignSelf: "flex-end" }}
                            >
                              <Minus />
                            </IconButton>
                          )}
                        </Stack>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Sheet>
          </Stack>
        </Stack>

        <Stack overflow="hidden" gap={1}>
          <Typography level="title-sm" display="flex" gap={0.5}>
            {t("item.levelRewards")}
            <Typography textColor="danger.500" fontWeight="500">
              *
            </Typography>
          </Typography>
          <Sheet
            sx={{ maxHeight: "50vh", overflow: "auto", borderRadius: "sm" }}
            variant="outlined"
          >
            <Table
              noWrap
              stickyHeader
              stripe="odd"
              borderAxis="bothBetween"
              sx={({ vars }) => ({
                tableLayout: "auto",
                borderRadius: "sm",
                "--TableRow-stripeBackground": vars.palette.background.level1,
                "--TableCell-height": "auto",
                "& thead tr:first-of-type th": {
                  verticalAlign: "bottom"
                },
                "& thead th": {
                  verticalAlign: "middle"
                }
              })}
            >
              <thead>
                <tr>
                  <th style={{ width: 32, textAlign: "right" }}>#</th>
                  {fields.map((field, trackIdx) => {
                    const trackName = tracks[trackIdx].displayName
                    return (
                      <th
                        key={`modalLevelHeader[${trackIdx}]`}
                        style={{
                          width: tracks.length === 1 ? "100%" : 260
                        }}
                      >
                        {`${t("item.track")} ${trackIdx + 1} ${trackName && `(${trackName})`}`}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {tracks?.[0]?.levels?.map(({ index: levelIdx }) => (
                  <tr
                    key={`modalLevelRow[${levelIdx}]`}
                    data-testid="modalLevelRow"
                  >
                    <td style={{ textAlign: "right" }}>{levelIdx + 1}</td>
                    {fields.map((field, trackIdx) => (
                      <td
                        key={`modalLevelRow[${levelIdx}].track[${trackIdx}].rewards`}
                        style={{ verticalAlign: "top" }}
                      >
                        <Typography
                          sx={{ display: "none" }}
                          id={`tracks.${trackIdx}.levels.${levelIdx}.rewards.label`}
                        >
                          {t("label.season.levelRewards", {
                            trackIdx: trackIdx + 1,
                            levelIdx: levelIdx + 1
                          })}
                        </Typography>
                        <FormRepeater
                          name={
                            `tracks.${trackIdx}.levels.${levelIdx}.rewards` as "tracks[0].levels[0].rewards"
                          }
                          fields={{
                            rewardId: {
                              type: "text",
                              rules: { required: true },
                              sx: { flexGrow: 1 }
                            }
                          }}
                          aria-labelledby={`tracks.${trackIdx}.levels.${levelIdx}.rewards.label`}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        </Stack>
      </Form>
    </Modal>
  )
}
