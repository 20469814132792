import {
  Button,
  ColorPaletteProp,
  List,
  ListItem,
  ListItemDecorator,
  Stack,
  Typography
} from "@mui/joy"
import {
  Check,
  CheckCircle,
  WarningTriangle,
  Wrench,
  Xmark
} from "iconoir-react"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLazyFixSpaceQuery, useLazyValidateSpaceQuery } from "@/api/space"
import { useAppSelector } from "@/hooks/store"
import { selectSpace } from "@/store/slices/context"

export const SpaceValidator: React.FC = () => {
  const spaceId = useAppSelector(selectSpace)
  const { t } = useTranslation()

  const [
    validateSpace,
    {
      data: spaceValidation,
      isFetching: isFetchingValidation,
      reset: resetValidation
    }
  ] = useLazyValidateSpaceQuery()

  const [
    fixSpace,
    {
      data: fixedSpaceValidation,
      isFetching: isFetchingFixedValidation,
      reset: resetFixedValidation
    }
  ] = useLazyFixSpaceQuery()

  useEffect(() => {
    resetValidation()
    resetFixedValidation()
  }, [resetFixedValidation, resetValidation, spaceId])

  const mergedSpaceValidation = fixedSpaceValidation || spaceValidation

  const renderListItem = (status: string, name: string, message: string) => {
    const listItemOptions = {
      color: "success",
      statusIcon: <Check />
    }

    switch (status) {
      case "Warning":
        listItemOptions["color"] = "warning"
        listItemOptions["statusIcon"] = <WarningTriangle />
        break
      case "Fail":
        listItemOptions["color"] = "danger"
        listItemOptions["statusIcon"] = <Xmark />
        break
      default:
        break
    }

    return (
      <ListItem
        key={name}
        color={listItemOptions.color as ColorPaletteProp}
        sx={{ justifyContent: "start" }}
      >
        <Stack sx={{ gap: 1 }}>
          <Stack sx={{ flexDirection: "row" }}>
            <ListItemDecorator data-testid="status-icon">
              {listItemOptions.statusIcon}
            </ListItemDecorator>
            <Typography level="body-md">{name}</Typography>
          </Stack>
          {message &&
            message.split(";").map((splitMessage, index) => (
              <Typography key={index} level="body-sm">
                {splitMessage}
              </Typography>
            ))}
        </Stack>
      </ListItem>
    )
  }

  return (
    spaceId && (
      <Stack sx={{ gap: 2 }}>
        <Typography level="h3" alignItems="center">
          {t("app.spaceValidator")}
        </Typography>
        {mergedSpaceValidation && (
          <List sx={{ "--ListItem-paddingX": 0 }}>
            {mergedSpaceValidation?.details.map(({ status, name, message }) =>
              renderListItem(status, name, message)
            )}
          </List>
        )}
        {mergedSpaceValidation?.autofixed && (
          <Typography color="success" level="body-sm">
            {t("label.spaceValidator.autofixed")}:{" "}
            {mergedSpaceValidation.autofixed}
          </Typography>
        )}
        <Stack direction="row" gap={2}>
          <Button
            loading={isFetchingValidation}
            disabled={isFetchingFixedValidation}
            variant="outlined"
            onClick={() => validateSpace(spaceId)}
            startDecorator={<CheckCircle />}
          >
            {t("action.item", {
              action: "action.validate",
              item: "item.space"
            })}
          </Button>
          {mergedSpaceValidation?.autofixable && (
            <Button
              loading={isFetchingFixedValidation}
              disabled={isFetchingValidation}
              variant="outlined"
              startDecorator={<Wrench />}
              onClick={() => fixSpace(spaceId)}
            >
              {t("action.item", {
                action: "action.fix",
                item: "item.space"
              })}
            </Button>
          )}
        </Stack>
      </Stack>
    )
  )
}
