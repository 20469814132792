import { Link, Stack, Typography, useColorScheme } from "@mui/joy"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link as RouterLink } from "react-router-dom"
import LogoDark from "@/assets/logo-small-dark.svg?react"
import LogoLight from "@/assets/logo-small-light.svg?react"
import { useAppSelector } from "@/hooks/store"
import { selectRevision } from "@/store/slices/app"
import { selectStage } from "@/store/slices/context"

interface Props {
  to?: string
  size?: "sm" | "md" | "lg"
  collapsed?: boolean
}

type LogoType = React.ComponentProps<"svg"> & { title?: string } & Props

export const Logo: React.FC<LogoType> = ({
  to,
  size = "md",
  collapsed = false,
  ...props
}: LogoType) => {
  const { t } = useTranslation()
  const { mode } = useColorScheme()
  const stage = useAppSelector(selectStage)
  const revision = useAppSelector(selectRevision)

  /* c8 ignore start */
  let _size
  switch (size) {
    case "sm":
      _size = 26
      break
    case "md":
      _size = 32
      break
    case "lg":
      _size = 48
      break
  }
  /* c8 ignore end */

  const renderLogo = () => (
    <Stack
      direction="row"
      sx={{
        transition: "0.2s all",
        alignItems: "center",
        width: collapsed ? _size : "auto"
      }}
    >
      <Stack sx={{ py: "2px" }}>
        {mode === "dark" ? (
          <LogoDark width={_size} height={_size} {...props} />
        ) : (
          <LogoLight width={_size} height={_size} {...props} />
        )}
      </Stack>
      <Stack
        sx={{
          ml: 1,
          opacity: collapsed ? 0 : 1,
          pointerEvents: collapsed ? "none" : "all",
          transition: "0.2s all"
        }}
      >
        <Typography
          level="title-lg"
          sx={{
            ":hover": { color: "text.secondary" }
          }}
          noWrap
        >
          {t("app.name")}
        </Typography>
        <Typography
          level="body-xs"
          fontSize={11}
          fontWeight={400}
          lineHeight={1}
          noWrap
        >
          {stage} - {revision}
        </Typography>
      </Stack>
    </Stack>
  )

  return to ? (
    <Link to={to} component={RouterLink} underline="none">
      {renderLogo()}
    </Link>
  ) : (
    renderLogo()
  )
}
