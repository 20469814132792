import { SpaceCreateRequest } from "@liveops-portal/lib"
import { Button } from "@mui/joy"
import { Plus } from "iconoir-react"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { FormSelect } from "@/components/form-select/form-select"
import { Modal } from "@/components/modal/modal"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useIsAdmin } from "@/hooks/useIsAdmin"
import { useGetAllGamesQuery } from "@/store/api/game"
import { useCreateSpaceMutation } from "@/store/api/space"
import { selectEnv } from "@/store/slices/context"

interface FormData extends SpaceCreateRequest {}

export const SpaceCreate: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const env = useAppSelector(selectEnv)
  const isAdmin = useIsAdmin()
  const methods = useForm<FormData>({
    mode: "all",
    defaultValues: {
      qdOperationReason: "Space creation"
    }
  })
  const { reset: resetForm } = methods
  const [open, setOpen] = useState<boolean>(false)

  const [createSpace, mutation] = useCreateSpaceMutation()
  const { isSuccess, isLoading } = mutation

  const { data: games } = useGetAllGamesQuery({ env }, { skip: !env })

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm])

  const onSubmitHandler = (data: FormData) => createSpace(data)

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [dispatch, isSuccess, onCloseHandler])

  return (
    isAdmin && (
      <>
        <Button
          variant="outlined"
          onClick={() => setOpen(true)}
          startDecorator={<Plus />}
        >
          {t("action.item", {
            action: "action.create",
            item: "item.space"
          })}
        </Button>

        <Modal
          open={open}
          onClose={onCloseHandler}
          title={t("action.item", {
            action: "action.create",
            item: "item.space"
          })}
        >
          <Form
            methods={methods}
            loading={isLoading}
            submitLabel={t("action.create")}
            onSubmit={onSubmitHandler}
            sx={{ gap: 2 }}
          >
            <FormSelect
              name="gameId"
              label={t("item.game")}
              options={
                games?.map(({ name, gameId }) => ({
                  label: name,
                  value: gameId
                })) || []
              }
              rules={{ required: true }}
            />
            <FormInput
              type="text"
              name="spaceName"
              label={t("modifier.name", { item: "item.space" })}
              helper={t("hint.spaceName")}
              rules={{
                required: true,
                validate: (value) =>
                  (!value.startsWith("-") && !value.endsWith("-")) ||
                  t("error.space.spaceName")
              }}
              onChange={(event) => {
                event.target.value = event.target.value.replace(/\s/g, "-")
              }}
            />

            <FormInput
              type="text"
              name="spacePrefix"
              label={t("modifier.prefix", { item: "item.space" })}
              helper={t("hint.spacePrefix")}
              rules={{
                required: true,
                validate: (value) =>
                  value.endsWith("_") || t("error.space.spacePrefix")
              }}
              onChange={(event) => {
                event.target.value = event.target.value.replace(/\s/g, "")
              }}
            />
          </Form>
        </Modal>
      </>
    )
  )
}
