import { Operation } from "@liveops-portal/lib"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { GlobalState } from "@/typings/redux"

/* Interface for the state of the Audit slice */
export interface AuditState {
  activeOperation: Operation | null
  qdOperationReason: string | null
}

const initialState = {
  activeOperation: null,
  qdOperationReason: null
} as AuditState

/* Redux store slice for the App feature. */
export const auditSlice = createSlice({
  name: "audit",
  initialState,
  reducers: {
    setActiveOperation: (
      state: AuditState,
      { payload: operation }: PayloadAction<Operation | null>
    ) => {
      state.activeOperation = operation
    },
    setOperationReason: (
      state: AuditState,
      { payload: qdOperationReason }: PayloadAction<string>
    ) => {
      state.qdOperationReason = qdOperationReason
    },
    resetOperation: () => initialState
  }
})

export const { setActiveOperation, setOperationReason, resetOperation } =
  auditSlice.actions

export const selectActiveOperation = (state: GlobalState) =>
  state.audit.activeOperation
export const selectOperationReason = (state: GlobalState) =>
  state.audit.qdOperationReason

export default auditSlice.reducer
