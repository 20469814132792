import { Unstable_Popup as Popup } from "@mui/base"
import { GlobalStyles, IconButton, Input, Stack } from "@mui/joy"
import { Slide } from "@mui/material"
import { Check, Xmark } from "iconoir-react"
import { MouseEvent, MouseEventHandler, useCallback, useEffect } from "react"
import React from "react"
import { useTranslation } from "react-i18next"
import { TransitionAdapter } from "@/components/transition-adapter/transition-adapter"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useEventListener } from "@/hooks/useEventListener"
import {
  resetOperation,
  selectOperationReason,
  setOperationReason
} from "@/store/slices/audit"

interface Props {
  onSubmit: (...args: unknown[]) => void
  onClose?: (...args: unknown[]) => void
  open: boolean
  autofocus?: boolean
  closeable?: boolean
  disabled?: boolean
}

export const OperationReason: React.FC<Props> = ({
  onSubmit,
  onClose,
  open,
  autofocus,
  closeable,
  disabled: _disabled
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const operationReason = useAppSelector(selectOperationReason)
  const disabled =
    operationReason == null || operationReason === "" || _disabled

  const onSubmitHandler: MouseEventHandler = (event) => {
    event.preventDefault()

    onSubmit()
  }

  const onCloseHandler = useCallback(() => {
    if (open) {
      onClose?.()
      dispatch(resetOperation())
    }
  }, [open, dispatch, onClose])

  useEventListener("keydown", (e: Event) => {
    const event = e as KeyboardEvent
    if (open && event.key === "Escape" && closeable) {
      onCloseHandler()
    }
  })

  useEventListener("keydown", (e: Event) => {
    const event = e as KeyboardEvent
    if (open && event.key === "Enter" && !disabled) {
      onSubmitHandler(e as unknown as MouseEvent)
    }
  })

  useEffect(() => {
    return () => {
      onCloseHandler()
    }
  }, [onCloseHandler])

  return (
    <>
      <GlobalStyles
        styles={{
          ":root": {
            "--OperationReason-height": "53px"
          }
        }}
      />
      <Popup
        container={() => document.getElementById("operation-reason")!}
        open={open}
        role="presentation"
        style={{
          position: "relative"
        }}
      >
        <TransitionAdapter>
          <Slide direction="up" in={open} timeout={200}>
            <Stack
              bgcolor="background.body"
              borderTop={1}
              borderColor="divider"
              direction="row"
              justifyContent="center"
              py={1}
              px={2}
            >
              <Stack direction="row" gap={1} flexGrow={1} alignItems="center">
                <Input
                  autoFocus={autofocus}
                  fullWidth
                  type="text"
                  title={t("label.reason")}
                  id="operation-reason"
                  name="qd-operation-reason"
                  placeholder={t("placeholder.reason")}
                  onChange={(event) =>
                    dispatch(setOperationReason(event.target.value))
                  }
                />
                <IconButton
                  type="submit"
                  data-testid="operation-reason-submit"
                  color="success"
                  variant="soft"
                  value="Submit"
                  onClick={onSubmitHandler}
                  disabled={disabled}
                >
                  <Check />
                </IconButton>
                {closeable && (
                  <IconButton
                    data-testid="operation-reason-reset"
                    color="danger"
                    variant="soft"
                    onClick={onCloseHandler}
                  >
                    <Xmark />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          </Slide>
        </TransitionAdapter>
      </Popup>
    </>
  )
}
