import {
  AllBundlesQuery,
  Bundle,
  DeleteBundleMutation,
  UpsertBundleMutation
} from "@liveops-portal/lib"
import { api } from "@/api"

export const bundleApi = api
  .enhanceEndpoints({ addTagTypes: ["Bundle", "Season"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBundles: builder.query<Bundle[], AllBundlesQuery>({
        query: ({ gameId }) => ({
          url: "bundles",
          params: { gameId }
        }),
        providesTags: ["Bundle"]
      }),
      upsertBundle: builder.mutation<Bundle, UpsertBundleMutation>({
        query: ({
          gameId,
          bundleName,
          seasonIds,
          bundleId,
          qdOperationReason
        }) => {
          return {
            url: "bundles",
            method: "PUT",
            params: { gameId },
            body: { bundleName, seasonIds, bundleId },
            headers: {
              "QD-Operation-Reason": qdOperationReason
            }
          }
        },
        invalidatesTags: ["Bundle", { type: "Season", id: "ALL" }]
      }),
      deleteBundle: builder.mutation<void, DeleteBundleMutation>({
        query: ({ gameId, bundleId }) => ({
          url: `bundles/${bundleId}`,
          method: "DELETE",
          params: { gameId }
        }),
        invalidatesTags: ["Bundle", { type: "Season", id: "ALL" }]
      })
    })
  })

export const {
  useGetBundlesQuery,
  useUpsertBundleMutation,
  useDeleteBundleMutation
} = bundleApi
