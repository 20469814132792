import { json } from "@codemirror/lang-json"
import {
  Stack,
  FormControl,
  useColorScheme,
  Chip,
  ColorPaletteProp
} from "@mui/joy"
import { SxProps } from "@mui/system"
import { nanoid } from "@reduxjs/toolkit"
import CodeMirror, { ReactCodeMirrorProps } from "@uiw/react-codemirror"
import React from "react"
import { useTranslation } from "react-i18next"
import { FormLabel } from "@/components/form-label/form-label"
import { codeEditorDark, codeEditorLight } from "@/styles/code-mirror"

interface Props extends ReactCodeMirrorProps {
  value: string
  name: string
  error?: boolean
  label?: string
  sx?: SxProps
}

const extensions = [json()]

/**
 * CodeEditor component.
 * Styles a code editor textarea using JoyUI
 */
export const CodeEditor: React.FC<Props> = ({
  value,
  name,
  label,
  error,
  onChange,
  sx,
  ...props
}: Props) => {
  const { t } = useTranslation()
  const { mode } = useColorScheme()

  const id = `code-editor-${nanoid()}`

  const renderStateChip = (
    state: boolean | undefined,
    color: ColorPaletteProp,
    text: string
  ) => {
    if (!state) {
      return
    }

    return (
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          position: "absolute",
          inset: 0,
          top: "auto",
          mb: 2
        }}
      >
        <Chip color={color} variant="solid">
          {t(text)}
        </Chip>
      </Stack>
    )
  }

  return (
    <Stack component={FormControl} error={error} sx={{ flexGrow: 1, ...sx }}>
      {label && <FormLabel id={id}>{label}</FormLabel>}
      <Stack
        sx={{
          flexGrow: 1,
          position: "relative",
          borderRadius: "sm",
          border: 1,
          borderColor: error
            ? "danger.outlinedBorder"
            : props?.readOnly
              ? "warning.outlinedBorder"
              : "neutral.outlinedBorder",
          boxShadow: "xs",
          overflow: "hidden",
          "& .cm-theme, & .cm-editor, & .cm-scroller": {
            borderRadius: "inherit"
          }
        }}
      >
        <CodeMirror
          value={value}
          aria-labelledby={id}
          extensions={extensions}
          theme={mode === "dark" ? codeEditorDark : codeEditorLight}
          onChange={onChange}
          height="100%"
          style={{ position: "absolute", inset: 0 }}
          {...props}
        />

        <input type="hidden" name={name} defaultValue={value} />

        {renderStateChip(error, "danger", "error.invalidJson")}

        {renderStateChip(props?.readOnly, "warning", "state.readOnly")}
      </Stack>
    </Stack>
  )
}
