import { useColorScheme, IconButton } from "@mui/joy"
import { useColorScheme as useMaterialColorScheme } from "@mui/material"
import { SunLight, HalfMoon } from "iconoir-react"
import { useTranslation } from "react-i18next"

export const ModeSwitcher: React.FC = () => {
  const { t } = useTranslation()
  const { setMode: setMaterialMode } = useMaterialColorScheme() // Once Joy UI reached component parity with Material UI, this can be removed
  const { mode, setMode } = useColorScheme()

  const onClickHandler = () => {
    // TODO: Once Joy UI reaches component parity with Material UI, setMaterialMode can be removed
    if (mode === "light") {
      setMode("dark")
      setMaterialMode("dark")
    } else {
      setMode("light")
      setMaterialMode("light")
    }
  }

  const title =
    mode === "dark" ? t("hint.switchToLight") : t("hint.switchToDark")
  const Icon = mode === "dark" ? SunLight : HalfMoon

  return (
    <IconButton
      size="sm"
      title={title}
      onClick={onClickHandler}
      data-testid="mode-switcher-button"
      sx={{ ":hover": { bgcolor: "transparent" } }}
    >
      <Icon />
    </IconButton>
  )
}
