import {
  GameServerBuild,
  GameServerBuildDeploymentType,
  DataState,
  GameServerBuildType
} from "@liveops-portal/lib"
import { Stack, Typography } from "@mui/joy"
import { skipToken } from "@reduxjs/toolkit/query"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useGetGameServerBuildsQuery } from "@/api/game-server-build"
import { Filters } from "@/components/filters/filters"
import { GameServerBuildCard } from "@/components/game-server-build-card/game-server-build-card"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { selectGame } from "@/store/slices/context"

/**
 * Component representing the build page.
 */
export const GameServerBuildsPage: React.FC = () => {
  const { t } = useTranslation()
  const gameId = useAppSelector(selectGame)

  const { data: gameServerBuilds, isFetching } = useGetGameServerBuildsQuery(
    gameId ? { gameId } /* c8 ignore next */ : skipToken
  )

  const [filteredGameServerBuilds, setFilteredGameServerBuilds] = useState<
    GameServerBuild[] | undefined
  >()
  const [activeFilters, setActiveFilters] = useState<Record<string, string[]>>(
    {}
  )
  const [searchTerm, setSearchTerm] = useState<string>("")

  const searchable = useMemo(
    () => ["buildName", "buildId", "codeChangeList", "dataChangeList"],
    []
  )

  const filters = [
    {
      accessor: "buildType" as const,
      label: t("label.gameServerBuild.buildType"),
      options: Object.keys(GameServerBuildType).map((key) => ({
        label: t(
          `${GameServerBuildType[key as keyof typeof GameServerBuildType]}`
        ),
        value: key
      }))
    },
    {
      accessor: "deploymentType" as const,
      label: t("label.gameServerBuild.deploymentType"),
      options: Object.keys(GameServerBuildDeploymentType).map((key) => ({
        label: t(
          `${GameServerBuildDeploymentType[key as keyof typeof GameServerBuildDeploymentType]}`
        ),
        value: key
      }))
    },
    {
      accessor: "state" as const,
      label: t("label.gameServerBuild.state"),
      options: Object.keys(DataState).map((key) => ({
        label: t(`${DataState[key as keyof typeof DataState]}`),
        value: key
      }))
    }
  ]

  const applyFilters = useCallback(() => {
    if (!gameServerBuilds) return

    let result = [...gameServerBuilds]

    if (searchTerm) {
      result = result.filter((item) =>
        searchable.some((key) => {
          const value = item[key as keyof GameServerBuild]
          return (
            !!value &&
            JSON.stringify(value)
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          )
        })
      )
    }

    Object.entries(activeFilters).forEach(([accessor, filterValues]) => {
      if (filterValues.length > 0) {
        result = result.filter((item) =>
          filterValues.some((filterValue) =>
            (item[accessor as keyof GameServerBuild] as string).includes(
              filterValue
            )
          )
        )
      }
    })

    setFilteredGameServerBuilds(result)
  }, [gameServerBuilds, searchTerm, activeFilters, searchable])

  useEffect(() => {
    applyFilters()
  }, [applyFilters])

  useEffect(() => {
    setFilteredGameServerBuilds(gameServerBuilds)
  }, [gameServerBuilds])

  const onSearchChangeHandler = (searchString: string) => {
    setSearchTerm(searchString)
  }

  const onFilterChangeHandler = (accessor: string, filterValue: string) => {
    setActiveFilters((current) => {
      const currentValues = current[accessor] || []

      if (currentValues.includes(filterValue)) {
        return {
          ...current,
          [accessor]: currentValues.filter((v) => v !== filterValue)
        }
      }

      return {
        ...current,
        [accessor]: [...currentValues, filterValue]
      }
    })
  }

  const onResetHandler = () => {
    setActiveFilters({})
    setSearchTerm("")
    setFilteredGameServerBuilds(gameServerBuilds)
  }

  return (
    <>
      <Spinner loading={isFetching} />

      {gameServerBuilds?.length && filteredGameServerBuilds ? (
        <Stack sx={{ gap: 2 }}>
          <Filters
            searchable={searchable}
            filters={filters}
            onSearchChange={onSearchChangeHandler}
            onFilterChange={onFilterChangeHandler}
            onReset={onResetHandler}
          />
          {filteredGameServerBuilds.length ? (
            <Stack sx={{ gap: 2 }}>
              {filteredGameServerBuilds.map((build: GameServerBuild) => (
                <GameServerBuildCard key={build.buildId} {...build} />
              ))}
            </Stack>
          ) : (
            <Typography>
              {t("message.noItems.selectedCriteria", {
                item: "item.builds"
              })}
            </Typography>
          )}
        </Stack>
      ) : (
        <Typography>
          {t("message.noItems.parent", {
            item: "item.builds",
            parent: "item.game"
          })}
        </Typography>
      )}
    </>
  )
}
