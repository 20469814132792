import { formatJsonToString } from "@liveops-portal/lib"
import {
  FormControl,
  FormLabel,
  Select,
  Stack,
  Option,
  Typography,
  FormHelperText
} from "@mui/joy"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CodeEditor } from "@/components/code-editor/code-editor"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import {
  useFindAllGameDataVersionsQuery,
  useFindAllGameDataTypesQuery,
  useFindAllGameDataQuery
} from "@/store/api/game-data"
import { useGetSeasonsQuery } from "@/store/api/season"
import { selectEnv, selectGame, selectSeason } from "@/store/slices/context"

/**
 * Component representing the Season Designer DataViewer page.
 */
export const SeasonDesignerDataViewerPage: React.FC = () => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const seasonId = useAppSelector(selectSeason)
  const [dataVersion, setDataVersion] = useState<string | null>(null)
  const [dataType, setDataType] = useState<string | null>(null)

  const activeSeason = useGetSeasonsQuery(
    { gameId, env },
    {
      skip: !gameId || !env,
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.find((season) => season.seasonId === seasonId)
      })
    }
  )

  const dataVersions = useFindAllGameDataVersionsQuery(
    { gameId, env },
    { skip: !env || !gameId }
  )

  const dataTypes = useFindAllGameDataTypesQuery(
    { env, gameId, dataVersion },
    { skip: !dataVersion }
  )

  const gameData = useFindAllGameDataQuery(
    { env, gameId, dataVersion, dataType },
    { skip: !dataType }
  )

  useEffect(() => {
    setDataVersion(
      activeSeason?.data?.clientDataVersion
        ? activeSeason.data.clientDataVersion
        : null
    )
    setDataType(null)
  }, [activeSeason])

  return (
    <>
      <Spinner
        loading={
          activeSeason?.isFetching ||
          dataVersions?.isFetching ||
          dataVersions?.isUninitialized
        }
      />

      <Stack sx={{ gap: 2, flexGrow: 1 }}>
        {dataVersions?.data?.length ? (
          <>
            <Stack sx={{ gap: 1 }}>
              <Stack sx={{ gap: 2, flexDirection: "row" }}>
                <FormControl>
                  <FormLabel>{t("item.dataVersion")}</FormLabel>
                  <Select
                    size="sm"
                    value={dataVersion || "placeholder"}
                    name="dataVersion"
                    onChange={(_, value) => {
                      setDataVersion(value)
                    }}
                    sx={{ flexGrow: 1, width: 145 }}
                  >
                    <Option disabled value="placeholder">
                      {t("placeholder.option")}
                    </Option>
                    {dataVersions.data?.map((option) => (
                      <Option key={option} label={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>{t("item.dataType")}</FormLabel>
                  <Select
                    size="sm"
                    value={dataType || "placeholder"}
                    name="dataType"
                    disabled={!dataVersion || !dataTypes?.data?.length}
                    onChange={(_, value) => {
                      setDataType(value)
                    }}
                    sx={{ flexGrow: 1, width: 145 }}
                  >
                    <Option disabled value="placeholder">
                      {t("placeholder.option")}
                    </Option>
                    {dataTypes?.data?.map((option) => (
                      <Option key={option} label={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              {dataVersion === activeSeason?.data?.clientDataVersion && (
                <FormHelperText>
                  {t("message.dataViewer.associatedVersion", {
                    seasonName: activeSeason?.data?.seasonName,
                    dataVersion
                  })}
                </FormHelperText>
              )}
            </Stack>
            <Stack
              sx={{
                flexGrow: 1,
                position: "relative"
              }}
            >
              <Spinner loading={gameData.isFetching} />
              <CodeEditor
                readOnly
                name="gameData"
                value={formatJsonToString(
                  dataVersion && dataType && gameData?.data ? gameData.data : {}
                )}
                label={t("item.gameData")}
              />
            </Stack>
          </>
        ) : (
          <Typography>
            {t("message.noItems.parent", {
              parent: "item.game",
              item: "item.dataVersions"
            })}
          </Typography>
        )}
      </Stack>
    </>
  )
}
