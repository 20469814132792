import { AllGamesQuery, CreateGameMutation, Game } from "@liveops-portal/lib"
import { api } from "@/api"

export const gameApi = api
  .enhanceEndpoints({ addTagTypes: ["Game"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAllGames: builder.query<Game[], AllGamesQuery>({
        query: ({ includeMarkedForDeletion }) => ({
          url: `games`,
          params: { includeMarkedForDeletion }
        }),
        providesTags: ["Game"]
      }),
      createGame: builder.mutation<Game, CreateGameMutation>({
        query: ({ gameId, name, qdOperationReason }) => {
          return {
            url: `/games`,
            method: "POST",
            body: {
              gameId,
              name
            },
            headers: {
              "QD-Operation-Reason": qdOperationReason
            }
          }
        },
        invalidatesTags: ["Game"]
      })
    }),
    overrideExisting: false
  })

export const { useGetAllGamesQuery, useCreateGameMutation } = gameApi
