import { useAccount } from "@azure/msal-react"
import {
  Avatar,
  Chip,
  Dropdown,
  ListDivider,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Typography
} from "@mui/joy"
import { SxProps } from "@mui/system"
import { HelpCircle, Settings } from "iconoir-react"
import React from "react"
import { useTranslation } from "react-i18next"
import { useGetPhotoQuery } from "@/store/api/user"

interface Props {
  sx?: SxProps
}

export const UserCard: React.FC<Props> = ({ sx }: Props) => {
  const account = useAccount()

  const { data: photo } = useGetPhotoQuery(
    { id: account?.username },
    { skip: !account }
  )

  const { t, i18n } = useTranslation()

  return (
    <Dropdown>
      <MenuButton
        variant="plain"
        size="sm"
        disabled={!account}
        sx={{
          ...sx,
          maxWidth: "32px",
          maxHeight: "32px",
          borderRadius: "100%"
        }}
      >
        <Avatar
          role="img"
          variant="outlined"
          src={photo}
          sx={{ maxWidth: "32px", maxHeight: "32px" }}
        />
      </MenuButton>
      <Menu placement="bottom-end" size="sm" sx={{ mt: "20px" }}>
        <MenuItem>
          <Stack>
            <Typography level="title-sm" textColor="text.primary">
              {account?.name}
            </Typography>
            <Typography level="body-xs" textColor="text.tertiary">
              {account?.username}
            </Typography>
          </Stack>
        </MenuItem>
        <ListDivider />
        <MenuItem disabled>
          <HelpCircle />
          {t("app.support")}
        </MenuItem>
        <MenuItem disabled>
          <Settings />
          {t("app.settings")}
        </MenuItem>
        <ListDivider />
        <MenuItem
          data-testid="language-switch"
          onClick={() => {
            const updatedLanguage =
              i18n.language === "en-US" ? /* c8 ignore next */ "zh-CN" : "en-US"
            i18n.changeLanguage(updatedLanguage)
            localStorage.setItem("localization", updatedLanguage)
          }}
        >
          <Chip>{t("common.languageCode")}</Chip>
          {t("action.language")}
        </MenuItem>
      </Menu>
    </Dropdown>
  )
}
