import { AuditLogsRequest, Audit } from "@liveops-portal/lib"
import { api } from "@/api"

export const auditApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAudits: builder.query<Audit[], AuditLogsRequest>({
      query: ({ duration }) => ({
        url: "audits",
        params: { duration }
      })
    })
  }),
  overrideExisting: false
})

export const { useGetAuditsQuery } = auditApi
