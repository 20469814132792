import {
  DeployGameServerBuildMutation,
  GameServerBuildDeploymentCpu
} from "@liveops-portal/lib"
import { skipToken } from "@reduxjs/toolkit/query"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo
} from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
  useDeployBuildMutation,
  useGetGameServerBuildsQuery
} from "@/api/game-server-build"
import { useFindAllSpacesQuery } from "@/api/space"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { FormRadioGroup } from "@/components/form-radio-group/form-radio-group"
import { FormSelect } from "@/components/form-select/form-select"
import { Modal } from "@/components/modal/modal"
import { useAppSelector } from "@/hooks/store"
import { useIsAdmin } from "@/hooks/useIsAdmin"
import { selectEnv, selectGame } from "@/store/slices/context"

type FormData = DeployGameServerBuildMutation

interface Props
  extends Partial<Pick<DeployGameServerBuildMutation, "spaceId" | "buildId">> {
  updating?: "space" | "build"
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const GameServerBuildDeploy = ({
  spaceId,
  buildId,
  updating,
  open,
  setOpen
}: Props) => {
  const { t } = useTranslation()
  const isAdmin = useIsAdmin()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)

  const { data: gameServerBuilds, isFetching: isFetchingBuilds } =
    useGetGameServerBuildsQuery(open && gameId ? { gameId } : skipToken)
  const { data: spaces, isFetching: isFetchingSpaces } = useFindAllSpacesQuery(
    open && env && gameId ? { env } : skipToken,
    {
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.filter((space) => space.gameId === gameId)
      })
    }
  )

  const isFetching = isFetchingBuilds || isFetchingSpaces

  const defaultValues = useMemo(
    () => ({
      gameId,
      spaceId: spaceId || "",
      buildId,
      envName: env,
      cpuType: "Amd" as const,
      numberOfCores: 2,
      qdOperationReason: "Build activated from LiveOps Portal"
    }),
    [env, gameId, spaceId, buildId]
  )

  const methods = useForm<FormData>({
    mode: "all",
    defaultValues
  })
  const { reset } = methods
  const [deploy, { isSuccess, isLoading }] = useDeployBuildMutation()

  const onSubmitHandler = (data: FormData) => deploy(data)

  const resetForm = useCallback(
    () => reset(defaultValues),
    [defaultValues, reset]
  )

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm, setOpen])

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [isSuccess, onCloseHandler])

  useEffect(() => resetForm(), [gameServerBuilds, resetForm])

  return (
    <Modal
      open={open}
      onClose={onCloseHandler}
      title={t("label.gameServerBuild.deploy")}
      sx={{
        "--ModalDialog-minWidth": "600px"
      }}
    >
      <Form
        methods={methods}
        loading={isLoading}
        disabled={isFetching}
        submitLabel={t("action.deploy")}
        onSubmit={onSubmitHandler}
      >
        <FormSelect
          name="spaceId"
          label={t("item.space")}
          defaultValue={spaceId}
          disabled={isFetchingSpaces || updating === "build"}
          options={
            spaces?.map((s) => ({
              label: s.spaceName,
              value: s.spaceId
            })) || []
          }
          rules={{ required: true }}
        />

        <FormSelect
          name="buildId"
          label={t("item.build")}
          disabled={isFetchingBuilds || updating === "space"}
          options={
            gameServerBuilds?.map((b) => ({
              label: b.buildName,
              value: b.buildId
            })) || []
          }
          rules={{ required: true }}
        />
        {isAdmin && (
          <>
            <FormRadioGroup
              label={t("label.gameServerBuild.cpuType")}
              name="cpuType"
              options={Object.keys(GameServerBuildDeploymentCpu).map(
                (value) => ({
                  value,
                  label: GameServerBuildDeploymentCpu[value]
                })
              )}
              rules={{ required: true }}
            />
            <FormInput
              type="number"
              name="numberOfCores"
              label={t("label.gameServerBuild.numberOfCores")}
              rules={{ min: 0, max: 32, required: true }}
              slotProps={{
                input: { min: 0, max: 32 }
              }}
            />
          </>
        )}
      </Form>
    </Modal>
  )
}
