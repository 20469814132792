import { Environments } from "@liveops-portal/lib"
import { Sheet, Stack, Typography } from "@mui/joy"
import React, { PropsWithChildren } from "react"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"
import { Slide, ToastContainer } from "react-toastify"
import { Header } from "@/components/header/header"
import { NotificationCenter } from "@/components/notification-center/notification-center"
import { Page } from "@/components/page/page"
import { Sidebar } from "@/components/sidebar/sidebar"
import { useAppSelector } from "@/hooks/store"
import { selectRevision } from "@/store/slices/app"
import { selectActiveOperation } from "@/store/slices/audit"
import { selectStage } from "@/store/slices/context"

export const Layout: React.FC<PropsWithChildren> = ({
  children
}: PropsWithChildren) => {
  const activeOperationReason = useAppSelector(selectActiveOperation)
  const stage = useAppSelector(selectStage)
  const revision = useAppSelector(selectRevision)
  const showWarning = stage === Environments.ONLINEDEV && revision !== "local"

  return (
    <>
      <Sidebar />
      <Stack
        component="section"
        sx={{
          transition: "margin 0.2s",
          height: "100dvh",
          ml: "var(--Sidebar-width)",
          position: "relative"
        }}
      >
        <Header />
        {showWarning && (
          <Sheet color="warning" variant="solid" invertedColors>
            <Typography textAlign="center" fontSize="xs" p={0.5}>
              <Trans
                i18nKey="message.appStage"
                components={[<Link to={import.meta.env.__LIVE_URL} />]}
              />
            </Typography>
          </Sheet>
        )}
        <Stack
          component="main"
          sx={{
            transition: "all 0.2s",
            overflow: "auto",
            p: 2,
            position: "absolute",
            inset: 0,
            top: showWarning
              ? "calc(var(--Header-height) + 26px)"
              : "var(--Header-height)",
            /* c8 ignore start */
            ...(activeOperationReason && {
              bottom: "var(--OperationReason-height)"
            })
            /* c8 ignore end */
          }}
        >
          <Page>{children}</Page>

          <ToastContainer
            role="none"
            limit={3}
            autoClose={3000}
            transition={Slide}
            icon={false}
            closeButton={false}
            hideProgressBar={true}
          />
          <NotificationCenter />
        </Stack>
        <Stack
          id="operation-reason"
          sx={{
            position: "absolute",
            inset: 0,
            top: "auto"
          }}
        />
      </Stack>
    </>
  )
}
