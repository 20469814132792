import { CustomErrors, ErrorKey, findValue } from "@liveops-portal/lib"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useFindAllConfigsQuery } from "@/api/config"
import { useAppSelector } from "@/hooks/store"
import { selectSpace } from "@/store/slices/context"

/**
 * Returns config based errors related to Unimatrix/Unipublish categories and keys from the private config.
 * Comparison is case-insensitive.
 */
export const useUniservicesConfigStatus = () => {
  const spaceId = useAppSelector(selectSpace)
  const { t } = useTranslation("translation", {
    keyPrefix: "message.configuration"
  })

  const {
    data: config,
    isFetching,
    isUninitialized
  } = useFindAllConfigsQuery({ spaceId }, { skip: !spaceId })

  const errors = useMemo(() => {
    const requiredConfigCategories = {
      unimatrix: [
        "appId",
        "appSecret",
        "gameId",
        "gameServerBaseUrl",
        "roleId"
      ],
      unipublish: ["baseUrl", "gameId", "signatureKey"]
    }

    const errors: CustomErrors = {}

    if (config) {
      Object.entries(requiredConfigCategories).forEach(([category, keys]) => {
        const lowercaseCategory = findValue(config, category)

        // If the entire category doesn't exist
        if (!lowercaseCategory) {
          errors[category] = {
            [ErrorKey.MissingConfigurationCategory]: t(
              "MissingConfigurationCategory",
              { category }
            )
          }

          return
        }

        // If category is present but private keys are missing
        const missingKeys = keys.filter(
          (key) => !findValue(lowercaseCategory, `private.${key}`)
        )

        if (missingKeys.length) {
          errors[category] = {
            [ErrorKey.MissingConfigurationKeys]: t("MissingConfigurationKeys", {
              category,
              missingKeys: missingKeys.join(", ")
            })
          }
        }
      })
    } else {
      // Entire configuration is empty
      errors[spaceId] = {
        [ErrorKey.EmptySpaceConfiguration]: t("EmptySpaceConfiguration")
      }
    }

    return errors
  }, [config, spaceId, t])

  return { errors, isFetching, isUninitialized }
}
