import { Stack, Typography, Button, Box } from "@mui/joy"
import * as Sentry from "@sentry/react"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { generatePath, Link, useRouteError } from "react-router-dom"
import { Layout } from "@/components/layout/layout"
import { useAppSelector } from "@/hooks/store"
import { selectEnv } from "@/store/slices/context"

export const ErrorPage: React.FC = () => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)
  const error = useRouteError() as Error

  useEffect(() => {
    /* c8 ignore start */
    if (!error) return
    Sentry.withScope(() => {
      Sentry.captureException(error)
    })
    /* c8 ignore end */
  }, [error])

  return (
    <Layout>
      <Stack sx={{ gap: 3 }}>
        <Typography
          level="body-lg"
          sx={{ fontWeight: "md", whiteSpace: "pre-line" }}
        >
          {t("error.generic")}
        </Typography>
        <Typography level="body-md" sx={{ whiteSpace: "pre-line" }}>
          {t("message.notified")} {t("message.sorry")}
        </Typography>
        <Box>
          <Button component={Link} to={generatePath("/:env", { env })}>
            {t("action.home")}
          </Button>
        </Box>
      </Stack>
    </Layout>
  )
}
