import { CurrencyItem, CurrencyMutation, Operation } from "@liveops-portal/lib"
import { Button, Input, Stack } from "@mui/joy"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
  useCreditCurrencyMutation,
  useDebitCurrencyMutation
} from "@/api/player"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { selectActiveOperation, setActiveOperation } from "@/store/slices/audit"
import { selectSpace } from "@/store/slices/context"

type Props = CurrencyItem & {
  accountId: string
}

export const PlayerWalletItem: React.FC<Props> = ({
  currencyCode,
  balance,
  accountId
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const activeOperation = useAppSelector(selectActiveOperation)

  const spaceId = useAppSelector(selectSpace)

  const [credit, creditMutation] = useCreditCurrencyMutation()
  const [debit, debitMutation] = useDebitCurrencyMutation()

  const creditOperation: Operation = [currencyCode, "credit"]
  const debitOperation: Operation = [currencyCode, "debit"]

  const isCreditOperationActive = isOperationActive(
    activeOperation,
    creditOperation
  )
  const isDebitOperationActive = isOperationActive(
    activeOperation,
    debitOperation
  )
  const {
    formState: { isValid },
    getValues,
    register,
    reset,
    watch
  } = useForm<CurrencyMutation>({
    mode: "all",
    defaultValues: {
      accountId,
      spaceId,
      currencyCode,
      amount: 0
    }
  })

  const onSubmitHandler = () => {
    if (isCreditOperationActive) credit(getValues())

    if (isDebitOperationActive) debit(getValues())
  }

  useEffect(() => {
    if (creditMutation.isSuccess || debitMutation.isSuccess) {
      reset()
    }
  }, [creditMutation.isSuccess, debitMutation.isSuccess, reset])

  const disabled = !watch("amount") || !isValid || !!activeOperation

  return (
    <>
      <OperationReason
        open={isCreditOperationActive || isDebitOperationActive}
        onSubmit={onSubmitHandler}
        autofocus
        closeable
      />

      <tr>
        <td>{currencyCode}</td>
        <td>{balance}</td>
        <td>
          <Stack component="form" sx={{ flexDirection: "row", gap: 1 }}>
            <Input
              {...register("amount", { min: 1 })}
              aria-label={t("label.amount")}
              size="sm"
              type="number"
              autoComplete="off"
              slotProps={{ input: { min: 0, step: 10 } }}
              sx={{ width: 90 }}
            />
            <Button
              size="sm"
              color="success"
              variant="soft"
              name="credit"
              disabled={disabled || isDebitOperationActive}
              loading={isCreditOperationActive}
              onClick={() => {
                dispatch(setActiveOperation([currencyCode, "credit"]))
              }}
            >
              {t("action.credit")}
            </Button>
            <Button
              size="sm"
              color="danger"
              variant="soft"
              disabled={disabled || isCreditOperationActive}
              loading={isDebitOperationActive}
              onClick={() => {
                dispatch(setActiveOperation([currencyCode, "debit"]))
              }}
            >
              {t("action.debit")}
            </Button>
          </Stack>
        </td>
      </tr>
    </>
  )
}
