import { Switch, FormControl, SwitchProps, FormControlProps } from "@mui/joy"
import {
  Controller,
  ControllerProps,
  FieldValues,
  Path,
  useFormContext
} from "react-hook-form"
import { FormLabel } from "@/components/form-label/form-label"

interface Props<T extends FieldValues>
  extends SwitchProps,
    Pick<FormControlProps, "orientation"> {
  name: Path<T>
  label?: string
  rules?: ControllerProps<T>["rules"]
  hint?: string
}

/**
 * Renders a Joy UI Switch bound to React Hook Form.
 * @param name
 * @param label
 * @param rules
 * @param hint
 * @param sx
 */
export const FormSwitch = <T extends FieldValues>({
  name,
  label,
  rules,
  hint,
  sx,
  ...props
}: Props<T>) => {
  const { control } = useFormContext<T>()
  const { defaultValue: _, orientation, ...switchProps } = props

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControl sx={sx} orientation={orientation}>
          {label && <FormLabel hint={hint}>{label}</FormLabel>}
          <Switch
            {...field}
            {...switchProps}
            size="sm"
            sx={{ mt: "2px" }}
            aria-label={label}
            checked={!!field.value}
            slotProps={{ input: { value: field.value } }}
          />
        </FormControl>
      )}
    />
  )
}
