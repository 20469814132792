import {
  ApiError,
  BuildSpaceAssociationsByAppId,
  ByEnvQuery,
  ByGameIdQuery
} from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const buildApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllBuildsByGameId: builder.query<
      BuildSpaceAssociationsByAppId,
      ByGameIdQuery & ByEnvQuery
    >({
      query: ({ gameId }) => ({
        url: "builds",
        params: { gameId }
      }),
      onQueryStarted({ gameId }, { queryFulfilled }) {
        queryFulfilled.catch(({ error }) => {
          notify.error({
            message: i18next.t("error.api.getAllBuildsByGameId", { gameId }),
            error: error as ApiError
          })
        })
      }
    })
  }),
  overrideExisting: false
})

export const { useGetAllBuildsByGameIdQuery } = buildApi
