import { Card, CardContent, List, ListItem, Stack, Typography } from "@mui/joy"
import { skipToken } from "@reduxjs/toolkit/query"
import React from "react"
import { useTranslation } from "react-i18next"
import { useGetPlayerQuery } from "@/api/player"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"
import { useAppSelector } from "@/hooks/store"
import { selectSpace, selectPlayer } from "@/store/slices/context"

export const PlayerIdentities: React.FC = () => {
  const { t } = useTranslation()
  const spaceId = useAppSelector(selectSpace)
  const { type, accountId } = useAppSelector(selectPlayer)
  const { currentData: playerProfile } = useGetPlayerQuery(
    spaceId && !!accountId.length && type
      ? { spaceId, accountId, type }
      : skipToken
  )

  return (
    !!playerProfile && (
      <Stack sx={{ gap: 2 }}>
        <Typography level="h3">{t("label.player.identities")}</Typography>
        <Card variant="outlined" sx={{ flexGrow: 1 }}>
          <CardContent sx={{ gap: 2 }}>
            <List
              sx={{
                p: 0,
                "--ListItem-minHeight": 0,
                "--ListItem-paddingX": 0
              }}
            >
              <ListItem sx={{ alignItems: "flex-start" }}>
                <Typography level="title-sm">
                  {t("label.service.unimatrix")}:
                </Typography>

                <CopyToClipboard
                  level="body-sm"
                  sx={{
                    alignItems: "flex-start",
                    wordBreak: "break-all"
                  }}
                >
                  {playerProfile.accountId}
                </CopyToClipboard>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Stack>
    )
  )
}
