// ./src/store/setup.ts
import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import * as Sentry from "@sentry/react"
import { api } from "@/api"
import { listenerMiddleware } from "@/store/middlewares/listener"
import { notificationsMiddleware } from "@/store/middlewares/notifications"
import { GlobalState } from "@/typings/redux"
import { rootReducer } from "./root-reducers"

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

/* Initial configuration of the redux store */
export const setupStore = (preloadedState?: Partial<GlobalState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .prepend(listenerMiddleware.middleware)
        .concat(api.middleware, notificationsMiddleware),
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers().concat(sentryReduxEnhancer),
    preloadedState
  })
}

export const store = setupStore()

setupListeners(store.dispatch)
