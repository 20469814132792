import {
  AllSeasonsQuery,
  Season,
  SeasonComponentItemCreateRequest,
  SeasonComponentItemCreateResponse,
  SeasonCreateRequest,
  SeasonCreateResponse,
  SeasonDeleteRequest,
  SeasonUpdateRequest,
  SeasonUpdateResponse,
  SeasonPublishRequest,
  formatWeeksToTimeSpan,
  SeasonComponentItemUpdateRequest,
  SeasonComponentItemUpdateResponse,
  SeasonComponentItemDeleteRequest,
  SeasonComponentItem,
  SeasonComponentItemsByTypeQuery,
  ByGameIdQuery,
  BySeasonIdQuery
} from "@liveops-portal/lib"
import { v4 as uuidv4 } from "uuid"
import { api } from "@/api"

export const seasonApi = api
  .enhanceEndpoints({
    addTagTypes: ["Season", "SeasonComponentItem", "Bundle"]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSeasons: builder.query<Season[], AllSeasonsQuery>({
        query: ({ gameId }: AllSeasonsQuery) => ({
          url: "seasons",
          params: { gameId }
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ seasonId }) => ({
                  type: "Season" as const,
                  id: seasonId
                })),
                "Season"
              ]
            : ["Season"]
      }),
      createSeason: builder.mutation<SeasonCreateResponse, SeasonCreateRequest>(
        {
          query: ({
            gameId,
            seasonName,
            seasonRevisionDetails: { duration, clientDataVersion },
            qdOperationReason
          }: SeasonCreateRequest) => {
            const seasonId = uuidv4()

            return {
              url: `seasons/${seasonId}`,
              method: "POST",
              params: { gameId },
              body: {
                seasonName,
                seasonRevisionDetails: {
                  clientDataVersion,
                  duration: formatWeeksToTimeSpan(duration)
                }
              },
              headers: {
                "QD-Operation-Reason": qdOperationReason
              }
            }
          },
          invalidatesTags: ["Season"]
        }
      ),
      deleteSeason: builder.mutation<void, SeasonDeleteRequest>({
        query: ({ seasonId, gameId }) => ({
          url: `seasons/${seasonId}`,
          params: { gameId },
          method: "DELETE"
        }),
        invalidatesTags: (result, error, { seasonId }) =>
          !error ? [{ type: "Season", id: seasonId }] : []
      }),
      updateSeason: builder.mutation<SeasonUpdateResponse, SeasonUpdateRequest>(
        {
          query: ({
            gameId,
            seasonId,
            seasonRevisionDetails,
            qdOperationReason
          }: SeasonUpdateRequest) => ({
            url: `seasons/${seasonId}`,
            method: "PUT",
            params: { gameId },
            body: {
              clientDataVersion: seasonRevisionDetails?.clientDataVersion,
              duration:
                seasonRevisionDetails &&
                formatWeeksToTimeSpan(seasonRevisionDetails.duration)
            },
            headers: {
              "QD-Operation-Reason": qdOperationReason
            }
          }),
          invalidatesTags: (result, error, { seasonId }) =>
            !error ? [{ type: "Season", id: seasonId }] : []
        }
      ),
      publishSeasonRevision: builder.mutation<
        { messages: string[] },
        SeasonPublishRequest
      >({
        query: ({
          seasonId,
          gameId,
          revision,
          referenceDate,
          qdOperationReason
        }: SeasonPublishRequest) => ({
          url: `seasons/${seasonId}/publish`,
          method: "POST",
          params: { gameId },
          body: { revision, referenceDate },
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: ["Season", "Bundle"]
      }),
      getSeasonComponentItems: builder.query<
        SeasonComponentItem[],
        SeasonComponentItemsByTypeQuery
      >({
        query: ({ gameId, seasonId, componentType }) => ({
          url: `seasons/${seasonId}/components/${componentType}`,
          params: { gameId }
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ itemId }) => ({
                  type: "SeasonComponentItem" as const,
                  id: itemId
                })),
                "SeasonComponentItem"
              ]
            : ["SeasonComponentItem"]
      }),
      createSeasonComponentItem: builder.mutation<
        SeasonComponentItemCreateResponse,
        SeasonComponentItemCreateRequest
      >({
        query: ({
          gameId,
          seasonId,
          qdOperationReason,
          componentType,
          ...body
        }: SeasonComponentItemCreateRequest) => {
          const itemId = uuidv4()
          return {
            url: `seasons/${seasonId}/components/${componentType}/items/${itemId}`,
            method: "POST",
            params: { gameId },
            body,
            headers: {
              "QD-Operation-Reason": qdOperationReason
            }
          }
        },
        invalidatesTags: (result, error) =>
          !error ? ["SeasonComponentItem"] : []
      }),
      updateSeasonComponentItem: builder.mutation<
        SeasonComponentItemUpdateResponse,
        SeasonComponentItemUpdateRequest
      >({
        query: ({
          gameId,
          seasonId,
          itemId,
          componentType,
          qdOperationReason,
          ...body
        }: SeasonComponentItemUpdateRequest) => ({
          url: `seasons/${seasonId}/components/${componentType}/items/${itemId}`,
          method: "PUT",
          params: { gameId },
          body,
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { itemId: id }) =>
          !error ? [{ type: "SeasonComponentItem", id }] : []
      }),
      deleteSeasonComponentItem: builder.mutation<
        void,
        SeasonComponentItemDeleteRequest
      >({
        query: ({
          gameId,
          seasonId,
          itemId,
          componentType,
          qdOperationReason
        }: SeasonComponentItemDeleteRequest) => ({
          url: `seasons/${seasonId}/components/${componentType}/items/${itemId}`,
          method: "DELETE",
          params: { gameId },
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { itemId: id }) =>
          !error ? [{ type: "SeasonComponentItem", id }] : []
      }),
      getReferenceDate: builder.query<string, ByGameIdQuery & BySeasonIdQuery>({
        query: ({ gameId, seasonId }) => ({
          url: `seasons/${seasonId}/referenceDate`,
          method: "GET",
          params: { gameId }
        }),
        transformResponse: ({ referenceDate }) => referenceDate
      })
    }),
    overrideExisting: false
  })

export const {
  useCreateSeasonComponentItemMutation,
  useUpdateSeasonComponentItemMutation,
  useDeleteSeasonComponentItemMutation,
  useCreateSeasonMutation,
  useDeleteSeasonMutation,
  usePublishSeasonRevisionMutation,
  useUpdateSeasonMutation,
  useGetSeasonsQuery,
  useGetSeasonComponentItemsQuery,
  useGetReferenceDateQuery
} = seasonApi
