# Glossary

Online features use *a lot* of different technologies and buzzwords. The goal of this document is to explain technologies we use, and why we use these.

## PlayFab

External platform that allows us to use various services for our games.

From their offer, we will be using amongst other things:

- matchmaking (with dedicated servers)
- per-player storage
- per-character storage
- statistics
- inventory and shop
- Content Delivery Network (CDN)

Integrating these is faster than writing a feature from scratch, but we have little to no flexibility on the behavior of the service, and might have limits that we don’t control again.

## NetEase UniSDK / UniMatrix

Platform developed by NetEase for their own games. Used in multiple of their titles already.

Exact list of what we will be using is still TBD, but we *probably* will be using at least:

- Login Queue (limit the amount of people that can come into the game at the same time and such)

There is a lot of other services we could be using, things like: per-player data storage, per-character data storage, friends, communication channel between players (could be used to handle control flows for party sessions), …

Integrating these is faster than writing a feature from scratch, but we have little to no flexibility on the behavior of the service, and might have limits that we don’t control again. At least, the NetEase team in charge of this technology has been super reactive; helping us, adding features when needed, which is better than Playfab where we got no control.

## NetEase EasePublish

EasePublish is the technology used by NetEase to link accounts together under a “NetEase” account.

We plan to use this to have a single identity across our backend platforms (UniMatrix / Playfab / Custom services).

## NetEase UData

Platform developed by NetEase for their own games, also used by external actors;

It is used for player tracking, analytics, dashboards, etc.

It can analyze player behavior, supports funnel analysis and various other options.

For example, it can be used to:

- know where people get stuck in tutorials / quest chains / interfaces
- generate heat maps of important points in a map
- analyze player retention
- and so much more

The UData team also offers consulting in various forms:

- daily/weekly analysis of your game and associated suggestions

- help designing tracking data to be able to get the information you want/need out of the platform

## GitLab

External platform hosting our Git repository. Git is used to store all our custom infrastructure and services.

Each Git repository has pipelines attached, these can run on schedule, manually, or on events (code commit for example).

## Azure

Microsoft-owned Cloud Provider; amongst other things, they provide services for: virtual infrastructure, virtual machines, function-as-a-service (FAAS) technologies, storage, etc.

We will be using Azure for all features that are not covered by PlayFab and NetEase UniSDK/UniMatrix. For example: community votes, remote logs, multiple ops tools, etc.

Handling these features is more complex than simply integrating an SDK since we need to take into consideration everything from storage, backup, performance (load tests), security, actual business logic, updates, deployments, monitoring, backward-compatibility etc.
Amazon AWS

Amazon-owned Cloud Provider; amongst other things, they provide services for: virtual infrastructure, virtual machines, function-as-a-service (FAAS) technologies, storage, etc.

AWS is currently used for two things (in Spellcasters' context):

- Temporarily storing builds in the process of being copied from Paris to Montreal
- Host our GitLab Runners
  - These are used to run all pipelines that we trigger from GitLab, for example:
    - Playfab Gameserver build cleanup (triggers on a schedule, once a week)
    - Test pipelines for all our custom services (triggers on code commit in branches)
    - Deployment pipelines for our custom infrastructure / services (triggers manually mainly)

## Related Wikipedia pages

- [Function-as-a-service (FAAS)](https://en.wikipedia.org/wiki/Function_as_a_service)

 

