import {
  GameServerBuild,
  DataState,
  Operation,
  insertIf
} from "@liveops-portal/lib"
import { ColorPaletteProp, Typography } from "@mui/joy"
import { skipToken } from "@reduxjs/toolkit/query"
import dayjs from "dayjs"
import React, { Fragment, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  usePreserveBuildMutation,
  useUnpreserveBuildMutation
} from "@/api/game-server-build"
import { useFindAllSpacesQuery } from "@/api/space"
import { CardItem } from "@/components/card-item/card-item"
import { GameServerBuildDeploy } from "@/components/game-server-build-deploy/game-server-build-deploy"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useIsAdmin } from "@/hooks/useIsAdmin"
import { selectActiveOperation, setActiveOperation } from "@/store/slices/audit"
import { selectEnv } from "@/store/slices/context"

const getDataStateColor = (state: keyof typeof DataState): ColorPaletteProp => {
  switch (DataState[state]) {
    case DataState.WAITING_FOR_UPLOAD:
      return "warning"
    case DataState.READY_FOR_USE:
      return "neutral"
    case DataState.IN_USE:
      return "success"
  }
}

export const GameServerBuildCard: React.FC<GameServerBuild> = ({
  gameId,
  buildId,
  buildType,
  codeChangeList,
  dataChangeList,
  deploymentType,
  state,
  lastUpdate,
  buildName,
  preserved,
  deployments
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const env = useAppSelector(selectEnv)
  const activeOperation = useAppSelector(selectActiveOperation)
  const isAdmin = useIsAdmin()

  const { data: spaces } = useFindAllSpacesQuery(
    env && gameId ? { env } /* c8 ignore next */ : skipToken,
    {
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.filter((space) => space.gameId === gameId)
      })
    }
  )

  const [preserve, preserveMutation] = usePreserveBuildMutation()
  const [unpreserve, unpreserveMutation] = useUnpreserveBuildMutation()

  const [openDeployModal, setOpenDeployModal] = useState<boolean>(false)

  const preserveOperation: Operation = [buildId, "preserve"]
  const unpreserveOperation: Operation = [buildId, "unpreserve"]

  const isPreserveOperationActive = isOperationActive(
    activeOperation,
    preserveOperation
  )
  const isUnpreserveOperationActive = isOperationActive(
    activeOperation,
    unpreserveOperation
  )

  const isLoadingPreserve =
    isPreserveOperationActive || preserveMutation.isLoading
  const isLoadingUnpreserve =
    isUnpreserveOperationActive || unpreserveMutation.isLoading

  const onSubmitHandler = useCallback(() => {
    if (isPreserveOperationActive) preserve({ gameId, buildId })

    if (isUnpreserveOperationActive) unpreserve({ gameId, buildId })
  }, [
    buildId,
    gameId,
    isPreserveOperationActive,
    isUnpreserveOperationActive,
    preserve,
    unpreserve
  ])

  return (
    <>
      <OperationReason
        open={isPreserveOperationActive || isUnpreserveOperationActive}
        onSubmit={onSubmitHandler}
        autofocus
        closeable
      />

      <GameServerBuildDeploy
        open={openDeployModal}
        setOpen={setOpenDeployModal}
        buildId={buildId}
        updating="space"
      />

      <CardItem
        active={isLoadingPreserve || isLoadingUnpreserve}
        title={{ content: buildName }}
        chips={[
          { content: buildType },
          { content: deploymentType },
          {
            content: t(DataState[state]),
            color: getDataStateColor(state),
            props: { "data-testid": "state" }
          }
        ]}
        subtitles={[
          { content: buildId, copiable: true },

          {
            label: t("label.time.lastModified"),
            content: dayjs(lastUpdate).format("YYYY-MM-DD HH:mm:ss z")
          },

          ...insertIf(state === "IN_USE" && !!deployments.length, {
            label: t("label.gameServerBuild.deployedOn"),
            content: deployments.map((d, i) => (
              <Fragment key={d.spaceId}>
                <Typography>
                  {spaces?.find((s) => s.spaceId === d.spaceId)?.spaceName}
                </Typography>
                {i < deployments.length - 1 && deployments.length > 1 && ", "}
              </Fragment>
            ))
          })
        ]}
        sections={[
          {
            title: t("label.gameServerBuild.codeChangelist"),
            content: codeChangeList || t("common.none")
          },
          {
            title: t("label.gameServerBuild.dataChangelist"),
            content: dataChangeList || t("common.none")
          },
          {
            title: t("state.preserved"),
            content: preserved ? t("common.yes") : t("common.no")
          }
        ]}
        actions={{
          ...(isAdmin &&
            (preserved
              ? {
                  unpreserve: {
                    handler: () => {
                      dispatch(setActiveOperation(unpreserveOperation))
                    }
                  }
                }
              : {
                  preserve: {
                    handler: () => {
                      dispatch(setActiveOperation(preserveOperation))
                    }
                  }
                })),
          deploy: {
            handler: () => {
              setOpenDeployModal(true)
            }
          }
        }}
      />
    </>
  )
}
