import { Chip, ChipProps } from "@mui/joy"

import React from "react"
import { useTranslation } from "react-i18next"

interface Props extends ChipProps {
  type: "game" | "space"
  initial?: boolean
}

export const EntityChip: React.FC<Props> = ({
  type,
  initial = false,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Chip
      {...props}
      color={type}
      sx={{
        ...props.sx,
        ...(initial && { p: 0, minWidth: "20px", textAlign: "center" })
      }}
    >
      {initial ? t(`item.${type}_initial`) : t(`item.${type}`)}
    </Chip>
  )
}
