import { Actions, SeasonCreateRequest } from "@liveops-portal/lib"
import { Button } from "@mui/joy"
import { Plus } from "iconoir-react"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useGetDataVersionsQuery } from "@/api/game-data"
import { useCreateSeasonMutation } from "@/api/season"
import { SeasonModal } from "@/components/season-modal/season-modal"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { selectEnv, selectGame } from "@/store/slices/context"

type FormData = SeasonCreateRequest

export const SeasonCreate: React.FC = () => {
  const dispatch = useAppDispatch()
  const methods = useForm<FormData>({
    mode: "all",
    defaultValues: { qdOperationReason: "Season created from LiveOps Portal" }
  })
  const { reset: resetForm } = methods
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const [createSeason, mutation] = useCreateSeasonMutation()
  const { isSuccess, isLoading: isLoadingCreate } = mutation

  const { isFetching, isError } = useGetDataVersionsQuery(
    { gameId, env },
    {
      skip: !gameId || !env,
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.map(({ dataVersion }) => dataVersion.toString())
      })
    }
  )

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm])

  const onSubmitHandler = (data: FormData) => createSeason({ ...data, gameId })

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [dispatch, isSuccess, onCloseHandler])

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        startDecorator={<Plus />}
        disabled={isFetching || isError}
      >
        {t("action.item", {
          action: "action.create",
          item: "item.season"
        })}
      </Button>

      <SeasonModal
        open={open}
        loading={isLoadingCreate}
        action={Actions.create}
        methods={methods}
        onSubmit={onSubmitHandler}
        onClose={onCloseHandler}
      />
    </>
  )
}
