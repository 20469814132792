import {
  GetDataVersionsQuery,
  DataVersion,
  GetDataTypesQuery,
  GameData,
  GetGameDataQuery,
  PreserveDataVersionMutation,
  UnpreserveDataVersionMutation
} from "@liveops-portal/lib"
import { api } from "@/api"

export const gameDataApi = api
  .enhanceEndpoints({
    addTagTypes: ["DataVersion"]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDataVersions: builder.query<DataVersion[], GetDataVersionsQuery>({
        query: ({ gameId }) => ({
          url: `/game/${gameId}/versions`
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ dataVersion }) => ({
                  type: "DataVersion" as const,
                  id: dataVersion
                })),

                { type: "DataVersion", id: "ALL" }
              ]
            : [{ type: "DataVersion", id: "ALL" }],
        transformResponse: (dataVersions: DataVersion[]) =>
          dataVersions?.filter(({ state }) => state === "READY_FOR_USE")
      }),
      preserveDataVersion: builder.mutation<void, PreserveDataVersionMutation>({
        query: ({ gameId, dataVersion, qdOperationReason }) => ({
          url: `/game/${gameId}/version/${dataVersion}/preserve`,
          method: "POST",
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { dataVersion }) => [
          { type: "DataVersion", id: dataVersion! }
        ]
      }),
      unpreserveDataVersion: builder.mutation<
        void,
        UnpreserveDataVersionMutation
      >({
        query: ({ gameId, dataVersion, qdOperationReason }) => ({
          url: `/game/${gameId}/version/${dataVersion}/unpreserve`,
          method: "POST",
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { dataVersion }) => [
          { type: "DataVersion", id: dataVersion! }
        ]
      }),
      getDataTypes: builder.query<string[], GetDataTypesQuery>({
        query: ({ gameId, dataVersion }) => ({
          url: `/game/${gameId}/version/${dataVersion}/datatypes`
        })
      }),
      getGameData: builder.query<GameData, GetGameDataQuery>({
        query: ({ gameId, dataVersion, dataType }) => ({
          url: `/game/${gameId}/version/${dataVersion}/datatype/${dataType}`
        })
      })
    }),
    overrideExisting: false
  })

export const {
  useGetDataVersionsQuery,
  usePreserveDataVersionMutation,
  useUnpreserveDataVersionMutation,
  useGetDataTypesQuery,
  useGetGameDataQuery
} = gameDataApi
