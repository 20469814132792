import { Method, ServiceName, ToolboxRequest } from "../typings"

export const mockToolboxRequestBody: ToolboxRequest = {
  serviceName: ServiceName.UNIMATRIX_XYQ_SERVICE,
  body: {},
  method: Method.GET,
  queryParams: {},
  spaceId: "spaceId",
  endpoint: "/endpoint"
}
