import { Notification } from "@liveops-portal/lib"
import {
  Drawer,
  DialogTitle,
  Sheet,
  DialogContent,
  Typography,
  Stack,
  Chip,
  IconButton,
  Button
} from "@mui/joy"
import dayjs from "dayjs"
import { Xmark } from "iconoir-react"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  NotificationCenterItem,
  useNotificationCenter
} from "react-toastify/addons/use-notification-center"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import {
  selectIsNotificationCenterOpen,
  toggleNotificationCenter
} from "@/store/slices/app"

export const NotificationCenter: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { notifications, clear } = useNotificationCenter({
    filter: ({ data }) => (data as Notification)?.exclude === false,
    sort: (l: NotificationCenterItem, r: NotificationCenterItem) =>
      r.createdAt - l.createdAt,
    data:
      localStorage.getItem("notifications") !== null
        ? JSON.parse(localStorage.getItem("notifications")!)
        : []
  })

  // State
  const isNotificationCenterOpen = useAppSelector(
    selectIsNotificationCenterOpen
  )
  const todayNotifications = notifications.filter(({ createdAt }) =>
    dayjs(createdAt).isToday()
  )
  const olderNotifications = notifications.filter(
    ({ createdAt }) => !dayjs(createdAt).isToday()
  )

  // Handlers
  const onCloseHandler = () => {
    dispatch(toggleNotificationCenter())
  }

  useEffect(() => {
    if (!notifications.length) return
    localStorage.setItem("notifications", JSON.stringify(notifications))
  }, [notifications])

  return (
    <Drawer
      open={isNotificationCenterOpen}
      onClose={onCloseHandler}
      anchor="right"
      variant="plain"
      slotProps={{
        content: {
          sx: {
            bgcolor: "transparent",
            p: 2,
            boxShadow: "none"
          }
        }
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: "md",
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          height: "100%",
          overflow: "auto"
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <DialogTitle level="title-lg">{t("item.notifications")}</DialogTitle>

          <Stack direction="row" alignItems="center">
            {notifications.length ? (
              <Button
                size="sm"
                variant="soft"
                onClick={() => {
                  clear()
                  localStorage.removeItem("notifications")
                }}
              >
                {t("action.clearAll")}
              </Button>
            ) : null}
            <IconButton
              size="sm"
              onClick={onCloseHandler}
              data-testid="notification-center-close-button"
            >
              <Xmark />
            </IconButton>
          </Stack>
        </Stack>

        <DialogContent sx={{ gap: 2 }}>
          {notifications.length ? (
            <>
              <Stack gap={2} data-testid="todayNotifications">
                <Typography level="title-sm">
                  {t("label.time.today")}
                </Typography>

                {todayNotifications.length ? (
                  todayNotifications.map(({ id, createdAt, data }) => {
                    const { message } = data as Notification

                    return (
                      <Stack
                        key={id}
                        direction="row"
                        alignItems="flex-start"
                        justifyContent="space-between"
                      >
                        <Typography level="body-sm">{message}</Typography>
                        <Chip>{dayjs(createdAt).format("HH:mm")}</Chip>
                      </Stack>
                    )
                  })
                ) : (
                  <Typography level="body-xs" textAlign="center">
                    {t("message.notifications.noneToday")}
                  </Typography>
                )}
              </Stack>

              <Stack gap={2} data-testid="olderNotifications">
                <Typography level="title-sm" mt={2}>
                  {t("label.time.older")}
                </Typography>

                {olderNotifications.length ? (
                  olderNotifications.map(({ id, createdAt, data }) => {
                    const { message } = data as Notification
                    return (
                      <Stack
                        key={id}
                        direction="row"
                        alignItems="flex-start"
                        justifyContent="space-between"
                      >
                        <Typography level="body-sm">{message}</Typography>
                        <Chip>{dayjs(createdAt).format("DD/MM/YY")}</Chip>
                      </Stack>
                    )
                  })
                ) : (
                  <Typography level="body-xs" textAlign="center">
                    {t("message.notifications.noneOlder")}
                  </Typography>
                )}
              </Stack>
            </>
          ) : (
            <Typography level="body-xs" textAlign="center">
              {t("message.notifications.none")}
            </Typography>
          )}
        </DialogContent>
      </Sheet>
    </Drawer>
  )
}
