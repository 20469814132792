import { ApiError, AuditLogsRequest, Audit } from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api/index"

export const auditApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAudits: builder.query<Audit[], AuditLogsRequest>({
      query: ({ duration }) => ({
        url: "audits",
        params: { duration }
      }),
      onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled.catch(({ error }) => {
          notify.error({
            message: i18next.t("api.getAudits.error"),
            error: error as ApiError
          })
        })
      }
    })
  }),
  overrideExisting: false
})

export const { useGetAuditsQuery } = auditApi
