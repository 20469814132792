import { CreateGameMutation } from "@liveops-portal/lib"
import { Button } from "@mui/joy"
import { Plus } from "iconoir-react"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useCreateGameMutation } from "@/api/game"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { Modal } from "@/components/modal/modal"
import { useAppDispatch } from "@/hooks/store"
import { useIsAdmin } from "@/hooks/useIsAdmin"

type FormData = CreateGameMutation

export const GameCreate: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isAdmin = useIsAdmin()

  const [open, setOpen] = useState<boolean>(false)

  const [createGame, mutation] = useCreateGameMutation()
  const { isSuccess, isLoading } = mutation

  const methods = useForm<FormData>({
    mode: "all",
    defaultValues: {
      gameId: "",
      name: "",
      qdOperationReason: "Game created from LiveOps Portal"
    }
  })

  const { reset: resetForm } = methods

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm])

  const onSubmitHandler = (data: FormData) => createGame(data)

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [dispatch, isSuccess, onCloseHandler])

  return (
    isAdmin && (
      <>
        <Button
          variant="outlined"
          onClick={() => setOpen(true)}
          startDecorator={<Plus />}
        >
          {t("action.item", {
            action: "action.create",
            item: "item.game"
          })}
        </Button>

        <Modal
          open={open}
          onClose={onCloseHandler}
          title={t("action.item", {
            action: "action.create",
            item: "item.game"
          })}
        >
          <Form
            methods={methods}
            loading={isLoading}
            submitLabel={t("action.create")}
            onSubmit={onSubmitHandler}
            sx={{ gap: 2 }}
            fieldsToTrim={["name"]}
          >
            <FormInput
              type="text"
              name="gameId"
              label={t("id.game")}
              rules={{}}
              helper={t("placeholder.gameId.empty")}
            />
            <FormInput
              type="text"
              name="name"
              label={t("modifier.name", { item: "item.game" })}
              rules={{
                required: true
              }}
            />
          </Form>
        </Modal>
      </>
    )
  )
}
