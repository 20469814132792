import { Healthcheck, Operation } from "@liveops-portal/lib"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  useToggleHealthcheckMutation,
  useDeleteHealthcheckMutation
} from "@/api/healthcheck"
import { CardItem } from "@/components/card-item/card-item"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { SwitchOnOff } from "@/components/switch-on-off/switch-on-off"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { selectActiveOperation, setActiveOperation } from "@/store/slices/audit"
import { selectEnv } from "@/store/slices/context"

export const HealthcheckCard: React.FC<Healthcheck> = ({
  enabled,
  lastStatusCode,
  msTeamsNotifierWebhookId,
  serviceName,
  url
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const env = useAppSelector(selectEnv)
  const activeOperation = useAppSelector(selectActiveOperation)

  const [toggleHealthcheck, toggleHealthcheckMeta] =
    useToggleHealthcheckMutation()
  const [deleteHealthcheck, deleteHealthcheckMeta] =
    useDeleteHealthcheckMutation()

  const toggleOperation: Operation = [serviceName, "toggle"]
  const deleteOperation: Operation = [serviceName, "delete"]

  const isToggleOperationActive = isOperationActive(
    activeOperation,
    toggleOperation
  )
  const isDeleteOperationActive = isOperationActive(
    activeOperation,
    deleteOperation
  )

  const isLoadingToggle =
    isToggleOperationActive || toggleHealthcheckMeta.isLoading
  const isLoadingDelete =
    isDeleteOperationActive || deleteHealthcheckMeta.isLoading

  const onSubmitHandler = () => {
    if (isToggleOperationActive)
      toggleHealthcheck({
        serviceName,
        enabled,
        env
      })

    if (isDeleteOperationActive)
      deleteHealthcheck({
        serviceName
      })
  }

  return (
    <>
      <OperationReason
        open={isToggleOperationActive || isDeleteOperationActive}
        onSubmit={onSubmitHandler}
        autofocus
        closeable
      />

      <CardItem
        data-testid="healthcheck"
        title={{
          content: serviceName
        }}
        active={isLoadingDelete}
        chips={[
          {
            content: !lastStatusCode
              ? t("state.operational")
              : t("state.failing"),
            color: !lastStatusCode ? "success" : "warning"
          }
        ]}
        subtitles={[
          { content: url },
          {
            content: msTeamsNotifierWebhookId.toString(),
            label: t("label.healthcheck.msTeamsNotifierWebhookId")
          }
        ]}
        sections={[
          {
            content: (
              <SwitchOnOff
                checked={enabled}
                loading={isLoadingToggle}
                onClick={() => {
                  dispatch(setActiveOperation(toggleOperation))
                }}
              />
            )
          }
        ]}
        actions={{
          delete: {
            handler: () => {
              dispatch(setActiveOperation(deleteOperation))
            }
          }
        }}
      />
    </>
  )
}
