import {
  ApplyPresetMutation,
  ByPlayerIdQuery,
  BySpaceIdQuery,
  CurrencyMutation,
  PlayerProfile,
  Preset
} from "@liveops-portal/lib"
import { api } from "@/api"

export const playerApi = api
  .enhanceEndpoints({
    addTagTypes: ["Player", "Preset"]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPlayer: builder.query<PlayerProfile, BySpaceIdQuery & ByPlayerIdQuery>(
        {
          query: ({ spaceId, accountId, type }) => ({
            url: `spaces/${spaceId}/players/${accountId}`,
            params: { type }
          }),
          providesTags: (result) =>
            result ? [{ type: "Player", id: result.accountId }] : []
        }
      ),
      creditCurrency: builder.mutation<void, CurrencyMutation>({
        query: ({ spaceId, accountId, currencyCode, amount }) => ({
          url: `spaces/${spaceId}/players/${accountId}/currencies/${currencyCode}/credit/${amount}`,
          method: "POST"
        }),
        invalidatesTags: (result, error, { accountId }) =>
          !error ? [{ type: "Player", id: accountId }] : []
      }),
      debitCurrency: builder.mutation<void, CurrencyMutation>({
        query: ({ spaceId, accountId, currencyCode, amount }) => ({
          url: `spaces/${spaceId}/players/${accountId}/currencies/${currencyCode}/debit/${amount}`,
          method: "POST"
        }),
        invalidatesTags: (result, error, { accountId: id }) =>
          !error ? [{ type: "Player", id }] : []
      }),
      getPresets: builder.query<Preset[], BySpaceIdQuery>({
        query: ({ spaceId }) => ({
          url: `spaces/${spaceId}/players/presets`
        }),
        providesTags: (result) =>
          result?.length
            ? [
                ...result.map(({ presetId }) => ({
                  type: "Preset" as const,
                  id: presetId
                })),
                "Preset"
              ]
            : []
      }),
      applyPlayerPreset: builder.mutation<void, ApplyPresetMutation>({
        query: ({ spaceId, accountId, presetId }) => ({
          url: `spaces/${spaceId}/players/${accountId}/presets/${presetId}`,
          method: "POST"
        }),
        invalidatesTags: (result, error, { accountId: id }) =>
          !error ? [{ type: "Player", id }] : []
      })
    }),
    overrideExisting: false
  })

export const {
  useApplyPlayerPresetMutation,
  useCreditCurrencyMutation,
  useDebitCurrencyMutation,
  useGetPlayerQuery,
  useGetPresetsQuery
} = playerApi
