import { Challenge, SeasonComponentItem } from "@liveops-portal/lib"
import { Stack, Typography } from "@mui/joy"
import React from "react"
import { useTranslation } from "react-i18next"
import { ChallengeCard } from "@/components/challenge-card/challenge-card"
import { ChallengeCreate } from "@/components/challenge-create/challenge-create"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { usePageActions } from "@/hooks/usePageActions"
import {
  useGetSeasonComponentItemsQuery,
  useGetSeasonsQuery
} from "@/store/api/season"
import { selectEnv, selectGame, selectSeason } from "@/store/slices/context"

/**
 * Component representing the Season Designer Challenges page.
 */
export const SeasonDesignerChallengesPage: React.FC = () => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const seasonId = useAppSelector(selectSeason)

  const {
    data: activeSeason,
    isUninitialized: isUninitializedSeasons,
    isFetching: isFetchingSeasons
  } = useGetSeasonsQuery(
    { gameId, env },
    {
      skip: !gameId || !env,
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.find((season) => season.seasonId === seasonId)
      })
    }
  )

  const {
    data: items,
    isUninitialized: isUninitializedItems,
    isFetching: isFetchingItems
  } = useGetSeasonComponentItemsQuery(
    { gameId, seasonId, env, componentType: "challenge" },
    { skip: !gameId || !seasonId || !env }
  )

  const isFetching = isFetchingSeasons || isFetchingItems
  const isUninitialized = isUninitializedSeasons || isUninitializedItems

  usePageActions([<ChallengeCreate key="challenge-create" />])

  return isUninitialized ? (
    <Typography>
      {t("message.uninitialized.parent", {
        parent: "item.season",
        item: "item.challenges"
      })}
    </Typography>
  ) : (
    <>
      <Spinner loading={isFetching} />

      {items?.length ? (
        <Stack sx={{ gap: 2 }}>
          {(items as SeasonComponentItem<Challenge>[]).map(({ itemId }) => (
            <ChallengeCard key={itemId} itemId={itemId} />
          ))}
        </Stack>
      ) : (
        <Typography>
          {t("message.noItems.namedParent", {
            item: "item.challenges",
            parent: "item.season",
            parentName: activeSeason?.seasonName
          })}
        </Typography>
      )}
    </>
  )
}
