import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import { enUS } from "@/i18n/enUS"
import { zhCN } from "@/i18n/zhCN"

i18next.use(initReactI18next).init({
  resources: {
    "en-US": enUS,
    "zh-CN": zhCN
  },
  lng: "en-US",
  fallbackLng: "en-US",
  interpolation: {
    skipOnVariables: false
  }
})

i18next.services.formatter?.add("lower", (value, lng) =>
  lng === "en-US" ? value.toLowerCase() : value
)

export * from "./enUS" // English
export * from "./zhCN" // Chinese (Simplified)
export default i18next
