import {
  AllGameServerBuildsQuery,
  ApiError,
  GameServerBuild,
  sortByKey
} from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const gameServerBuildApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGameServerBuilds: builder.query<
      GameServerBuild[],
      AllGameServerBuildsQuery
    >({
      query: ({ gameId }) => ({
        url: "game-server-builds",
        params: { gameId }
      }),
      onQueryStarted({ gameId }, { queryFulfilled }) {
        queryFulfilled.catch(({ error }) => {
          notify.error({
            message: i18next.t("api.getGameServerBuilds.error", { gameId }),
            error: error as ApiError
          })
        })
      },
      transformResponse: (builds: GameServerBuild[]) =>
        sortByKey(builds, "lastUpdate", "desc")
    })
  }),
  overrideExisting: false
})

export const { useGetGameServerBuildsQuery } = gameServerBuildApi
