import {
  formatJsonToString,
  HealthcheckTriggerRequest
} from "@liveops-portal/lib"
import { Button, Divider, Stack, Typography } from "@mui/joy"
import { PlaySolid } from "iconoir-react"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { CodeEditor } from "@/components/code-editor/code-editor"
import { Form } from "@/components/form/form"
import { FormMultiSelect } from "@/components/form-multi-select/form-multi-select"
import { FormSwitch } from "@/components/form-switch/form-switch"
import { Modal } from "@/components/modal/modal"
import { useAppSelector } from "@/hooks/store"
import {
  useGetHealthchecksQuery,
  useTriggerHealthchecksMutation
} from "@/store/api/healthcheck"
import { selectEnv } from "@/store/slices/context"

interface FormData extends HealthcheckTriggerRequest {}

export const HealthcheckTrigger: React.FC = () => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)
  const { data: healthchecks } = useGetHealthchecksQuery(
    { env },
    { skip: !env }
  )
  const methods = useForm<FormData>({
    mode: "all",
    defaultValues: {
      isDeep: false,
      serviceNames: [],
      saveResult: true
    }
  })
  const {
    reset: resetForm,
    formState: { isSubmitting, isSubmitted, isValidating, isValid }
  } = methods
  const [triggerHealthchecks, mutation] = useTriggerHealthchecksMutation()
  const { isSuccess, isLoading: isLoadingMutation, data: results } = mutation
  const [open, setOpen] = useState<boolean>(false)

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm])

  const onSubmitHandler = (data: FormData) => triggerHealthchecks(data)

  useEffect(() => {
    if (isSuccess) {
      resetForm()
    }
  }, [isSuccess, resetForm])

  const action = "action.trigger"
  const item = "item.healthchecks"

  return (
    !!healthchecks && (
      <>
        <Button
          variant="outlined"
          color="neutral"
          startDecorator={<PlaySolid />}
          onClick={() => setOpen(true)}
        >
          {t("action.trigger")}
        </Button>

        <Modal
          open={open}
          onClose={onCloseHandler}
          title={t("action.item", {
            action,
            item
          })}
        >
          <Stack direction="row" gap={2}>
            <Form
              methods={methods}
              disabled={
                isLoadingMutation ||
                isSubmitting ||
                isSubmitted ||
                isValidating ||
                !isValid
              }
              loading={isLoadingMutation}
              submitLabel={t("action.trigger")}
              onSubmit={onSubmitHandler}
              gap={2}
              direction="column"
              sx={{ width: 350 }}
            >
              <FormSwitch
                name="isDeep"
                label={t("label.healthcheck.deepCheck")}
                orientation="horizontal"
              />
              <FormMultiSelect
                name="serviceNames"
                label={t("item.services")}
                options={healthchecks.map(({ serviceName }) => ({
                  name: serviceName,
                  label: serviceName
                }))}
                rules={{
                  validate: (value) =>
                    value.length > 0 || t("error.healthcheck.serviceNames")
                }}
              />
            </Form>
            <Divider orientation="vertical" />
            <Stack gap={1}>
              <Typography fontSize="sm" fontWeight="md">
                {t("label.response")}
              </Typography>
              <CodeEditor
                name="results"
                value={results?.length ? formatJsonToString(results) : "[]"}
                sx={{ width: 500, flexGrow: 1 }}
              />
            </Stack>
          </Stack>
        </Modal>
      </>
    )
  )
}
