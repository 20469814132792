import { Notification, Severity } from "@liveops-portal/lib"
import { nanoid } from "@reduxjs/toolkit"
import { toast, Id, ToastOptions } from "react-toastify"
import { NotificationItem } from "@/components/notification-item/notification-item"

class Notify {
  private format(value: Notification | string): Notification {
    return typeof value === "string"
      ? { message: value, exclude: false }
      : { ...value }
  }

  notify(notification: Notification, extraOptions?: ToastOptions): Id {
    return toast(NotificationItem, {
      ...extraOptions,
      toastId: nanoid(),
      type: notification.severity,
      data: notification
    })
  }

  success(
    notification: Notification | string,
    extraOptions?: ToastOptions
  ): Id {
    return this.notify(
      {
        ...this.format(notification),
        severity: Severity.Success
      },
      extraOptions
    )
  }

  error(notification: Notification | string, extraOptions?: ToastOptions): Id {
    return this.notify(
      {
        ...this.format(notification),
        severity: Severity.Error
      },
      extraOptions
    )
  }

  warn(notification: Notification | string, extraOptions?: ToastOptions): Id {
    return this.notify(
      {
        ...this.format(notification),
        severity: Severity.Warning
      },
      extraOptions
    )
  }

  info(notification: Notification | string, extraOptions?: ToastOptions): Id {
    return this.notify(
      {
        ...this.format(notification),
        severity: Severity.Info
      },
      extraOptions
    )
  }
}

const notifyInstance = new Notify()

const notify = (
  notification: Notification | string,
  extraOptions?: ToastOptions
): Id => notifyInstance.info(notification, extraOptions)

notify.error = notifyInstance.error.bind(notifyInstance)
notify.success = notifyInstance.success.bind(notifyInstance)
notify.warn = notifyInstance.warn.bind(notifyInstance)
notify.warning = notifyInstance.warn.bind(notifyInstance)
notify.info = notifyInstance.info.bind(notifyInstance)

export default notify
