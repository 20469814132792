import {
  CreateActiveDeploymentMutation,
  Deployment,
  GetActiveDeploymentQuery
} from "@liveops-portal/lib"
import { api } from "@/api"

export const deploymentApi = api
  .enhanceEndpoints({ addTagTypes: ["Deployment"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getActiveDeployment: builder.query<Deployment, GetActiveDeploymentQuery>({
        providesTags: ["Deployment"],
        query: ({ gameId, spaceId }) => ({
          url: "deployments/active",
          method: "GET",
          params: { gameId, spaceId }
        })
      }),
      createActiveDeployment: builder.mutation<
        Deployment,
        CreateActiveDeploymentMutation
      >({
        query: ({
          gameId,
          spaceId,
          bundleId,
          bundleVersion,
          qdOperationReason
        }) => ({
          url: "deployments/active",
          method: "POST",
          body: { bundleId, bundleVersion },
          params: { gameId, spaceId },
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: ["Deployment"]
      })
    })
  })

export const {
  useGetActiveDeploymentQuery,
  useCreateActiveDeploymentMutation
} = deploymentApi
