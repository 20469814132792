import { ApiError, ToolboxRequest } from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const toolboxApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendRequest: builder.mutation<unknown, ToolboxRequest>({
      query: (requestParams) => ({
        url: "toolbox",
        body: requestParams,
        method: "post"
      }),
      onQueryStarted(requestParams, { queryFulfilled }) {
        queryFulfilled.catch(({ error }) => {
          notify.error({
            message: i18next.t("api.sendRequest.error"),
            error: error as ApiError
          })
        })
      }
    })
  })
})

export const { useSendRequestMutation } = toolboxApi
