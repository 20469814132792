import { UnfoldMore } from "@mui/icons-material"
import {
  Stack,
  Checkbox,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Chip,
  CheckboxProps
} from "@mui/joy"

interface Props<T> {
  accessor: keyof T
  label: string
  options: { label?: string; value: string }[]
  selected: string[] | string
  onChange: CheckboxProps["onChange"]
}

export const FiltersSelect = <T,>({
  accessor,
  label,
  options,
  selected = [],
  onChange
}: Props<T>) => {
  return (
    <Dropdown>
      <MenuButton
        endDecorator={
          <Stack direction="row" gap={0.5}>
            {!!selected.length && (
              <Chip
                size="sm"
                variant="solid"
                sx={{ px: "8px" }}
                data-testid="selected-indicator"
              >
                {typeof selected === "string" ? "1" : selected.length}
              </Chip>
            )}
            <UnfoldMore />
          </Stack>
        }
        sx={{ fontWeight: 400, fontSize: "md" }}
      >
        {label}
      </MenuButton>
      <Menu
        placement="bottom-start"
        sx={{ minWidth: 130, maxHeight: 240, overflow: "auto" }}
      >
        {options.map((option) => {
          const id = accessor as string
          const checked =
            typeof selected === "string"
              ? selected === option.value
              : selected.some((v) => v === option.value)
          return (
            <MenuItem key={option.value}>
              <Checkbox
                overlay
                size="sm"
                name={id}
                id={option.value}
                label={option.label || option.value}
                value={option.value}
                checked={checked}
                onChange={onChange}
              />
            </MenuItem>
          )
        })}
      </Menu>
    </Dropdown>
  )
}
