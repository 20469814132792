import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import en from "@/i18n/en.json"
import zh from "@/i18n/zh.json"

i18next.use(initReactI18next).init({
  resources: {
    en,
    zh
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    skipOnVariables: false
  }
})

i18next.services.formatter?.add("lower", (value, lng) =>
  lng?.includes("en") ? value.toLowerCase() : value
)

i18next.services.formatter?.add("capitalize", (value, lng) =>
  lng?.includes("en") ? value.charAt(0).toUpperCase() + value.slice(1) : value
)

export default i18next
