import { HelpCircle, OpenBook, Mail } from "iconoir-react"
import React from "react"
import { useTranslation } from "react-i18next"
import { generatePath } from "react-router-dom"
import { HeaderDropdown } from "@/components/header-dropdown/header-dropdown"
import { useAppSelector } from "@/hooks/store"
import { selectEnv } from "@/store/slices/context"

export const HelpDropdown: React.FC = () => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)

  const props = {
    icon: <HelpCircle />,
    label: "Help",
    items: [
      {
        content: t("app.glossary"),
        icon: <OpenBook />,
        to: generatePath(":env/glossary", { env })
      },
      {
        content: t("app.contact"),
        icon: <Mail />,
        to: "mailto:liveops_portal_support@quanticdream.com"
      }
    ]
  }
  return <HeaderDropdown {...props} />
}
