import { useMemo } from "react"
import { useGetBundlesQuery } from "@/api/bundle"
import { useGetSeasonsQuery } from "@/api/season"
import { useAppSelector } from "@/hooks/store"
import { selectEnv, selectGame } from "@/store/slices/context"

/**
 * Returns multiple values related to bundles and seasons.
 */
export const useBundles = () => {
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)

  const { data: seasons, isFetching: isFetchingSeasons } = useGetSeasonsQuery(
    { gameId, env },
    { skip: !gameId || !env }
  )

  const { data: bundles, isFetching: isFetchingBundles } = useGetBundlesQuery(
    { gameId },
    { skip: !gameId || !env }
  )

  const isFetching = isFetchingBundles || isFetchingSeasons

  // Seasons not in a bundle (standalone)
  const standaloneSeasons = useMemo(
    () =>
      seasons?.filter(
        ({ seasonId }) =>
          !bundles?.find(({ bundleContent }) =>
            bundleContent.cookedSeasonIds.find(
              (cookedSeasonId) => cookedSeasonId.seasonId === seasonId
            )
          )
      ),
    [bundles, seasons]
  )

  // Filter drafts out of seasons
  const allPublishedSeasons = useMemo(
    () =>
      seasons?.filter(
        ({ latestPublishedRevision }) => latestPublishedRevision !== -1
      ),
    [seasons]
  )

  return {
    bundles,
    allPublishedSeasons,
    standaloneSeasons,
    isFetching
  }
}
