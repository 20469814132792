import {
  Operation,
  FeatureSwitchValueTypes,
  FeatureSwitch,
  insertIf
} from "@liveops-portal/lib"
import { Grid, Select, Typography, Option, Button, Stack } from "@mui/joy"
import { WarningTriangle } from "iconoir-react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { CardItem } from "@/components/card-item/card-item"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import {
  useDeleteOverrideValueMutation,
  useSetOverrideValueMutation
} from "@/store/api/feature-switch"
import { selectActiveOperation, setActiveOperation } from "@/store/slices/audit"
import { selectSpace } from "@/store/slices/context"

interface Props extends FeatureSwitch {}

export const FeatureSwitchCard: React.FC<Props> = ({
  switchName,
  switchDescription,
  switchType,
  switchValue,
  defaultValue,
  overrideValue
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const spaceId = useAppSelector(selectSpace)
  const activeOperation = useAppSelector(selectActiveOperation)

  const setOperation: Operation = [switchName, "set"]
  const deleteOperation: Operation = [switchName, "delete"]
  const isSetOperationActive = isOperationActive(activeOperation, setOperation)
  const isDeleteOperationActive = isOperationActive(
    activeOperation,
    deleteOperation
  )

  const [overrideSelectValue, setOverrideSelectValue] = useState<
    boolean | null
  >(overrideValue)

  const [setOverrideValue, setOverrideValueMeta] = useSetOverrideValueMutation()
  const [deleteOverrideValue, deleteOverrideValueMeta] =
    useDeleteOverrideValueMutation()

  const isLoadingSet = isSetOperationActive || setOverrideValueMeta.isLoading
  const isLoadingDelete =
    isDeleteOperationActive || deleteOverrideValueMeta.isLoading

  const onSubmitHandler = () => {
    if (isSetOperationActive && overrideSelectValue !== null) {
      setOverrideValue({
        spaceId,
        switchName,
        switchValue: overrideSelectValue
      })
    }

    if (isDeleteOperationActive) {
      deleteOverrideValue({
        spaceId,
        switchName
      })
    }
  }

  const isLoadingActions = isLoadingSet || isLoadingDelete

  return (
    <Grid md={6} sx={{ display: "flex" }}>
      <OperationReason
        onSubmit={onSubmitHandler}
        open={isLoadingActions}
        autofocus
        closeable
      />
      <CardItem
        chips={[
          ...insertIf(overrideValue !== null, {
            content: t(FeatureSwitchValueTypes.OVERRIDDEN),
            color: "danger" as const
          })
        ]}
        title={{ content: switchName }}
        active={isLoadingActions}
        subtitles={[
          {
            content: switchDescription,
            props: { sx: { fontStyle: "italic", fontWeight: "400" } }
          },
          ...insertIf(switchType === "bool", {
            label: t("modifier.default", { item: "item.value" }),
            content: (
              <Typography
                color={defaultValue ? "success" : "danger"}
                data-testid="defaultValue"
              >
                {defaultValue.toString()}
              </Typography>
            )
          }),
          ...insertIf(switchType === "bool", {
            label: t("modifier.current", { item: "item.value" }),
            content: (
              <Typography
                color={switchValue ? "success" : "danger"}
                data-testid="switchValue"
              >
                {switchValue.toString()}
              </Typography>
            )
          }),
          ...insertIf(switchType !== "bool", {
            content: t("message.complexFeatureSwitches"),
            props: {
              level: "body-xs" as const,
              color: "warning" as const,
              startDecorator: <WarningTriangle />,
              sx: { mt: 1, alignItems: "flex-start" }
            }
          })
        ]}
        sections={[
          ...insertIf(switchType === "bool", {
            title: t("modifier.override", {
              item: "item.value"
            }),
            content: (
              <Stack sx={{ mt: 1, gap: 1, minWidth: 165 }}>
                <Select
                  disabled={isLoadingActions}
                  slotProps={{
                    listbox: {
                      variant: "outlined",
                      color: "neutral",
                      sx: { p: 0 }
                    }
                  }}
                  onChange={(_, value) => {
                    dispatch(setActiveOperation(setOperation))
                    setOverrideSelectValue(value)
                  }}
                  aria-label="Override"
                  name="overrideValue"
                  value={isLoadingSet ? overrideSelectValue : overrideValue}
                  placeholder={t("placeholder.option")}
                >
                  <Option value={true} key="true">
                    true
                  </Option>
                  <Option value={false} key="false">
                    false
                  </Option>
                </Select>
                {overrideValue !== null && (
                  <Button
                    disabled={isLoadingActions}
                    color="danger"
                    size="sm"
                    onClick={() => {
                      dispatch(setActiveOperation(deleteOperation))
                      setOverrideSelectValue(null)
                    }}
                  >
                    {t("action.resetToDefault")}
                  </Button>
                )}
              </Stack>
            )
          })
        ]}
      />
    </Grid>
  )
}
