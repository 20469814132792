import { Page } from "@liveops-portal/lib"
import React, { createContext, useCallback, useEffect, useRef } from "react"

export const PageContext: React.Context<Page> = createContext({} as Page)

export const usePageActions = (actions: React.ReactElement[]) => {
  const init = useRef(false)
  const {
    pageActions: [, setPageActions]
  } = React.useContext(PageContext)

  const updatePageActions = useCallback(
    (updatedPageActions: React.ReactElement[]) => {
      setPageActions(updatedPageActions)
    },
    [setPageActions]
  )

  useEffect(() => {
    if (!init.current) {
      updatePageActions(actions)
      init.current = true
    }
  }, [actions, updatePageActions])

  useEffect(() => {
    return () => {
      if (init.current) {
        updatePageActions([])
        init.current = false
      }
    }
  }, [updatePageActions])
}
