import { Button, CircularProgress, Stack, StackProps } from "@mui/joy"
import React, { BaseSyntheticEvent, FormEvent, ReactElement } from "react"
import {
  FieldValues,
  FormProvider,
  Path,
  SubmitHandler,
  UseFormReturn
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormInput } from "@/components/form-input/form-input"

interface Props<T extends FieldValues> extends Omit<StackProps, "onSubmit"> {
  methods: UseFormReturn<T>
  onSubmit: SubmitHandler<T>
  submitLabel: string
  auditable?: boolean
  disabled?: boolean
  loading?: boolean
  fieldsToTrim?: Path<T>[]
}

/**
 * Renders a form bound to React Hook Form.
 * @param methods
 * @param onSubmit
 * @param submitLabel
 * @param auditable
 * @param disabled
 * @param loading
 * @param fieldsToTrim Arrays of react-hook-form Path that should be trimmed before submitting.
 * @param children
 */
export const Form = <T extends FieldValues>({
  methods,
  onSubmit,
  submitLabel,
  auditable,
  disabled,
  loading,
  fieldsToTrim,
  children,
  sx,
  ...props
}: Props<T>) => {
  const { t } = useTranslation()
  const { handleSubmit, getValues, setValue } = methods
  const { isValid } = methods.formState

  // Lets us manipulate data on the entire form BEFORE submitting it.
  // For example, helpful for trimming values without hindering the user experience.
  const onSubmitHandler = (
    event: FormEvent,
    onSubmit: (e?: BaseSyntheticEvent) => Promise<void>
  ) => {
    event.preventDefault()

    // Trim fields
    if (fieldsToTrim) {
      for (const field of fieldsToTrim) {
        const fieldValue = getValues(field)

        if (fieldValue) {
          setValue(field, fieldValue.trim())
        }
      }
    }

    return onSubmit()
  }

  return (
    <Stack
      component="form"
      data-form-type="other"
      onSubmit={(event: FormEvent<HTMLFormElement>) =>
        onSubmitHandler(event, handleSubmit(onSubmit))
      }
      sx={{ ...sx, gap: 2, overflow: "hidden" }}
      {...props}
    >
      <FormProvider {...methods}>
        {React.Children.map(
          children as ReactElement[],
          (child: ReactElement): JSX.Element => child
        )}
        {auditable && (
          <FormInput
            type="text"
            name="qdOperationReason"
            label={t("label.reason")}
            placeholder={t("placeholder.reason")}
            rules={{ required: true }}
          />
        )}
      </FormProvider>

      <Button
        size="sm"
        type="submit"
        loading={loading}
        loadingIndicator={<CircularProgress data-testid="loading" />}
        disabled={!isValid || disabled}
      >
        {submitLabel}
      </Button>
    </Stack>
  )
}
