import {
  Home,
  CodeBrackets,
  Megaphone,
  CubeHole,
  Tournament,
  PharmacyCrossCircle,
  Wrench,
  FireFlame,
  Trophy,
  Community,
  Server,
  Gamepad
} from "iconoir-react"
import { RouteObject } from "react-router-dom"
import { ActiveDeploymentPage } from "@/pages/active-deployment-page/active-deployment-page"
import { ApplicationsPage } from "@/pages/applications-page/applications-page"
import { AuditPage } from "@/pages/audit-page/audit-page"
import { ConfigPage } from "@/pages/config-page/config-page"
import { FeatureSwitchesPage } from "@/pages/feature-switches-page/feature-switches-page"
import { GameServerBuildsPage } from "@/pages/game-server-builds-page/game-server-builds-page"
import { GlossaryPage } from "@/pages/glossary-page/glossary-page"
import { HealthchecksPage } from "@/pages/healthchecks-page/healthchecks-page"
import { MarkdownPage } from "@/pages/markdown-page/markdown-page"
import { OverviewPage } from "@/pages/overview-page/overview-page"
import { PlayerFinderPage } from "@/pages/player-finder-page/player-finder-page"
import { SeasonDesignerBattlepassPage } from "@/pages/season-designer-battlepass-page/season-designer-battlepass-page"
import { SeasonDesignerChallengesPage } from "@/pages/season-designer-challenges-page/season-designer-challenges-page"
import { SeasonDesignerDataViewerPage } from "@/pages/season-designer-data-viewer-page/season-designer-data-viewer-page"
import { SeasonDesignerOverviewPage } from "@/pages/season-designer-overview-page/season-designer-overview-page"
import { ToolboxPage } from "@/pages/toolbox-page/toolbox.page"

export const ROUTES: RouteObject[] = [
  {
    id: "general",
    handle: {
      name: "app.general"
    },
    children: [
      {
        id: "overview",
        path: ":env/overview/:gameId?/:spaceId?",
        Component: OverviewPage,
        handle: {
          name: "app.overview",
          icon: Home
        }
      },
      {
        id: "glossary",
        path: ":env/glossary",
        Component: GlossaryPage,
        handle: {
          hideSidebar: true,
          hideTitle: true
        }
      },
      {
        id: "markdown",
        path: ":env/markdown",
        Component: MarkdownPage,
        handle: {
          hideSidebar: true,
          hideTitle: true
        }
      },
      {
        id: "healthchecks",
        path: ":env/healthchecks",
        Component: HealthchecksPage,
        handle: {
          name: "item.healthchecks",
          icon: Server
        }
      },
      {
        id: "toolbox",
        path: ":env/toolbox/:gameId?/:spaceId?",
        Component: ToolboxPage,
        handle: {
          name: "app.toolbox",
          icon: CodeBrackets
        }
      },
      {
        id: "audit",
        path: ":env/audit",
        Component: AuditPage,
        handle: {
          name: "app.audit",
          icon: Megaphone
        }
      }
    ]
  },
  {
    id: "game",
    handle: {
      name: "item.game"
    },
    children: [
      {
        id: "game-server-builds",
        path: ":env/game-server-builds/:gameId?",
        Component: GameServerBuildsPage,
        handle: {
          name: "item.gameServerBuilds",
          icon: CubeHole
        }
      },
      {
        id: "applications",
        path: ":env/applications/:gameId?",
        Component: ApplicationsPage,
        handle: {
          name: "item.applications",
          icon: Gamepad
        }
      },
      {
        id: "season-designer",
        path: ":env/season-designer/:gameId?",
        handle: {
          name: "app.seasonDesigner",
          icon: Tournament
        },
        children: [
          {
            id: "season-designer-overview",
            index: true,
            Component: SeasonDesignerOverviewPage,
            handle: {
              name: "app.overview",
              title: "app.seasonDesigner"
            }
          },
          {
            id: "season-designer-timeline",
            path: "timeline/:seasonId?",
            handle: {
              name: "app.timeline"
            }
          },
          {
            id: "season-designer-data-viewer",
            path: "data-viewer/:seasonId?",
            Component: SeasonDesignerDataViewerPage,
            handle: {
              name: "app.dataViewer"
            }
          },
          {
            id: "season-designer-battlepasses",
            path: "battlepasses/:seasonId?",
            Component: SeasonDesignerBattlepassPage,
            handle: {
              name: "item.battlepasses"
            }
          },
          {
            id: "season-designer-challenges",
            path: "challenges/:seasonId?",
            Component: SeasonDesignerChallengesPage,
            handle: {
              name: "item.challenges"
            }
          },
          {
            id: "season-designer-community-votes",
            path: "community-votes/:seasonId?",
            handle: {
              name: "item.communityVotes"
            }
          },
          {
            id: "season-designer-shop",
            path: "shop/:seasonId?",
            handle: {
              name: "app.shop"
            }
          },
          {
            id: "season-designer-news",
            path: "news/:seasonId?",
            handle: {
              name: "app.news"
            }
          }
        ]
      }
    ]
  },
  {
    id: "space",
    handle: {
      name: "item.space"
    },
    children: [
      {
        id: "health",
        handle: {
          name: "app.health",
          icon: PharmacyCrossCircle
        },
        children: [
          {
            id: "health-news",
            path: ":env/news/:gameId?/:spaceId?",
            handle: {
              name: "item.news"
            }
          },
          {
            id: "feature-switches",
            path: ":env/feature-switches/:gameId?/:spaceId?",
            Component: FeatureSwitchesPage,
            handle: {
              name: "item.featureSwitches"
            }
          },
          {
            id: "remote-logs",
            path: ":env/remote-logs/:gameId?/:spaceId?",
            handle: {
              name: "item.remoteLogs"
            }
          }
        ]
      },
      {
        id: "config",
        path: ":env/config/:gameId?/:spaceId?",
        Component: ConfigPage,
        handle: {
          name: "item.configurations",
          icon: Wrench
        }
      },
      {
        id: "liveops",
        handle: {
          name: "app.liveops",
          icon: FireFlame
        },
        children: [
          {
            id: "playlists",
            path: ":env/playlists/:gameId?/:spaceId?",
            handle: {
              name: "item.playlists"
            }
          },
          {
            id: "live-balancing",
            path: ":env/live-balancing/:gameId?/:spaceId?",
            handle: {
              name: "app.liveBalancing"
            }
          },
          {
            id: "telemetry",
            path: ":env/telemetry/:gameId?/:spaceId?",
            handle: {
              name: "app.telemetry"
            }
          }
        ]
      },
      {
        id: "seasons",
        path: ":env/seasons/:gameId?/:spaceId?",
        handle: {
          name: "item.seasons",
          icon: Trophy
        },
        children: [
          {
            id: "active-deployment",
            index: true,
            Component: ActiveDeploymentPage,
            handle: {
              name: "app.activeDeployment"
            }
          }
        ]
      },
      {
        id: "players",
        handle: {
          name: "item.players",
          icon: Community
        },
        children: [
          {
            id: "players-overview",
            index: true,
            Component: PlayerFinderPage,
            path: ":env/players/:gameId?/:spaceId?",
            handle: {
              name: "app.playerFinder"
            }
          },
          {
            id: "mass-grant",
            path: ":env/mass-grant/:gameId?/:spaceId?",
            handle: {
              name: "app.massGrant"
            }
          },
          {
            id: "player-reports",
            path: ":env/player-reports/:gameId?/:spaceId?",
            handle: {
              name: "app.playerReports"
            }
          }
        ]
      }
    ]
  }
]
