import {
  Card,
  Typography,
  Divider,
  ColorPaletteProp,
  Stack,
  Chip,
  StackProps,
  CardProps,
  CardContent,
  ChipProps,
  TypographyProps
} from "@mui/joy"
import React, { HTMLProps, MouseEventHandler, ReactNode } from "react"
import { CardActions } from "@/components/card-actions/card-actions"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"

export interface CardItemProps extends Omit<CardProps, "title"> {
  active?: boolean
  actions?: Partial<
    Record<
      "publish" | "update" | "delete",
      { handler: MouseEventHandler; disabled?: boolean }
    >
  >
  title: {
    content: string | ReactNode
    copiable?: boolean
    props?: TypographyProps
  }
  chips?: {
    content: string
    color?: ColorPaletteProp
    props?: ChipProps & HTMLProps<HTMLSpanElement>
  }[]
  subtitles?: {
    label?: string
    content: string | ReactNode
    color?: ColorPaletteProp
    copiable?: boolean
    props?: TypographyProps
  }[]
  sections?: {
    title?: string
    content: string | ReactNode
    props?: StackProps
  }[]
}

export const CardItem: React.FC<CardItemProps> = ({
  title,
  active,
  chips,
  subtitles,
  sections,
  actions,
  sx,
  ...props
}: CardItemProps) => {
  return (
    <Card
      {...props}
      orientation="horizontal"
      sx={({ palette }) => ({
        flexGrow: 1,
        ...(active && {
          animation: "borderMove .3s infinite linear",
          background: `linear-gradient(90deg, ${palette.neutral.outlinedBorder} 50%, transparent 0) repeat-x, linear-gradient(90deg, ${palette.neutral.outlinedBorder} 50%, transparent 0) repeat-x, linear-gradient(0deg, ${palette.neutral.outlinedBorder} 50%, transparent 0) repeat-y, linear-gradient(0deg, ${palette.neutral.outlinedBorder} 50%, transparent 0) repeat-y`,
          backgroundSize: " 10px 1px, 10px 1px, 1px 10px, 1px 10px",
          backgroundPosition: "0 0, 0 100%, 0 0, 100% 0",
          borderColor: "transparent"
        }),
        /* c8 ignore next */ ...(sx && { sx })
      })}
    >
      <CardContent>
        {!!actions && <CardActions active={active} actions={actions} />}
        <Stack
          direction="row"
          alignItems="center"
          flexGrow={1}
          divider={
            <Divider
              orientation="vertical"
              inset="context"
              sx={{ ml: 2, mr: 2 }}
            />
          }
        >
          <Stack flexGrow={1} gap="5px">
            <Typography
              level="body-md"
              component="h2"
              fontWeight="normal"
              textColor="text.primary"
              display="flex"
              alignItems="center"
              gap={1}
              {...title.props}
            >
              {title.copiable ? (
                <CopyToClipboard>{title.content}</CopyToClipboard>
              ) : (
                title.content
              )}
              {chips?.map(({ content, color, props: chipProps }, i) => (
                <Chip
                  key={`card-chip-${i}`}
                  color={color || "neutral"}
                  {...chipProps}
                >
                  {content}
                </Chip>
              ))}
            </Typography>

            {subtitles?.length &&
              subtitles.map(
                (
                  { label, content, color, copiable, props: subtitleProps },
                  index
                ) => (
                  <Typography
                    level="body-xs"
                    key={`card-subtitle-${index}`}
                    color={color}
                    {...subtitleProps}
                  >
                    {label && (
                      <Typography fontWeight="bold">{label}: </Typography>
                    )}
                    {copiable ? (
                      <CopyToClipboard>{content}</CopyToClipboard>
                    ) : (
                      content
                    )}
                  </Typography>
                )
              )}
          </Stack>

          {sections?.length &&
            sections.map(({ title, content, props: stackProps }, i) => (
              <Stack key={`card-section-${i}`} {...stackProps}>
                {title && (
                  <Typography
                    level="body-xs"
                    fontWeight={600}
                    textColor="text.secondary"
                  >
                    {title}
                  </Typography>
                )}
                {typeof content === "string" ? (
                  <Typography>{content}</Typography>
                ) : (
                  content
                )}
              </Stack>
            ))}
        </Stack>
      </CardContent>
    </Card>
  )
}
