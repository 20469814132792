import { switchClasses } from "@mui/joy"
import { extendTheme, PaletteRange } from "@mui/joy/styles"

declare module "@mui/joy" {
  interface ColorPalettePropOverrides {
    game: true
    space: true
    live: true
    staging: true
    dev: true
    "online-dev": true
  }

  interface Palette {
    game: PaletteRange
    space: PaletteRange
    live: PaletteRange
    staging: PaletteRange
    dev: PaletteRange
    "online-dev": PaletteRange
  }
}

const generateVariables = (key: string, mode: "dark" | "light") => ({
  plainColor: `var(--palette-${key}-${mode === "light" ? 500 : 300})`,
  plainHoverBg: `var(--palette-${key}-${mode === "light" ? 100 : 800})`,
  plainActiveBg: `var(--palette-${key}-${mode === "light" ? 200 : 700})`,
  plainDisabledColor: `var(--palette-neutral-${mode === "light" ? 400 : 500})`,
  outlinedColor: `var(--palette-${key}-${mode === "light" ? 500 : 200})`,
  outlinedBorder: `var(--palette-${key}-${mode === "light" ? 300 : 700})`,
  outlinedHoverBg: `var(--palette-${key}-${mode === "light" ? 100 : 800})`,
  outlinedActiveBg: `var(--palette-${key}-${mode === "light" ? 200 : 700})`,
  outlinedDisabledColor: `var(--palette-neutral-${mode === "light" ? 400 : 500})`,
  outlinedDisabledBorder: `var(--palette-neutral-${mode === "light" ? 200 : 800})`,
  softColor: `var(--palette-${key}-${mode === "light" ? 700 : 200})`,
  softBg: `var(--palette-${key}-${mode === "light" ? 100 : 900})`,
  softHoverBg: `var(--palette-${key}-${mode === "light" ? 200 : 700})`,
  softActiveBg: `var(--palette-${key}-${mode === "light" ? 300 : 600})`,
  softDisabledColor: `var(--palette-neutral-${mode === "light" ? 400 : 500})`,
  softDisabledBg: `var(--palette-neutral-${mode === "light" ? 50 : 800})`,
  solidColor: `var(--palette-common-white)`,
  solidBg: `var(--palette-${key}-500)`,
  solidHoverBg: `var(--palette-${key}-600)`,
  solidActiveBg: `var(--palette-${key}-700)`,
  solidDisabledColor: `var(--palette-neutral-${mode === "light" ? 400 : 500})`,
  solidDisabledBg: `var(--palette-neutral-${mode === "light" ? 100 : 800})`
})

const primaryPalette = {
  "50": "#F0FAFF",
  "100": "#E0F5FF",
  "200": "#CCEFFF",
  "300": "#9ADBF9",
  "400": "#9ADBF9",
  "500": "#00a3ef",
  "600": "#028ED4",
  "700": "#027AC0",
  "800": "#026BAC",
  "900": "#024C8D",
  mainChannel: "0 163 239",
  lightChannel: "204 239 255",
  darkChannel: "2 107 172"
}

const gamePalette = {
  50: "#faf5ff",
  100: "#f3e8ff",
  200: "#e9d5ff",
  300: "#d8b4fe",
  400: "#c084fc",
  500: "#a855f7",
  600: "#9333ea",
  700: "#7e22ce",
  800: "#6b21a8",
  900: "#581c87",
  mainChannel: "233 213 255",
  lightChannel: "0 178 255",
  darkChannel: "107 33 168"
}

const spacePalette = {
  "50": "#f0fdfa",
  "100": "#ccfbf1",
  "200": "#99f6e4",
  "300": "#5eead4",
  "400": "#2dd4bf",
  "500": "#14b8a6",
  "600": "#0d9488",
  "700": "#0f766e",
  "800": "#115e59",
  "900": "#134e4a",
  mainChannel: "20 184,166",
  lightChannel: "153 246 228",
  darkChannel: "17 94 89"
}

const livePalette = {
  50: "#fff1f2",
  100: "#ffe4e6",
  200: "#fecdd3",
  300: "#fda4af",
  400: "#fb7185",
  500: "#f43f5e",
  600: "#e11d48",
  700: "#be123c",
  800: "#9f1239",
  900: "#881337",
  mainChannel: "#f43f5e",
  lightChannel: "#fecdd3",
  darkChannel: "#9f1239"
}

const stagingPalette = {
  "50": "#fffbeb",
  "100": "#fef3c7",
  "200": "#fde68a",
  "300": "#fcd34d",
  "400": "#fbbf24",
  "500": "#f59e0b",
  "600": "#d97706",
  "700": "#b45309",
  "800": "#92400e",
  "900": "#78350f",
  mainChannel: "245 158 11",
  lightChannel: "253 230 138",
  darkChannel: "146 64 14"
}

const devPalette = {
  "50": "#f7fee7",
  "100": "#ecfccb",
  "200": "#d9f99d",
  "300": "#bef264",
  "400": "#a3e635",
  "500": "#84cc16",
  "600": "#65a30d",
  "700": "#4d7c0f",
  "800": "#3f6212",
  "900": "#365314",
  mainChannel: "132 204 22",
  lightChannel: "217 249 157",
  darkChannel: "63 98 18"
}

const onlineDevPalette = {
  ...primaryPalette
}

const theme = extendTheme({
  cssVarPrefix: "",
  colorSchemes: {
    light: {
      palette: {
        game: {
          ...gamePalette,
          ...generateVariables("game", "light")
        },
        space: {
          ...spacePalette,
          ...generateVariables("space", "light")
        },
        live: {
          ...livePalette,
          ...generateVariables("live", "light")
        },
        staging: {
          ...stagingPalette,
          ...generateVariables("staging", "light")
        },
        dev: {
          ...devPalette,
          ...generateVariables("dev", "light")
        },
        "online-dev": {
          ...onlineDevPalette,
          ...generateVariables("online-dev", "light")
        },
        primary: {
          ...primaryPalette
        }
      }
    },
    dark: {
      palette: {
        game: {
          ...gamePalette,
          ...generateVariables("game", "dark")
        },
        space: {
          ...spacePalette,
          ...generateVariables("space", "dark")
        },
        live: {
          ...livePalette,
          ...generateVariables("live", "dark")
        },
        staging: {
          ...stagingPalette,
          ...generateVariables("staging", "dark")
        },
        dev: {
          ...devPalette,
          ...generateVariables("dev", "dark")
        },
        "online-dev": {
          ...onlineDevPalette,
          ...generateVariables("online-dev", "dark")
        },
        primary: {
          ...primaryPalette
        }
      }
    }
  },
  fontFamily: {
    fallback:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Noto Color Emoji", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
  },
  components: {
    JoyChip: {
      defaultProps: {
        size: "sm",
        variant: "outlined"
      },
      styleOverrides: {
        root: {
          textTransform: "uppercase",
          fontWeight: "600"
        }
      }
    },
    JoySelect: {
      defaultProps: {
        slotProps: {
          listbox: {
            placement: "bottom-start"
          }
        }
      }
    },
    JoySwitch: {
      styleOverrides: {
        root: {
          "--Switch-thumbSize": "22px",
          "--Switch-trackWidth": "50px",
          "--Switch-trackHeight": "26px",
          [`& .${switchClasses.thumb}`]: {
            transition: "left 0.2s"
          }
        }
      }
    },
    JoyDrawer: {
      styleOverrides: {
        root: {
          "--Drawer-transitionDuration": "200ms"
        },
        backdrop: {
          backdropFilter: "blur(3px)",
          transform: "translate3d(0, 0, 0)"
        }
      }
    },
    JoyList: {
      defaultProps: {
        size: "sm"
      }
    },
    JoySkeleton: {
      defaultProps: {
        animation: "wave"
      }
    },
    JoyCircularProgress: {
      defaultProps: {
        size: "lg",
        thickness: 3
      }
    },
    JoyTable: {
      styleOverrides: {
        root: ({ theme }) => ({
          "--TableCell-headBackground": theme.vars.palette.background.level1,
          "--TableRow-hoverBackground": theme.vars.palette.background.body,
          ...theme.applyStyles("dark", {
            "--TableRow-hoverBackground": theme.vars.palette.background.backdrop
          })
        })
      }
    },
    JoyInput: {
      defaultProps: {
        slotProps: {
          input: {
            // This disables most password managers autocomplete
            "data-1p-ignore": "",
            "data-lpignore": "",
            "data-bwignore": ""
          }
        }
      }
    }
  }
})

export default theme
