import {
  ByEnvQuery,
  sortByKey,
  Space,
  SpaceCreateRequest,
  SpaceCreateResponse,
  SpaceValidation
} from "@liveops-portal/lib"
import { api } from "@/api"

export const spaceApi = api
  .enhanceEndpoints({
    addTagTypes: ["Space"]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      findAllSpaces: builder.query<Space[], ByEnvQuery>({
        query: () => ({
          url: "spaces"
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ spaceId }) => ({
                  type: "Space" as const,
                  id: spaceId
                })),

                { type: "Space", id: "ALL" }
              ]
            : [{ type: "Space", id: "ALL" }],
        transformResponse: (spaces: Space[]) => sortByKey(spaces, "spaceName")
      }),
      createSpace: builder.mutation<SpaceCreateResponse, SpaceCreateRequest>({
        query: ({ gameId, spaceName, spacePrefix, qdOperationReason }) => ({
          url: "spaces",
          method: "POST",
          params: { gameId },
          body: {
            spaceName,
            spacePrefix
          },
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: [{ type: "Space", id: "ALL" }]
      }),
      validateSpace: builder.query<SpaceValidation, string>({
        query: (spaceId) => ({
          url: `spaces/${spaceId}/validate`
        })
      }),
      fixSpace: builder.query<SpaceValidation, string>({
        query: (spaceId) => ({
          url: `spaces/${spaceId}/fix`,
          method: "PATCH"
        })
      })
    }),
    overrideExisting: false
  })

export const {
  useFindAllSpacesQuery,
  useCreateSpaceMutation,
  useLazyValidateSpaceQuery,
  useLazyFixSpaceQuery
} = spaceApi
