import {
  BySpaceIdQuery,
  ConfigForSpaceId,
  ConfigCategoryUpdateRequest,
  ConfigCategoryDeleteRequest
} from "@liveops-portal/lib"
import { api } from "@/api"

export const configApi = api
  .enhanceEndpoints({ addTagTypes: ["Config"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      findAllConfigs: builder.query<ConfigForSpaceId, BySpaceIdQuery>({
        query: ({ spaceId, ignoreDefaultConfig = false }) => ({
          url: `space/${spaceId}/config/categories`,
          params: { ignoreDefaultConfig }
        }),
        providesTags: ["Config"]
      }),
      updateConfigCategory: builder.mutation<void, ConfigCategoryUpdateRequest>(
        {
          query: ({ spaceId, configName, jsonData }) => ({
            url: `space/${spaceId}/config/categories/${configName}`,
            method: "POST",
            body: jsonData
          }),
          invalidatesTags: ["Config"]
        }
      ),
      deleteConfigCategory: builder.mutation<void, ConfigCategoryDeleteRequest>(
        {
          query: ({ spaceId, configName }) => ({
            url: `space/${spaceId}/config/categories/${configName}`,
            method: "DELETE"
          }),
          invalidatesTags: ["Config"]
        }
      )
    }),
    overrideExisting: false
  })

export const {
  useFindAllConfigsQuery,
  useUpdateConfigCategoryMutation,
  useDeleteConfigCategoryMutation
} = configApi
