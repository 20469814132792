import { formatJsonToString, Operation } from "@liveops-portal/lib"
import {
  FormControl,
  FormLabel,
  Select,
  Stack,
  Option,
  Typography,
  FormHelperText,
  IconButton
} from "@mui/joy"
import { PinSlashSolid, PinSolid } from "iconoir-react"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  useGetDataVersionsQuery,
  useGetDataTypesQuery,
  useGetGameDataQuery,
  usePreserveDataVersionMutation,
  useUnpreserveDataVersionMutation
} from "@/api/game-data"
import { useGetSeasonsQuery } from "@/api/season"
import { CodeEditor } from "@/components/code-editor/code-editor"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { Spinner } from "@/components/spinner/spinner"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useIsAdmin } from "@/hooks/useIsAdmin"
import {
  resetOperation,
  selectActiveOperation,
  setActiveOperation
} from "@/store/slices/audit"
import { selectEnv, selectGame, selectSeason } from "@/store/slices/context"

/**
 * Component representing the Season Designer DataViewer page.
 */
export const SeasonDesignerDataViewerPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const seasonId = useAppSelector(selectSeason)
  const isAdmin = useIsAdmin()
  const activeOperation = useAppSelector(selectActiveOperation)
  const [dataVersion, setDataVersion] = useState<string | null>(null)
  const [dataType, setDataType] = useState<string | null>(null)
  const preserveOperation: Operation = ["dataVersion", "preserve"]
  const isPreserveOperationActive = isOperationActive(
    activeOperation,
    preserveOperation
  )
  const [preserve, { isLoading: isLoadingPreserve }] =
    usePreserveDataVersionMutation()
  const [unpreserve, { isLoading: isLoadingUnpreserve }] =
    useUnpreserveDataVersionMutation()

  const { data: activeSeason, isFetching: isFetchingSeasons } =
    useGetSeasonsQuery(
      { gameId, env },
      {
        skip: !gameId || !env,
        selectFromResult: (result) => ({
          ...result,
          data: result.data?.find((season) => season.seasonId === seasonId)
        })
      }
    )

  const {
    data: dataVersions,
    isFetching: isFetchingDataVersions,
    isUninitialized: isUninitializedDataVersions
  } = useGetDataVersionsQuery({ env, gameId }, { skip: !env || !gameId })

  const { data: dataTypes } = useGetDataTypesQuery(
    { env, gameId, dataVersion },
    { skip: !dataVersion }
  )

  const { data: gameData, isFetching: isFetchingGameData } =
    useGetGameDataQuery(
      { env, gameId, dataVersion, dataType },
      { skip: !dataType }
    )

  useEffect(() => {
    setDataVersion(
      activeSeason?.latestPublishedSeasonRevision
        ? activeSeason?.latestPublishedSeasonRevision?.seasonRevisionDetails
            .clientDataVersion
        : activeSeason?.latestDraftSeasonRevision?.seasonRevisionDetails
            .clientDataVersion || null
    )
    setDataType(null)
    dispatch(resetOperation())
  }, [activeSeason, dispatch])

  const isDataVersionPreserved = useMemo(
    () =>
      dataVersion &&
      dataVersions?.length &&
      dataVersions.find(({ dataVersion: v }) => dataVersion === v.toString())
        ?.preserved,
    [dataVersion, dataVersions]
  )

  const onSubmitHandler = () =>
    isDataVersionPreserved
      ? unpreserve({ gameId, dataVersion })
      : preserve({ gameId, dataVersion })

  return (
    <>
      <Spinner
        loading={
          isFetchingSeasons ||
          isFetchingDataVersions ||
          isUninitializedDataVersions
        }
      />

      <OperationReason
        autofocus={isPreserveOperationActive}
        closeable={isPreserveOperationActive}
        open={
          isPreserveOperationActive || isLoadingPreserve || isLoadingUnpreserve
        }
        onSubmit={onSubmitHandler}
      />

      <Stack sx={{ gap: 2, flexGrow: 1 }}>
        {dataVersions?.length ? (
          <>
            <Stack sx={{ gap: 1 }}>
              <Stack
                sx={{ gap: 2, flexDirection: "row", alignItems: "flex-start" }}
              >
                <FormControl>
                  <FormLabel>{t("item.dataVersion")}</FormLabel>
                  <Stack sx={{ gap: 1, flexDirection: "row" }}>
                    <Select
                      size="sm"
                      value={dataVersion || "placeholder"}
                      name="dataVersion"
                      onChange={(_, value) => {
                        setDataVersion(value)
                        dispatch(resetOperation())
                      }}
                      sx={{ width: 150 }}
                    >
                      <Option disabled value="placeholder">
                        {t("placeholder.option")}
                      </Option>
                      {dataVersions?.map(({ dataVersion }) => (
                        <Option
                          key={dataVersion}
                          label={dataVersion.toString()}
                          value={dataVersion.toString()}
                        >
                          {dataVersion}
                        </Option>
                      ))}
                    </Select>
                    {dataVersion && isAdmin && (
                      <IconButton
                        size="sm"
                        variant="soft"
                        color={isDataVersionPreserved ? "danger" : "success"}
                        onClick={() => {
                          dispatch(setActiveOperation(preserveOperation))
                        }}
                        title={t(
                          isDataVersionPreserved
                            ? "action.unpreserve"
                            : "action.preserve"
                        )}
                        aria-label={t(
                          isDataVersionPreserved
                            ? "action.unpreserve"
                            : "action.preserve"
                        )}
                      >
                        {isDataVersionPreserved ? (
                          <PinSlashSolid />
                        ) : (
                          <PinSolid />
                        )}
                      </IconButton>
                    )}
                  </Stack>
                  {dataVersion && (
                    <Typography
                      fontSize="xs"
                      color={isDataVersionPreserved ? "success" : "danger"}
                      mt={1}
                    >
                      {t(
                        isDataVersionPreserved
                          ? "state.preserved"
                          : "state.notPreserved"
                      )}
                    </Typography>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel>{t("item.dataType")}</FormLabel>
                  <Select
                    size="sm"
                    value={dataType || "placeholder"}
                    name="dataType"
                    disabled={!dataVersion || !dataTypes?.length}
                    onChange={(_, value) => {
                      setDataType(value)
                    }}
                    sx={{ width: 150 }}
                  >
                    <Option disabled value="placeholder">
                      {t("placeholder.option")}
                    </Option>
                    {dataTypes?.map((option) => (
                      <Option key={option} label={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              {dataVersion ===
                (activeSeason?.latestPublishedSeasonRevision
                  ?.seasonRevisionDetails.clientDataVersion ||
                  activeSeason?.latestDraftSeasonRevision?.seasonRevisionDetails
                    .clientDataVersion) && (
                <FormHelperText>
                  {t("message.dataViewer.associatedVersion", {
                    seasonName: activeSeason?.seasonName,
                    dataVersion
                  })}
                </FormHelperText>
              )}
            </Stack>
            <Stack
              sx={{
                flexGrow: 1,
                position: "relative"
              }}
            >
              <Spinner loading={isFetchingGameData} />
              <CodeEditor
                readOnly
                name="gameData"
                value={formatJsonToString(
                  dataVersion && dataType && gameData ? gameData : {}
                )}
                label={t("item.data")}
              />
            </Stack>
          </>
        ) : (
          <Typography>
            {t("message.noItems.parent", {
              parent: "item.game",
              item: "item.dataVersions"
            })}
          </Typography>
        )}
      </Stack>
    </>
  )
}
