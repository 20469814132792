import { GlobalStyles, List, IconButton, Stack, Typography } from "@mui/joy"
import { SidebarCollapse } from "iconoir-react"
import React from "react"
import { useTranslation } from "react-i18next"
import { Logo } from "@/components/logo/logo"
import { SidebarItem } from "@/components/sidebar-item/sidebar-item"
import { ROUTES } from "@/constants/routes"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { selectIsSidebarExpanded, toggleSidebar } from "@/store/slices/app"
import { selectEnv } from "@/store/slices/context"

export const Sidebar: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const env = useAppSelector(selectEnv)
  const expanded = useAppSelector(selectIsSidebarExpanded)

  return (
    <Stack
      data-testid="sidebar"
      component="aside"
      aria-expanded={expanded}
      sx={{
        height: "100dvh",
        width: "var(--Sidebar-width)",
        position: "fixed",
        zIndex: "popup",
        top: 0,
        left: 0,
        borderRight: 1,
        borderColor: "divider",
        transitionDuration: "0.2s",
        transitionProperty: "left, width"
      }}
    >
      <GlobalStyles
        styles={{
          ":root": {
            "--Sidebar-width": expanded ? "250px" : "60px"
          }
        }}
      />
      <Stack
        component="header"
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1.5,
          minHeight: "var(--Header-height)",
          borderBottom: 1,
          borderColor: "divider"
        }}
      >
        <Logo to="/" collapsed={!expanded} />

        {expanded && (
          <IconButton
            size="sm"
            title={t("action.item", {
              action: "action.collapse",
              item: "item.sidebar"
            })}
            aria-label={t("action.item", {
              action: "action.collapse",
              item: "item.sidebar"
            })}
            onClick={() => dispatch(toggleSidebar())}
            aria-expanded={expanded}
            aria-controls="sidebar"
            sx={{ mr: -1, "&:hover": { bgcolor: "transparent" } }}
          >
            <SidebarCollapse />
          </IconButton>
        )}
      </Stack>

      <Stack
        component="nav"
        sx={{
          overflowX: "hidden",
          overflowY: "auto",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none"
          }
        }}
      >
        {ROUTES.map(({ id, handle: { name }, children }) => {
          const sectionName = expanded ? name : "—"

          return (
            <Stack
              key={id}
              sx={{ borderBottom: Number(expanded), borderColor: "divider" }}
            >
              <Typography
                sx={{
                  p: 2,
                  pb: 1,
                  color: expanded ? `${env}.solidBg` : "neutral.500",
                  fontSize: "xs",
                  fontWeight: 600,
                  letterSpacing: "0.1em",
                  textTransform: "uppercase",
                  transition: "padding 0.2s"
                }}
              >
                {t(sectionName)}
              </Typography>
              {children && (
                <List
                  sx={({ vars: { palette, fontSize }, spacing }) => ({
                    p: 0,
                    "--Icon-color": palette.text.tertiary,
                    "--Icon-fontSize": fontSize.xl,
                    "--ListItemDecorator-size": spacing(4),
                    "--ListItem-paddingLeft": spacing(2),
                    "--ListItem-minHeight": spacing(5)
                  })}
                >
                  {children.map((item) => (
                    <SidebarItem key={item.id} {...item} />
                  ))}
                </List>
              )}
            </Stack>
          )
        })}
      </Stack>
    </Stack>
  )
}
