import { Action, Actions } from "@liveops-portal/lib"
import { FieldValues, SubmitHandler, UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { FormSelect } from "@/components/form-select/form-select"
import { Modal } from "@/components/modal/modal"
import { useAppSelector } from "@/hooks/store"
import { useFindAllGameDataVersionsQuery } from "@/store/api/game-data"
import { selectGame, selectEnv } from "@/store/slices/context"

interface Props<T extends FieldValues> {
  open: boolean
  loading: boolean
  action: Action
  methods: UseFormReturn<T>
  onSubmit: SubmitHandler<T>
  onClose: () => void
}

export const SeasonModal = <T extends FieldValues>({
  open,
  loading,
  action,
  methods,
  onClose,
  onSubmit
}: Props<T>) => {
  const { t } = useTranslation()

  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const skip = !gameId || !env
  const { data: gameDataVersions } = useFindAllGameDataVersionsQuery(
    { gameId, env },
    { skip }
  )
  const item = "item.season"

  return (
    !!gameDataVersions && (
      <Modal
        open={open}
        onClose={onClose}
        title={t("action.item", {
          action: `action.${action}`,
          item
        })}
      >
        <Form
          auditable
          methods={methods}
          loading={loading}
          submitLabel={t(`action.${action}`)}
          onSubmit={onSubmit}
        >
          <FormInput
            type="text"
            name="seasonName"
            disabled={action === Actions.update}
            label={t("modifier.name", { item })}
            rules={{
              required: true
            }}
          />

          <FormInput
            type="number"
            name="seasonRevisionDetails.duration"
            label={t("label.time.duration")}
            rules={{
              required: true,
              min: 1
            }}
            endDecorator={<>{t("time.unit.week_other")}</>}
            slotProps={{ input: { min: 1, max: 1000 } }}
          />

          <FormSelect
            name="seasonRevisionDetails.clientDataVersion"
            label={t("item.dataVersion")}
            options={gameDataVersions.map((gameDataVersion) => ({
              value: gameDataVersion,
              label: gameDataVersion
            }))}
            rules={{
              required: true
            }}
          />
        </Form>
      </Modal>
    )
  )
}
