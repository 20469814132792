import { DataState } from "@liveops-portal/lib"
import { ColorPaletteProp } from "@mui/joy"

/**
 * Matches a data state to a color, for chips or buttons
 * @param state
 */
export const matchDataStateToColor = (
  state: keyof typeof DataState
): ColorPaletteProp => {
  switch (DataState[state]) {
    case DataState.WAITING_FOR_UPLOAD:
      return "warning"
    case DataState.READY_FOR_USE:
      return "neutral"
    case DataState.IN_USE:
      return "success"
  }
}

/**
 * Finds a dot notation path value in an object
 * Helpful to retrieve deeply nested values from form defaultValues or errors
 * e.g.: field.0.value
 * @param obj
 * @param path
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const findNestedValue = (obj: Record<string, any>, path: string) =>
  path.split(".").reduce((acc, key) => acc?.[key], obj)

/**
 * Finds last element of array without mutating the array (as opposed to .pop())
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const last = (array: any[]) => array[array.length - 1]
