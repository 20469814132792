import { Box, Button, Stack, Typography } from "@mui/joy"
import React from "react"
import { useTranslation } from "react-i18next"
import { generatePath, Link } from "react-router-dom"
import { useAppSelector } from "@/hooks/store"
import { selectEnv } from "@/store/slices/context"

export const NotFoundPage: React.FC = () => {
  const env = useAppSelector(selectEnv)
  const { t } = useTranslation()

  return (
    <Stack sx={{ gap: 3 }}>
      <Typography
        level="body-lg"
        sx={{ fontWeight: "md", whiteSpace: "pre-line" }}
      >
        {t("message.notFound.title")}
      </Typography>
      <Typography level="body-md" sx={{ whiteSpace: "pre-line" }}>
        {t("message.notFound.explanation")}
      </Typography>
      <Box>
        <Button component={Link} to={generatePath("/:env", { env })}>
          {t("action.home")}
        </Button>
      </Box>
    </Stack>
  )
}
