import { Card, CardContent, Sheet, Stack, Table, Typography } from "@mui/joy"
import { skipToken } from "@reduxjs/toolkit/query"
import React from "react"
import { useTranslation } from "react-i18next"
import { useGetPlayerQuery } from "@/api/player"
import { PlayerWalletItem } from "@/components/player-wallet-item/player-wallet-item"
import { useAppSelector } from "@/hooks/store"
import { selectSpace, selectPlayer } from "@/store/slices/context"

export const PlayerWallet: React.FC = () => {
  const { t } = useTranslation()
  const spaceId = useAppSelector(selectSpace)
  const { type, accountId } = useAppSelector(selectPlayer)
  const { currentData: playerProfile } = useGetPlayerQuery(
    spaceId && !!accountId.length && type
      ? { spaceId, accountId, type }
      : skipToken
  )
  const wallet = playerProfile && playerProfile.wallets

  return (
    !!wallet && (
      <Stack sx={{ gap: 2, flexGrow: 1 }}>
        <Typography level="h3">{t("label.player.wallet")}</Typography>
        {wallet.length ? (
          <Sheet
            variant="outlined"
            sx={{
              overflow: "hidden",
              borderRadius: "sm"
            }}
          >
            <Table noWrap>
              <thead>
                <tr>
                  <th>{t("item.currency")}</th>
                  <th style={{ width: 90 }}>{t("label.player.balance")}</th>
                  <th style={{ width: 250 }}>{t("label.actions")}</th>
                </tr>
              </thead>
              <tbody>
                {wallet.map((currency) => (
                  <PlayerWalletItem
                    key={currency.currencyCode}
                    accountId={playerProfile.accountId}
                    {...currency}
                  />
                ))}
              </tbody>
            </Table>
          </Sheet>
        ) : (
          <Card sx={{ flexGrow: 1 }}>
            <CardContent sx={{ gap: 2, flexGrow: 1 }}>
              <Typography color="warning" fontStyle="italic">
                {t("error.player.noWallet")}
              </Typography>
            </CardContent>
          </Card>
        )}
      </Stack>
    )
  )
}
