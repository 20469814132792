import {
  Challenge,
  insertIf,
  Operation,
  Season,
  SeasonComponentItem
} from "@liveops-portal/lib"
import {
  List,
  ListItem,
  ListItemButton,
  listItemButtonClasses,
  Sheet,
  Table,
  Typography
} from "@mui/joy"
import { Collapse } from "@mui/material"
import { NavArrowDown } from "iconoir-react"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { CardItem } from "@/components/card-item/card-item"
import { ChallengeUpdate } from "@/components/challenge-update/challenge-update"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useFindAllGameDataQuery } from "@/store/api/game-data"
import {
  useDeleteSeasonComponentItemMutation,
  useGetSeasonComponentItemsQuery,
  useGetSeasonsQuery
} from "@/store/api/season"
import { selectActiveOperation, setActiveOperation } from "@/store/slices/audit"
import { selectEnv, selectGame, selectSeason } from "@/store/slices/context"

interface Props {
  itemId: SeasonComponentItem["itemId"]
}

export const ChallengeCard: React.FC<Props> = ({ itemId }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const activeOperation = useAppSelector(selectActiveOperation)
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const seasonId = useAppSelector(selectSeason)
  const [expandLevels, setExpandLevels] = useState<boolean>(false)

  // DATA
  const { data: activeSeason } = useGetSeasonsQuery(
    { env, gameId },
    {
      skip: !env || !gameId || !seasonId,
      selectFromResult: (result) => ({
        ...result,
        data:
          result.data?.find((season) => season.seasonId === seasonId) ||
          ({} as Season)
      })
    }
  )

  const { data: filters } = useFindAllGameDataQuery(
    {
      env,
      gameId,
      dataVersion: activeSeason.clientDataVersion,
      dataType: "challenge-filters"
    },
    { skip: !env || !gameId || !activeSeason.clientDataVersion }
  )

  const { item } = useGetSeasonComponentItemsQuery(
    { gameId, seasonId, env, componentType: "challenge" },
    {
      skip: !env || !gameId || !seasonId,
      selectFromResult: ({ data }) => ({
        item: data?.find((item) => item.itemId === itemId) as
          | SeasonComponentItem<Challenge>
          | undefined
      })
    }
  )
  const { displayName, scope, progressType, levels, progressInMenu, filter } =
    item || {}

  const _filter = useMemo(
    () => filters?.find(({ guid }) => guid === filter),
    [filter, filters]
  )

  // UPDATE
  const [openUpdate, setOpenUpdate] = useState<boolean>(false)

  // DELETE
  const [deleteChallenge, deleteChallengeMeta] =
    useDeleteSeasonComponentItemMutation()
  const deleteOperation: Operation = [itemId, "delete"]
  const isDeleteOperationActive = isOperationActive(
    activeOperation,
    deleteOperation
  )
  const isLoadingDelete =
    isDeleteOperationActive || deleteChallengeMeta.isLoading

  const onSubmitHandler = () => {
    if (isDeleteOperationActive)
      deleteChallenge({ seasonId, gameId, itemId, componentType: "challenge" })
  }

  return (
    item && (
      <>
        <OperationReason
          open={isDeleteOperationActive}
          onSubmit={onSubmitHandler}
          autofocus
          closeable
        />

        <ChallengeUpdate open={openUpdate} setOpen={setOpenUpdate} {...item} />

        <CardItem
          title={{ content: displayName }}
          active={isLoadingDelete}
          chips={[
            {
              content: scope!
            },
            {
              content: t(`label.season.progressTypes.${progressType}`)
            },
            /* c8 ignore start */ // Fallback to machine name if friendly name doesn't exist
            ...insertIf(Boolean(_filter && _filter.data), {
              content: _filter?.data?.name || _filter?.name || ""
            })
            /* c8 ignore end */
          ]}
          subtitles={[
            { content: itemId, copiable: true },
            {
              content: (
                <List
                  variant="plain"
                  aria-expanded={expandLevels}
                  sx={({ spacing }) => ({
                    "--ListItem-paddingY": 0,
                    "--ListItem-minHeight": 0,
                    "--ListDivider-gap": 0,
                    "--ListItem-startActionWidth": spacing(1),
                    "--ListItem-startActionTranslateX": spacing(-0.5),
                    fontWeight: "sm"
                  })}
                >
                  <ListItem
                    startAction={
                      <NavArrowDown
                        style={{
                          transform: expandLevels ? "none" : "rotate(-90deg)",
                          transition: "all 0.2s"
                        }}
                      />
                    }
                    onClick={() => {
                      setExpandLevels(!expandLevels)
                    }}
                  >
                    <ListItemButton
                      sx={{
                        color: "text.tertiary",
                        [`&.${listItemButtonClasses.root}:hover`]: {
                          bgcolor: "transparent"
                        }
                      }}
                    >
                      <Typography fontWeight="md">
                        {t("item.levels")}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                  <Collapse in={expandLevels} timeout={200}>
                    <Sheet sx={{ maxHeight: 200, overflow: "auto" }}>
                      <Table
                        noWrap
                        stickyHeader
                        aria-expanded={expandLevels}
                        sx={{
                          tableLayout: "auto",
                          "--TableCell-height": "auto",
                          "& thead tr:first-of-type th": {
                            verticalAlign: "bottom"
                          },
                          "& thead th": {
                            verticalAlign: "middle"
                          }
                        }}
                      >
                        <thead>
                          <tr>
                            <th style={{ textAlign: "right", width: 32 }}>#</th>
                            <th>{t("label.season.threshold")}</th>
                            <th>{t("item.reward")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {levels?.map(({ threshold, reward }, levelIdx) => (
                            <tr key={`levels[${levelIdx}]`}>
                              <th scope="row" style={{ textAlign: "right" }}>
                                {levelIdx + 1}
                              </th>
                              <td style={{ verticalAlign: "top" }}>
                                {threshold}
                              </td>
                              <td style={{ verticalAlign: "top" }}>{reward}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Sheet>
                  </Collapse>
                </List>
              )
            }
          ]}
          sections={[
            {
              title: t("label.season.progressInMenu"),
              content: progressInMenu ? t("common.yes") : t("common.no")
            }
          ]}
          actions={{
            update: {
              handler: () => {
                setOpenUpdate(true)
              }
            },
            delete: {
              handler: () => {
                dispatch(setActiveOperation(deleteOperation))
              }
            }
          }}
        />
      </>
    )
  )
}
