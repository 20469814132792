import { DataOption, Environments } from "@liveops-portal/lib"
import { skipToken } from "@reduxjs/toolkit/query"
import { useMemo } from "react"
import { useGetActiveDeploymentQuery } from "@/api/deployment"
import { useGetDataVersionsQuery, useGetGameDataQuery } from "@/api/game-data"
import { useAppSelector } from "@/hooks/store"
import {
  selectEnv,
  selectGame,
  selectSpace,
  selectStage
} from "@/store/slices/context"

export const useDataType = (
  dataType: string | null,
  dataVersion?: string | null
) => {
  const stage = useAppSelector(selectStage)
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)

  const validEnd =
    stage === Environments.ONLINEDEV ? env === stage : env !== stage

  const { data: deployment, isFetching: isFetchingDeployment } =
    useGetActiveDeploymentQuery(
      gameId && spaceId && !dataVersion ? { gameId, spaceId } : skipToken
    )

  const { data: latestDataVersion, isFetching: isFetchingGameData } =
    useGetDataVersionsQuery(
      env && gameId && !dataVersion ? { env, gameId } : skipToken,
      {
        selectFromResult: (result) => ({
          ...result,
          data: result.data?.map(({ dataVersion }) => dataVersion.toString())[
            result.data.length - 1
          ]
        })
      }
    )

  const isFetching = isFetchingDeployment || isFetchingGameData

  const requestedDataVersion = useMemo(
    () =>
      dataVersion
        ? dataVersion
        : validEnd && deployment?.bundleClientDataVersion
          ? deployment?.bundleClientDataVersion
          : latestDataVersion
            ? latestDataVersion
            : null,
    [
      dataVersion,
      latestDataVersion,
      deployment?.bundleClientDataVersion,
      validEnd
    ]
  )

  /* c8 ignore start */
  const _dataType =
    dataType === "challenge_filters"
      ? Number(requestedDataVersion) === 1
        ? "challenge-filters"
        : "challenge_filters"
      : dataType
  /* c8 ignore end */

  const result = useGetGameDataQuery(
    {
      env,
      gameId,
      dataVersion: requestedDataVersion,
      dataType: _dataType
    },
    {
      skip: !requestedDataVersion || !env || !gameId || !dataType
    }
  )

  return {
    ...result,
    data: (result.data?.map(({ guid, data, name }) => ({
      id: guid,
      label: /* c8 ignore next */ data?.name || name // Fallback to machine name if friendly name doesn't exist
    })) || []) as DataOption[],
    ...((result.isUninitialized || isFetching) && { isFetching: true })
  }
}
