import { SeasonPublishRequest, SeasonRevision } from "@liveops-portal/lib"
import dayjs from "dayjs"
import { t } from "i18next"
import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import {
  useGetReferenceDateQuery,
  usePublishSeasonRevisionMutation
} from "@/api/season"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { Modal } from "@/components/modal/modal"
import { useAppSelector } from "@/hooks/store"
import { selectGame } from "@/store/slices/context"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  seasonId: string
  revision: SeasonRevision["revision"]
}

type FormData = SeasonPublishRequest

export const SeasonPublish: React.FC<Props> = ({
  open,
  setOpen,
  seasonId,
  revision
}: Props) => {
  const gameId = useAppSelector(selectGame)
  const { data: referenceDate, isFetching: isFetchingReferenceDate } =
    useGetReferenceDateQuery(
      { gameId, seasonId },
      { skip: !open || revision === 0 }
    )
  const [publishSeasonRevision, mutation] = usePublishSeasonRevisionMutation()
  const { isSuccess, isLoading } = mutation

  const methods = useForm<FormData>({
    mode: "all",
    defaultValues: {
      gameId,
      seasonId,
      revision,
      referenceDate: ""
    }
  })
  const { reset: resetForm, setValue } = methods

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm, setOpen])

  const onSubmitHandler = (data: FormData) => publishSeasonRevision(data)

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [isSuccess, onCloseHandler])

  useEffect(() => {
    if (referenceDate)
      setValue("referenceDate", dayjs(referenceDate).format("YYYY-MM-DDTHH:mm"))
  }, [referenceDate, setValue])

  return (
    <Modal
      open={open}
      onClose={onCloseHandler}
      title={t("action.item", {
        action: "action.publish",
        item: "item.season"
      })}
    >
      <Form
        auditable
        methods={methods}
        loading={isLoading}
        disabled={isFetchingReferenceDate}
        submitLabel={t("action.publish")}
        onSubmit={onSubmitHandler}
      >
        <FormInput
          disabled={isLoading || isFetchingReferenceDate}
          type="datetime-local"
          name="referenceDate"
          label={t("label.season.referenceDate")}
          rules={{
            required: true
          }}
        />
      </Form>
    </Modal>
  )
}
