import { Card, CardContent, Divider, Stack, Typography } from "@mui/joy"
import { Unstable_Grid2 as Grid } from "@mui/material"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"
import { OverviewEntity } from "@/components/overview-entity/overview-entity"
import { OverviewTree } from "@/components/overview-tree/overview-tree"
import { SpaceCreate } from "@/components/space-create/space-create"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { usePageActions } from "@/hooks/usePageActions"
import { useGetAllApplicationsByGameIdQuery } from "@/store/api/application"
import { useFindAllConfigsQuery } from "@/store/api/config"
import { useGetAllGamesQuery } from "@/store/api/game"
import { useFindAllSpacesQuery } from "@/store/api/space"
import { selectEnv, selectGame, selectSpace } from "@/store/slices/context"

export const OverviewPage: React.FC = () => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)
  const spaces = useFindAllSpacesQuery({ env }, { skip: !env })
  const games = useGetAllGamesQuery({ env }, { skip: !env })
  const config = useFindAllConfigsQuery({ spaceId }, { skip: !spaceId })
  const apps = useGetAllApplicationsByGameIdQuery(
    { gameId, env },
    { skip: !gameId || !env }
  )

  const isFetching = useMemo(() => {
    const baseConditions =
      spaces.isFetching || games.isFetching || apps.isFetching

    return spaceId ? baseConditions || config.isFetching : baseConditions
  }, [
    spaceId,
    spaces.isFetching,
    games.isFetching,
    apps.isFetching,
    config.isFetching
  ])

  const isLoading = useMemo(() => {
    const baseConditions = spaces.isLoading && games.isLoading && apps.isLoading

    return spaceId ? baseConditions && config.isLoading : baseConditions
  }, [
    spaceId,
    apps.isLoading,
    config.isLoading,
    games.isLoading,
    spaces.isLoading
  ])

  usePageActions([<SpaceCreate />])

  return (
    <Grid container spacing={3} sx={{ flexGrow: 1 }}>
      <Grid xs={4} lg={2}>
        <OverviewTree />
      </Grid>
      <Grid xs={8} lg={10} position="relative">
        <Spinner loading={isFetching || isLoading} />

        {!isFetching && !isLoading && (
          <Stack sx={{ gap: 2 }}>
            <OverviewEntity />

            <Divider inset="context" />

            <Stack sx={{ gap: 2 }}>
              <Typography level="h4">{t("item.applications")}</Typography>

              <Grid container spacing={1}>
                {!apps?.data?.length && !apps?.isFetching && (
                  <Typography>
                    {t("message.noItems.root", { item: "item.application" })}
                  </Typography>
                )}
                {apps?.data?.map(({ appId, name }) => (
                  <Grid sm={12} md={6} lg={3} key={appId}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography level="title-md">{name}</Typography>
                        <Typography level="body-sm">
                          <CopyToClipboard>{appId}</CopyToClipboard>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Stack>
        )}
      </Grid>
    </Grid>
  )
}
