import { Link, LinkProps } from "@mui/joy"

import { OpenNewWindow } from "iconoir-react"
import React from "react"

interface Props extends LinkProps {}

export const ExternalLink: React.FC<Props> = ({
  children,
  ...props
}: Props) => {
  return (
    <Link endDecorator={<OpenNewWindow />} target="_blank" {...props}>
      {children}
    </Link>
  )
}
