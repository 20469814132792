import {
  Link,
  Stack,
  Checkbox,
  List,
  ListItem,
  Sheet,
  CheckboxProps,
  Typography,
  FormHelperText
} from "@mui/joy"
import { useEffect, useRef } from "react"
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  useFormContext
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormLabel } from "@/components/form-label/form-label"

interface Props<T extends FieldValues> {
  name: Path<T>
  label?: string
  options: CheckboxProps[]
  defaultValue?: string[]
  rules?: RegisterOptions
}

/**
 * Renders a Joy UI List with Checkboxes bound to React Hook Form.
 * @param name
 * @param label
 * @param options
 * @param defaultValue
 * @param rules
 */
export const FormMultiSelect = <T extends FieldValues>({
  name,
  label,
  rules,
  defaultValue,
  options
}: Props<T>) => {
  const { t } = useTranslation()
  const { control, setValue, trigger, formState } = useFormContext<T>()
  const init = useRef(false)

  useEffect(() => {
    if (!init.current) {
      const _defaultValue = defaultValue || formState.defaultValues?.[name]
      setValue(
        name,
        (_defaultValue ? _defaultValue : []) as PathValue<T, Path<T>>
      )
      init.current = true
    }
  }, [defaultValue, formState.defaultValues, name, setValue])

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value }, fieldState: { error } }) =>
        value && (
          <Stack gap={1}>
            <Stack
              gap={1}
              direction="row"
              textAlign="center"
              justifyContent="space-between"
            >
              {label && (
                <Typography
                  display="flex"
                  gap={1}
                  sx={({ palette }) => ({
                    "--FormLabel-asteriskColor": palette.danger[500]
                  })}
                >
                  <FormLabel required={!!rules?.validate || !!rules?.required}>
                    {label}
                  </FormLabel>
                  <Typography fontSize="xs" mt="1px">
                    ({value?.length}/{options.length})
                  </Typography>
                </Typography>
              )}
              <Link
                fontSize="sm"
                onClick={() => {
                  setValue(
                    name,
                    (value?.length !== options.length
                      ? options.map(({ name }) => name)
                      : []) as PathValue<T, Path<T>>
                  )
                  trigger(name)
                }}
              >
                {value?.length !== options.length
                  ? t("action.selectAll")
                  : t("action.selectNone")}
              </Link>
            </Stack>

            <Sheet
              variant="outlined"
              sx={{
                bgcolor: "transparent",
                maxHeight: 300,
                overflow: "hidden auto",
                borderRadius: "sm"
              }}
              aria-label={label}
            >
              <List>
                {options.map(({ name: optionName, label }) => (
                  <ListItem key={optionName}>
                    <Checkbox
                      overlay
                      size="sm"
                      name={name}
                      label={label}
                      id={`${name}.${optionName}`}
                      value={optionName}
                      checked={!!value?.length && value?.includes(optionName)}
                      onChange={(e) => {
                        setValue(
                          name,
                          !e.target.checked
                            ? value?.filter((v: string) => v !== e.target.value)
                            : value?.concat(e.target.value)
                        )
                        trigger(name)
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Sheet>

            {error?.message && (
              <FormHelperText
                sx={({ palette }) => ({
                  fontSize: "xs",
                  color: palette.danger[500]
                })}
              >
                {error.message}
              </FormHelperText>
            )}
          </Stack>
        )
      }
    />
  )
}
