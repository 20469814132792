import {
  Autocomplete,
  AutocompleteOption,
  Chip,
  createFilterOptions,
  ListItemContent,
  Typography
} from "@mui/joy"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { useGetSeasonsQuery } from "@/api/season"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useRoutePath } from "@/hooks/useRoutePath"
import {
  selectEnv,
  selectGame,
  selectSeason,
  selectSpace,
  setSeasonId
} from "@/store/slices/context"

export const SeasonSelector: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const currentPath = useRoutePath()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)
  const seasonId = useAppSelector(selectSeason)
  const { seasonId: seasonIdParam } = useParams()
  const isSeasonContext = currentPath?.includes("seasonId")

  const { data: seasons, isFetching: isFetchingSeasons } = useGetSeasonsQuery(
    { gameId, env },
    { skip: !env || !gameId || !isSeasonContext }
  )

  useEffect(() => {
    if (isSeasonContext && seasonIdParam && seasonIdParam !== seasonId) {
      dispatch(setSeasonId(seasonIdParam))
    }
  }, [dispatch, isSeasonContext, seasonId, seasonIdParam])

  return (
    currentPath?.includes("seasonId") && (
      <Autocomplete
        autoComplete
        aria-label={t("label.seasonSelector")}
        placeholder={t("placeholder.season")}
        disabled={isFetchingSeasons}
        value={seasons?.find((season) => season.seasonId === seasonId) || null}
        startDecorator={
          seasonId ? <Chip color="primary">{t("item.season")}</Chip> : null
        }
        options={seasons || []}
        filterOptions={createFilterOptions({
          stringify: (option) => `${option?.seasonName} ${option?.seasonId}`
        })}
        onChange={(_, newSeason) => {
          dispatch(setSeasonId(newSeason?.seasonId || null))
          if (currentPath) {
            navigate(
              generatePath(currentPath, {
                env,
                gameId,
                spaceId,
                seasonId: newSeason?.seasonId || null
              })
            )
          }
        }}
        sx={{
          width: 260
        }}
        isOptionEqualToValue={(option, value) =>
          option.seasonId === value.seasonId
        }
        getOptionLabel={(option) => option.seasonName}
        renderOption={(props, option) => (
          <AutocompleteOption {...props} key={option.seasonId}>
            <ListItemContent>
              <Typography level="body-sm" textColor="text.primary">
                {option.seasonName}
              </Typography>
              <Typography level="body-xs" fontWeight="400" noWrap>
                {option.seasonId}
              </Typography>
            </ListItemContent>
          </AutocompleteOption>
        )}
      />
    )
  )
}
