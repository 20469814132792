import {
  Actions,
  formatTimeSpanToWeeks,
  Season,
  SeasonUpdateRequest
} from "@liveops-portal/lib"
import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useUpdateSeasonMutation } from "@/api/season"
import { SeasonModal } from "@/components/season-modal/season-modal"
import { useAppSelector } from "@/hooks/store"
import { selectGame } from "@/store/slices/context"

interface Props extends Season {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  duration: string
  clientDataVersion: string
}

interface FormData extends Props, Omit<SeasonUpdateRequest, "gameId"> {
  seasonName: Season["seasonName"]
}

export const SeasonUpdate: React.FC<Props> = ({
  open,
  setOpen,
  seasonId,
  seasonName,
  duration,
  clientDataVersion
}: Props) => {
  const gameId = useAppSelector(selectGame)
  const methods = useForm<FormData>({
    mode: "all",
    defaultValues: {
      seasonName,
      seasonRevisionDetails: {
        duration: formatTimeSpanToWeeks(duration).toString(),
        clientDataVersion
      }
    }
  })
  const { reset: resetForm } = methods

  const [updateSeason, mutation] = useUpdateSeasonMutation()
  const { isSuccess, isLoading } = mutation

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm, setOpen])

  const onSubmitHandler = (data: FormData) =>
    updateSeason({ ...data, gameId, seasonId })

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [isSuccess, onCloseHandler])

  return (
    <SeasonModal
      open={open}
      loading={isLoading}
      action={Actions.update}
      methods={methods}
      onSubmit={onSubmitHandler}
      onClose={onCloseHandler}
    />
  )
}
