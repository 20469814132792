import { createSlice } from "@reduxjs/toolkit"
import { GlobalState } from "@/typings/redux"

/* Interface for the state of the App feature */
export interface AppState {
  revision: string
  buildDate: string | null
  isSidebarExpanded: boolean
  isNotificationCenterOpen: boolean
}

/* Redux store slice for the App feature. */
export const appSlice = createSlice({
  name: "app",
  initialState: {
    revision: import.meta.env.__REVISION || "local",
    buildDate: import.meta.env.__BUILD_DATE || null,
    isSidebarExpanded: true,
    isNotificationCenterOpen: false
  } as AppState,
  reducers: {
    toggleSidebar: (state: AppState) => {
      state.isSidebarExpanded = !state.isSidebarExpanded
    },
    toggleNotificationCenter: (state: AppState) => {
      state.isNotificationCenterOpen = !state.isNotificationCenterOpen
    }
  }
})

export const { toggleSidebar, toggleNotificationCenter } = appSlice.actions

export const selectRevision = (state: GlobalState) => state.app.revision
export const selectIsSidebarExpanded = (state: GlobalState) =>
  state.app.isSidebarExpanded
export const selectIsNotificationCenterOpen = (state: GlobalState) =>
  state.app.isNotificationCenterOpen

export default appSlice.reducer
