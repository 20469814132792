import {
  Card,
  CardContent,
  CardProps,
  Checkbox,
  CheckboxProps,
  Container,
  Divider,
  GlobalStyles,
  Link,
  LinkProps,
  List,
  ListItem,
  ListItemProps,
  Sheet,
  Stack,
  Table,
  TableProps,
  Typography,
  TypographyProps
} from "@mui/joy"
import { Grid2 as Grid } from "@mui/material"
import { toHast as mdastToHast } from "mdast-util-to-hast"
import { toc as getTocMdast } from "mdast-util-toc"
import { Nodes } from "mdast-util-toc/lib"
import { Rank } from "mdast-util-toc/lib/search"
import React, {
  Fragment,
  useEffect,
  useState,
  createElement,
  ReactNode
} from "react"
import { jsx, jsxs } from "react/jsx-runtime"
import rehypeToReact, { Components } from "rehype-react"
import { Root } from "rehype-react/lib"
import rehypeSlugify from "rehype-slug"
import remarkGfm from "remark-gfm"
import { remarkMark } from "remark-mark-highlight"
import remarkParse from "remark-parse"
import remarkToRehype from "remark-rehype"
import remarkSupersub from "remark-supersub"
import { unified } from "unified"
import { Compatible } from "unified/lib"
import { ExternalLink } from "@/components/external-link/external-link"

interface Props {
  tocDepth?: Omit<Rank, 1>
  disableToc?: boolean
  markdown: Compatible
}

const components: Partial<Components> = {
  h1: ({ children, ...props }) => (
    <Typography
      component="h1"
      level="h2"
      sx={{ "p + &, div + &": { mt: 2 } }}
      {...(props as TypographyProps)}
    >
      {children}
    </Typography>
  ),
  h2: ({ children, ...props }) => (
    <Typography
      component="h2"
      level="h3"
      sx={{ "p + &, div + &": { mt: 2 } }}
      {...(props as TypographyProps)}
    >
      {children}
    </Typography>
  ),
  h3: ({ children, ...props }) => (
    <Typography
      component="h3"
      level="h4"
      sx={{ "p + &, div + &": { mt: 2 } }}
      {...(props as TypographyProps)}
    >
      {children}
    </Typography>
  ),
  h4: ({ children, ...props }) => (
    <Typography
      component="h4"
      level="title-lg"
      sx={{ "p + &, div + &": { mt: 2 } }}
      {...(props as TypographyProps)}
    >
      {children}
    </Typography>
  ),
  h5: ({ children, ...props }) => (
    <Typography
      component="h5"
      level="title-md"
      sx={{ "p + &, div + &": { mt: 2 } }}
      {...(props as TypographyProps)}
    >
      {children}
    </Typography>
  ),
  h6: ({ children, ...props }) => (
    <Typography
      component="h6"
      level="title-sm"
      sx={{ "p + &, div + &": { mt: 2 } }}
      {...(props as TypographyProps)}
    >
      {children}
    </Typography>
  ),
  p: ({ children, ...props }) => (
    <Typography
      level="body-sm"
      textColor="text.secondary"
      {...(props as TypographyProps)}
    >
      {children}
    </Typography>
  ),
  strong: ({ children, ...props }) => (
    <Typography
      component="strong"
      fontWeight="xl"
      {...(props as TypographyProps)}
    >
      {children}
    </Typography>
  ),
  mark: ({ children, ...props }) => (
    <Typography
      component="mark"
      variant="soft"
      color="warning"
      {...(props as TypographyProps)}
    >
      {children}
    </Typography>
  ),
  a: ({ children, href, ...props }) =>
    href?.startsWith("http") ? (
      <ExternalLink href={href} {...(props as LinkProps)}>
        {children}
      </ExternalLink>
    ) : (
      <Link href={href} {...(props as LinkProps)}>
        {children}
      </Link>
    ),
  ul: ({ id, className, children }) => (
    <List
      id={id}
      className={className}
      size="sm"
      sx={{
        lineHeight: "md",
        "--ListItem-minHeight": ({ spacing }) => spacing(1),
        li: {
          display: "inline-flex",
          flexWrap: "wrap",
          ul: {
            flexBasis: "100%",
            py: 0
          }
        },
        "li::before": {
          content: "'• '"
        },
        "&.contains-task-list": {
          li: {
            display: "flex"
          },
          "li::before": {
            content: "none"
          },
          ".task-list-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 0
          }
        }
      }}
    >
      {children}
    </List>
  ),
  ol: ({ id, className, children }) => (
    <List
      id={id}
      className={className}
      component="ol"
      marker="numeric"
      sx={({ spacing }) => ({
        paddingInlineStart: "2.5ch",
        lineHeight: "md",
        "--ListItem-minHeight": spacing(1),
        ".footnotes &": {
          py: spacing(2)
        }
      })}
    >
      {children}
    </List>
  ),
  li: ({ children, ...props }) => (
    <ListItem {...(props as ListItemProps)}>{children}</ListItem>
  ),
  blockquote: ({ children, ...props }) => (
    <Card {...(props as CardProps)}>
      <CardContent>{children}</CardContent>
    </Card>
  ),
  table: ({ children, ...props }) => (
    <Sheet
      sx={{
        overflow: "hidden",
        borderRadius: "sm",
        "p + &, div + &": { mt: 2 },
        "&:not(:last-child)": { mb: 2 }
      }}
    >
      <Table {...(props as TableProps)}>{children}</Table>
    </Sheet>
  ),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  input: ({ disabled, children, ...props }) => (
    <Checkbox
      variant="outlined"
      size="sm"
      color="primary"
      sx={{
        pointerEvents: "none",
        mr: 1
      }}
      {...(props as CheckboxProps)}
    >
      {children}
    </Checkbox>
  ),
  hr: () => (
    <Divider
      sx={{
        my: 2,
        "--Divider-lineColor": ({ vars }) =>
          `rgba(${vars.palette.neutral.lightChannel} / 0.2)`
      }}
    />
  )
}

const tocComponents: Partial<Components> = {
  ul: ({ children }) => (
    <List
      size="sm"
      marker="disc"
      sx={{
        flexGrow: 0,
        "--ListItem-minHeight": "10px",
        "--ListItem-paddingY": 0,
        "--ListItem-paddingX": 0
      }}
    >
      {children}
    </List>
  ),
  li: ({ children }) => <ListItem>{children}</ListItem>,
  p: ({ children }) => <Fragment>{children}</Fragment>,
  a: ({ href, children }) => (
    <Link fontSize="sm" href={href}>
      {children}
    </Link>
  )
}

const processor = unified()
  .use(remarkParse)
  .use(remarkMark)
  .use(remarkSupersub)
  .use(remarkGfm, { singleTilde: false })
  .use(remarkToRehype)
  .use(rehypeSlugify)

const rehypeToReactOptions = {
  Fragment,
  jsx,
  jsxs,
  createElement,
  components
}

export const MdContainer: React.FC<Props> = ({
  tocDepth = 3,
  disableToc = false,
  markdown
}: Props) => {
  const [toc, setToc] = useState<ReactNode>()
  const [content, setContent] = useState<ReactNode>()

  useEffect(() => {
    if (!disableToc) {
      const tocMdast = getTocMdast(processor().parse(markdown), {
        minDepth: 2,
        maxDepth: tocDepth as Rank
      }).map

      setToc(
        unified()
          .use(rehypeToReact, {
            ...rehypeToReactOptions,
            components: tocComponents
          })
          .stringify(mdastToHast(tocMdast as Nodes) as Root)
      )
    }

    setContent(
      processor().use(rehypeToReact, rehypeToReactOptions).processSync(markdown)
        .result
    )
  }, [markdown, disableToc, tocDepth])

  return (
    <Container maxWidth="xl">
      <GlobalStyles
        styles={({ vars }) => ({
          ".footnotes": {
            borderTop: `1px solid rgba(${vars.palette.neutral.lightChannel} / 0.2)`
          },

          ".sr-only": {
            position: "absolute",
            width: "1px",
            height: "1px",
            padding: 0,
            overflow: "hidden",
            clip: "rect(0, 0, 0, 0)",
            wordWrap: "normal",
            border: 0
          },

          "[data-footnote-ref]::before": {
            content: "'['"
          },

          "[data-footnote-ref]::after": {
            content: "']'"
          },

          pre: {
            margin: 0
          }
        })}
      />
      <Grid container spacing={4} columns={12}>
        {!disableToc && (
          <Grid size={{ xs: 12, lg: 2.5 }} sx={{ position: "relative" }}>
            <Card sx={{ position: "sticky", top: 0 }}>{toc}</Card>
          </Grid>
        )}

        <Grid
          size={{ xs: 12, lg: disableToc ? 12 : 9.5 }}
          component={Stack}
          sx={{ gap: 1.2 }}
        >
          {content}
        </Grid>
      </Grid>
    </Container>
  )
}
