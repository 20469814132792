import { sortObjectsWithKeyword } from "@liveops-portal/lib"
import { Card, CardContent, Chip, Stack, Table, Typography } from "@mui/joy"
import { Grid2 as Grid } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { useGetApplicationsQuery } from "@/api/application"
import { useFindAllSpacesQuery } from "@/api/space"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { selectEnv, selectGame } from "@/store/slices/context"

export const ApplicationsPage: React.FC = () => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)

  const { data: spaces, isFetching: isFetchingSpaces } = useFindAllSpacesQuery(
    { env },
    { skip: !env }
  )

  const { data: applications, isFetching: isFetchingApplications } =
    useGetApplicationsQuery({ gameId, env }, { skip: !gameId || !env })

  const isFetching = isFetchingSpaces || isFetchingApplications

  return (
    <Stack sx={{ gap: 4 }}>
      <Spinner loading={isFetching} />

      {applications?.length ? (
        applications.map(({ name, appId, builds }) => (
          <Stack key={appId} sx={{ gap: 2 }}>
            <Stack sx={{ flexDirection: "row", alignItems: "center", gap: 1 }}>
              <Typography level="h3">{name}</Typography>

              <Typography level="body-xs" textColor="text.tertiary" mt={1}>
                <CopyToClipboard>{appId}</CopyToClipboard>
              </Typography>
            </Stack>
            {builds?.length ? (
              <Grid container spacing={2} columns={12}>
                {sortObjectsWithKeyword(builds, "buildId").map(
                  ({ buildId, spaceId }, i) => {
                    const space = spaces?.find(
                      ({ spaceId: s }) => s === spaceId
                    )

                    return (
                      <Grid
                        key={`${buildId}-${i}`}
                        component={Card}
                        size={{ xs: 6, lg: 4 }}
                        variant="outlined"
                      >
                        <CardContent
                          component={Table}
                          borderAxis="none"
                          sx={{ "--TableCell-headBackground": "transparent" }}
                        >
                          <tbody>
                            <tr>
                              <th scope="row" style={{ width: "100px" }}>
                                {t("id.build")}
                              </th>
                              <td>
                                {buildId === "default" ? (
                                  <Chip>{buildId}</Chip>
                                ) : (
                                  <CopyToClipboard>
                                    <Typography level="body-sm">
                                      {buildId}
                                    </Typography>
                                  </CopyToClipboard>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                style={{ width: "100px", verticalAlign: "top" }}
                              >
                                {t("item.space")}
                              </th>
                              <td>
                                <Typography fontSize="sm">
                                  {space?.spaceName}
                                </Typography>
                                <CopyToClipboard>
                                  <Typography
                                    level="body-xs"
                                    textColor="text.tertiary"
                                  >
                                    {space?.spaceId}
                                  </Typography>
                                </CopyToClipboard>
                              </td>
                            </tr>
                          </tbody>
                        </CardContent>
                      </Grid>
                    )
                  }
                )}
              </Grid>
            ) : (
              <Typography>
                {t("message.noItems.parent", {
                  item: "item.builds",
                  parent: "item.application"
                })}
              </Typography>
            )}
          </Stack>
        ))
      ) : (
        <Typography>
          {t("message.noItems.parent", {
            item: "item.applications",
            parent: "item.game"
          })}
        </Typography>
      )}
    </Stack>
  )
}
